import React, { useEffect, useState, useCallback, useRef } from 'react';
import FriendCard from './FriendCard';
import InfiniteScroll from 'react-infinite-scroller';
import ClipLoader from "react-spinners/BeatLoader";
import { useSelector } from 'react-redux';
import SearchFriends from './SearchFriends';
import '../assets/Friends.css';
import defaultProfileImage from '../assets/images/dummy-pic.jpg';
import axios from 'axios';

const Friends = () => {
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.userData);
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const initialFetchRef = useRef(false);

  const [friendsList, setFriendsList] = useState([]);
  const [friendsPage, setFriendsPage] = useState(1);
  const [friendsHasMore, setFriendsHasMore] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [friendsLoading, setFriendsLoading] = useState(false);

  const searchFriends = useCallback(async (query) => {
    if (!loggedIn || !user || !token || requestInProgress) return;
    setRequestInProgress(true);
    setFriendsLoading(true);

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        }
      };

      const url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?search=${query}&page=1&per_page=15`;
      const response = await axios.get(url, config);

      setFriendsList(response.data.friends);
      setFriendsPage(1);
      setFriendsHasMore(response.data.friends.length >= 15);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setRequestInProgress(false);
      setFriendsLoading(false);
    }
  }, [loggedIn, user, token]);

  const fetchInitialFriends = useCallback(async () => {
    if (!token || !user || requestInProgress) {
      return;
    }

    setRequestInProgress(true);
    setFriendsLoading(true);

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        }
      };

      const url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?page=1&per_page=15`;
      const response = await axios.get(url, config);

      setFriendsList(response.data.friends);
      setFriendsPage(2);
      setFriendsHasMore(response.data.friends.length >= 15);
    } catch (error) {
      console.error('Failed to fetch friends:', error);
    } finally {
      setRequestInProgress(false);
      setFriendsLoading(false);
    }
  }, [token, user]);

  const loadMoreFriends = useCallback(async () => {
    if (!loggedIn || !user || !token || requestInProgress || !friendsHasMore) return;
    setRequestInProgress(true);

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        }
      };

      const url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?page=${friendsPage}&per_page=15`;
      const response = await axios.get(url, config);

      setFriendsList(prev => [...prev, ...response.data.friends]);
      setFriendsPage(prev => prev + 1);
      setFriendsHasMore(response.data.friends.length >= 15);
    } catch (error) {
      console.error('Failed to load more friends:', error);
    } finally {
      setRequestInProgress(false);
    }
  }, [loggedIn, user, token, requestInProgress, friendsHasMore, friendsPage]);

  useEffect(() => {
    if (!initialFetchRef.current && token && user && loggedIn) {
      initialFetchRef.current = true;
      fetchInitialFriends();
    }
  }, [token, user, loggedIn, fetchInitialFriends]);

  const deleteFriendship = useCallback(async (friend) => {
    const userId = user?.id;
    const friendId = friend.id;
    const url = `${process.env.REACT_APP_API_URL}/destroy_friendship`;

    const data = {
      user_id: userId,
      friend_id: friendId
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const question = window.confirm(`Are you sure you want to delete friend?`);
    if (question) {
      try {
        await axios.delete(url, { headers, params: data });
        setFriendsList(prev => prev.filter(f => f.id !== friendId));
      } catch (error) {
        console.error('Failed to delete friend:', error);
      }
    }
  }, [token, user]);

  return (
    <div className='friends-container'>
      <div className='friends-header-container'>
        <h1 className='friends-header'>Your Friends</h1>
      </div>
      <SearchFriends
        searchFriends={searchFriends}
        fetchInitialFriends={fetchInitialFriends}
      />
      <div
        style={{
          height: "calc(100vh - 64px - 120px)",
          overflow: "auto",
          position: "relative",
          paddingBottom: "80px"
        }}
      >
        {friendsLoading && friendsList.length === 0 ? (
          <div className="friends-loader-container">
            <ClipLoader
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#ff7c62"
            />
          </div>
        ) : friendsList.length === 0 ? (
          <div className="no-friends-message">
            No friends found. Try adjusting your search.
          </div>
        ) : (
          <InfiniteScroll
            className="friendsList"
            pageStart={1}
            loadMore={loadMoreFriends}
            hasMore={friendsHasMore}
            useWindow={false}
            threshold={50}
            loader={
              <div className="friends-loader-container" key={0}>
                <ClipLoader
                  loading={true}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="#ff7c62"
                />
              </div>
            }
          >
            {friendsList.map((friend) => (
              <FriendCard
                key={friend.id}
                id={friend.id}
                imageUrl={friend.profile_image_url ? friend.profile_image_url : defaultProfileImage}
                firstname={friend.firstname}
                lastname={friend.lastname}
                online={friend.online}
                onDelete={() => deleteFriendship(friend)}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Friends;
