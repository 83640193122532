import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import '../../assets/HeartButton.css';
import { useSelector } from 'react-redux';

const HeartButton = ({ contentId, source }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const [hearted, setHearted] = useState(false);
  const [savedContentId, setSavedContentId] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // useEffect(() => {
  //   const checkIfSaved = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/users/${user.id}/saved_contents`,
  //         {
  //           headers: {
  //             'Authorization': `Bearer ${token}`,
  //             'Content-Type': 'application/json',
  //             Accept: 'application/json',
  //           },
  //         }
  //       );

  //       const savedContent = response.data.contents.find(content =>
  //         (source === 'plex' && content.rating_key === contentId) ||
  //         (source === 'db' && content.id === contentId)
  //       );

  //       if (savedContent) {
  //         setHearted(true);
  //         setSavedContentId(savedContent.id);
  //       }
  //     } catch (error) {
  //       console.error('Failed to check saved content status', error);
  //     }
  //   };

  //   if (user && token) {
  //     checkIfSaved();
  //   }
  // }, [user, token, contentId, source]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const handleClick = async () => {
    try {
      if (!hearted) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/${user.id}/saved_contents`,
          {
            content_id: contentId,
            source: source
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );
        setSavedContentId(response.data.id);
        setHearted(true);
        setToastMessage('Added to your saved content');
        setShowToast(true);
      } else {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/users/${user.id}/saved_contents/${savedContentId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );
        setHearted(false);
        setSavedContentId(null);
        setToastMessage('Removed from your saved content');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Failed to toggle saved content', error);
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faHeart}
        onClick={handleClick}
        className={`heart-icon ${hearted ? 'hearted' : ''}`}
        data-testid='heartbtn'
      />
      {showToast && (
        <div className="toast-notification">
          {toastMessage}
        </div>
      )}
    </>
  );
};

export default HeartButton;
