import React, { useState, CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";

import "../assets/modal.css";
import imdbLogo from '../assets/images/imdb_logo.png'
import metaCriticLogo from '../assets/images/metacritic-logo-original.png'
import rottenLogo from '../assets/images/Rotten_tomato_logo.png'

function Modal({
  closeModal,
  id,
  title,
  genres,
  video,
  name,
  overview,
  ratings
})
{
  const [loading, setLoading] = useState(true)

  const firstRatingValue = ratings?.[0]?.Value || "";

  const hideSpinner = () => {
    setLoading(false)
  };

  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  return (
    <div className="modalBackground">
      <div className="titleCloseBtn">
        <FontAwesomeIcon
          onClick={() => closeModal(false)}
          icon={faSquareXmark}
          size="3x"
        />
      </div>
      <div className="modalHeader">
        <div className="modalTitle">
          <h2>{title || name}</h2>
        </div>
      </div>
        <div className="modalBody">
          <div className="video">
            {video && (
              <div className="loader">
                <ClipLoader
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  cssOverride={override}
                />
              </div>
            )}
            {video && (
              <iframe
                onLoad={hideSpinner}
                src={video}
                title={title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                className="modalTrailer"
                loading="eager"
              />
            )}
          </div>
          <div className="modalDescription">
            <p>{overview}</p>
          </div>
          <div className="modalGenres">
            {genres.map((genre, i) => (
              <li className="modalGenre" key={i}>
                {genre}
              </li>
            ))}
          </div>
          <div className="modalRating">
          {ratings && ratings.length > 0 && (
                <ul>
                  {ratings.map((rating, index) => (
                    <li key={index}>
                      {rating.Source === 'Internet Movie Database' ? (
                        <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Metacritic' ? (
                        <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Rotten Tomatoes' ? (
                        <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : (
                        rating.Source
                      )}{rating.Value}
                    </li>
                  ))}
                </ul>
            )}
          </div>
        </div>
      </div>
  );
}

export default Modal;
