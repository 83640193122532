import React, { useState } from 'react';
import axios from 'axios';

export default function PlexLogin({ setRequire2FA, setUserId2FA, setCurrentUser, setInvalidLogin }) {
  const [loading, setLoading] = useState(false);

  const handlePlexLogin = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/auth/plex/login_url`)
      .then(response => {
        setLoading(false);
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          setInvalidLogin('Error retrieving Plex login URL.');
        }
      })
      .catch(error => {
        setLoading(false);
        setInvalidLogin('Error retrieving Plex login URL.');
      });
  };

  return (
    <button
      type="button"
      onClick={handlePlexLogin}
      disabled={loading}
    >
      {loading ? 'Loading...' : 'Sign in with Plex'}
    </button>
  );
}
