import React from 'react';
import '../assets/LoggedInHomepage.css'
import flickswipeLogo from '../assets/images/flickswipe_logo.png';
import Tutorial from './Tutorial';
import { Link } from 'react-router-dom';

const LoggedInHomepage = () => {
  return (
    <div className="LoggedIn-homepage-container">
      <div className="LoggedIn-logo-container">
        <img src={flickswipeLogo} alt="Flickswipe logo" className="LoggedIn-flickswipe-logo" />
      </div>
      <div className="LoggedIn-tagline-container">
        <h1 className="LoggedIn-tagline">Swipe, Match, Watch!</h1>
      <div className="home-premium">
        <h2>Get Flickswipe Premium</h2>
        <p>Unlimited undo swipes, personalized content and much more!</p>
        <button className='home-free-btn'><Link to='/premium'>Try it Free!</Link></button>
      </div>
      </div>
      <Tutorial />
    </div>
  );
};

export default LoggedInHomepage;
