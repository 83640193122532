import axios from 'axios';
import React, { useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

import '../../assets/FacebookLogin.css'

async function handleFacebookLoginSuccess(response, setRequire2FA, setUserId2FA, setCurrentUser, setInvalidLogin) {
  const accessToken = response.authResponse.accessToken;
  try {
    const userData = await getFacebookUserData(accessToken);

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/facebook/callback`, { user: userData })
      .then((response) => {
        if (response.data.require_2fa) {
          setRequire2FA(true);
          setUserId2FA(response.data.user_id);
        } else {
          setCurrentUser(
            response.data.user,
            response.data.jwt,
            response.data.exp,
            response.data.refresh_token
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          setInvalidLogin(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  } catch (error) {
    console.log('Error fetching Facebook user data:', error);
    handleFacebookLoginError(setInvalidLogin);
  }
}

function handleFacebookLoginError(setInvalidLogin) {
  setInvalidLogin('Facebook login failed.');
}

async function getFacebookUserData(accessToken) {
  return new Promise((resolve, reject) => {
    window.FB.api('/me', { fields: 'id,email,first_name,last_name,picture.type(large)' }, (response) => {
      if (response && !response.error) {
        resolve({
          id: response.id,
          email: response.email,
          first_name: response.first_name,
          last_name: response.last_name,
          profile_image_url: response.picture.data.url,
          access_token: accessToken,
        });
      } else {
        reject(response.error);
      }
    });
  });
}

function FacebookLoginComponent({ setRequire2FA, setUserId2FA, setCurrentUser, setInvalidLogin }) {
  useEffect(() => {
    if (!window.FB) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initFacebookSdk();
      };
      document.body.appendChild(script);
    } else {
      initFacebookSdk();
    }

    function initFacebookSdk() {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      });
    }
  }, []);

  const handleFacebookLoginClick = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          handleFacebookLoginSuccess(response, setRequire2FA, setUserId2FA, setCurrentUser, setInvalidLogin);
        } else {
          handleFacebookLoginError(setInvalidLogin);
        }
      },
      { scope: 'email' }
    );
  };

  return (
    <button data-testid="fb-btn" className="custom-fb-button" onClick={handleFacebookLoginClick}>
      <FontAwesomeIcon icon={faFacebookF} />
      <span>Login with Facebook</span>
    </button>
  );
}

export default FacebookLoginComponent;
