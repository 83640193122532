// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heart-icon {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 4em;
  color: #bbbbbbca;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  z-index: 10;
}

.hearted {
  color: #ff7c62;
  animation: heart-pulse 1s ease-in-out;
}

.toast-notification {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  z-index: 1000;
  animation: slide-up 0.2s ease-out;
}

@keyframes heart-pulse {
  0% { transform: translate(-50%, 50%) scale(1); }
  50% { transform: translate(-50%, 50%) scale(1.1); }
  100% { transform: translate(-50%, 50%) scale(1); }
}

@keyframes slide-up {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .heart-icon {
    font-size: 3em;
    bottom: 8%;
  }

  .toast-notification {
    bottom: -16px;
    width: 80%;
    max-width: 300px;
    text-align: center;
    font-size: 0.9em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/HeartButton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,+BAA+B;EAC/B,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kCAAkC;EAClC,WAAW;AACb;;AAEA;EACE,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,oCAAoC;EACpC,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,iCAAiC;AACnC;;AAEA;EACE,KAAK,wCAAwC,EAAE;EAC/C,MAAM,0CAA0C,EAAE;EAClD,OAAO,wCAAwC,EAAE;AACnD;;AAEA;EACE;IACE,gCAAgC;IAChC,UAAU;EACZ;EACA;IACE,6BAA6B;IAC7B,UAAU;EACZ;AACF;;AAEA,6BAA6B;AAC7B;EACE;IACE,cAAc;IACd,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;AACF","sourcesContent":[".heart-icon {\n  position: absolute;\n  bottom: 10%;\n  left: 50%;\n  transform: translate(-50%, 50%);\n  font-size: 4em;\n  color: #bbbbbbca;\n  cursor: pointer;\n  transition: color 0.2s ease-in-out;\n  z-index: 10;\n}\n\n.hearted {\n  color: #ff7c62;\n  animation: heart-pulse 1s ease-in-out;\n}\n\n.toast-notification {\n  position: fixed;\n  bottom: 80px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n  padding: 12px 24px;\n  border-radius: 8px;\n  z-index: 1000;\n  animation: slide-up 0.2s ease-out;\n}\n\n@keyframes heart-pulse {\n  0% { transform: translate(-50%, 50%) scale(1); }\n  50% { transform: translate(-50%, 50%) scale(1.1); }\n  100% { transform: translate(-50%, 50%) scale(1); }\n}\n\n@keyframes slide-up {\n  from {\n    transform: translate(-50%, 100%);\n    opacity: 0;\n  }\n  to {\n    transform: translate(-50%, 0);\n    opacity: 1;\n  }\n}\n\n/* Mobile Responsive Styles */\n@media screen and (max-width: 768px) {\n  .heart-icon {\n    font-size: 3em;\n    bottom: 8%;\n  }\n\n  .toast-notification {\n    bottom: -16px;\n    width: 80%;\n    max-width: 300px;\n    text-align: center;\n    font-size: 0.9em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
