import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Home, LogIn, UserPlus } from 'lucide-react'; // Import icons
import '../assets/LoggedOutNavbar.css';

const LoggedOutNavbar = () => {
  const location = useLocation();
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const isActive = (path) => location.pathname === path;

  return (
    <header className="loggedout-navbar">
      <div className="loggedout-navbar-container">
        {!loggedIn && (
          <nav className="loggedout-navbar-nav">
            <Link to="/" className={`loggedout-nav-link${isActive('/') ? ' active' : ''}`}>
              <Home size={24} aria-hidden="true" />
              <div className="nav-label">Home</div>
            </Link>
            <Link to="/login" className={`loggedout-nav-link${isActive('/login') ? ' active' : ''}`}>
              <LogIn size={24} />
              <span>Login</span>
            </Link>
            <Link to="/signup" className={`loggedout-nav-link signup${isActive('/signup') ? ' active' : ''}`}>
              <UserPlus size={24} />
              <span>Sign Up</span>
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default LoggedOutNavbar;
