import React, { useEffect, useState, CSSProperties } from 'react';
import axios from 'axios';
import Typed from 'react-typed';
import RatingSlider from './RatingSlider';
import ClipLoader from "react-spinners/ClipLoader";

import '../assets/DeckFiltering.css';

function DeckFiltering({ token, onGenreChange, onRatingChange }) {
    const [genres, setGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [filter, setFilter] = useState('');
    const [ratings, setRatings] = useState([]);
    const [typedStrings, setTypedStrings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Prepare Strings for Typed
      let newStrings = genres.map(genre => `Filter by: ${genre.name}`).concat(ratings.map(rating => `Filter by: ${rating.source}`));

      // Randomize array in-place using Durstenfeld shuffle algorithm
      for (let i = newStrings.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [newStrings[i], newStrings[j]] = [newStrings[j], newStrings[i]];
      }

      setTypedStrings(newStrings);
    }, [genres, ratings]);

    useEffect(() => {
      const handleClickOutside = (event) => {
          // Check if the user clicked outside of the dropdown/input field
          if (dropdownVisible && event.target.closest(".filter-container") === null) {
              setDropdownVisible(false);
          }
      };

      // Attach the listeners to the document
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);

      // Cleanup
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("touchstart", handleClickOutside);
      };
  }, [dropdownVisible]); // Re-run the effect when dropdownVisible changes

    useEffect(() => {
        const fetchGenres = async () => {
            let config = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            axios
                .get(`${process.env.REACT_APP_API_URL}/filters`, config)
                .then((response) => {
                    setGenres(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        if (token) {
            fetchGenres()
        }
    }, [token]);

    useEffect(() => {
        const genresObject = genres.reduce((acc, genre) => {
            acc[genre.id] = selectedGenres.includes(genre.id);
            return acc;
        }, {});
        onGenreChange(genresObject);
    }, [selectedGenres]);


    const handleOptionClick = (id) => {
        if (!selectedGenres.includes(id)) {
            setSelectedGenres([...selectedGenres, id]);
        } else {
            setSelectedGenres(selectedGenres.filter((genreId) => genreId !== id));
        }
    };

    const removeSelectedGenre = (id) => {
        setSelectedGenres(selectedGenres.filter((genreId) => genreId !== id));
    };

    useEffect(() => {
      const fetchRatings = async () => {
        let config = {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        axios
          .get(`${process.env.REACT_APP_API_URL}/ratings`, config)
          .then((response) => {
            const initialRatings = response.data.map((rating) => ({
              source: rating.source,
              ratingValue: 0,
            }));
            setRatings(initialRatings);
            onRatingChange(initialRatings);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      };
      if (token) {
        fetchRatings();
      }
    }, [token]);

    const handleRatingChange = (source, newValue) => {
      const updatedRatings = ratings.map((rating) =>
        rating.source === source ? { ...rating, ratingValue: newValue } : rating
      );
      setRatings(updatedRatings);
      onRatingChange(updatedRatings);
    };

    const override: CSSProperties = {
      borderColor: "#ff7c62",
      height: "24px",
      width: "24px"
    };

    return (
      <div className="filter-container">
          <div className="input-field" data-testid="inputfieldbox" onClick={() => setDropdownVisible(!dropdownVisible)}>
              {loading ? (
                  <ClipLoader
                      loading={true}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      cssOverride={override}
                  />
              ) : selectedGenres.length === 0 && !dropdownVisible && (
                  <div className="typed-strings">
                      <Typed strings={typedStrings} typeSpeed={40} backSpeed={50} smartBackspace={true} loop />
                  </div>
              )}
              {selectedGenres.map((id) => {
                  const genre = genres.find((genre) => genre.id === id);
                  return genre ? (
                      <div key={id} className="selected-genre">
                          {genre.name}
                          <span className="remove-genre" onClick={(e) => { e.stopPropagation(); removeSelectedGenre(id); }}>&times;</span>
                      </div>
                  ) : null;
              })}
          </div>
          {dropdownVisible && (
              <div className="scrollable-container">
                  <div className="dropdown" data-testid="dropdown">
                      <input type="text" className="filter-input" placeholder="Filter..." onChange={(e) => setFilter(e.target.value)} />
                      {genres
                          .filter((genre) => genre.name.toLowerCase().includes(filter.toLowerCase()))
                          .map((genre) => (
                              <div key={genre.id} className="dropdown-option" onClick={() => handleOptionClick(genre.id)}>
                                  <input type="checkbox" checked={selectedGenres.includes(genre.id)} readOnly /> {genre.name}
                              </div>
                          ))}
                  </div>
                  <div className="ratings-container" data-testid="rating-slider">
                      {ratings.map((rating) => (
                          <div className="rating-slider" data-testid={`rating-slider-${rating.source}`}  key={rating.source}>
                              <RatingSlider
                                  rating={rating}
                                  onRatingChange={handleRatingChange}
                              />
                          </div>
                      ))}
                  </div>
              </div>
          )}
      </div>
    );
}
export default DeckFiltering;
