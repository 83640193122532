import React from "react";
import UserRecentSearches from './UserRecentSearches';
import RecommendedFriends from './RecommendedFriends';
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";

import '../assets/SearchUsers.css';

const SearchUsers = ({
  searchValue,
  displayResults,
  fetchMoreUsers,
  hasMore,
  sendFriendRequest,
  sentInvitationsList,
  sentRequest,
  recentSearches,
  handleRecentSearchClick,
  recommendations,
  fetchMoreRecommendations,
  hasMoreRecommendations,
  loadingRecommendations,
  isSearchComplete
}) => {
  // Separate component for individual user card to improve performance
  const UserCard = React.memo(({ searchedUser, index, isPending, onRequestClick }) => (
    <div className="userCard" key={searchedUser.id}>
      <img
        className="userImage"
        data-testid="profile_image"
        src={searchedUser.profile_image_url || require('../assets/images/dummy-pic.jpg')}
        alt="profile"
      />
      <div className="UserBody">
        <h2>{searchedUser.firstname}</h2>
        <p>{searchedUser.lastname}</p>
      </div>
      <button
        data-testid={`requestbtn-${index}`}
        disabled={isPending}
        onClick={() => onRequestClick(searchedUser)}
      >
        {isPending ? "Pending" : "Send Request"}
      </button>
    </div>
  ));

  const renderSearchResults = () => {
    if (searchValue && displayResults.length === 0 && isSearchComplete) {
      return (
        <div className="no-results">
          <p>No users found matching "{searchValue}"</p>
        </div>
      );
    }

    return displayResults.map((searchedUser, i) => {
      const isPending = sentInvitationsList.some(
        invitation => invitation.friend_id === searchedUser.id
      ) || sentRequest[searchedUser.id];

      return (
        <UserCard
          key={searchedUser.id}
          searchedUser={searchedUser}
          index={i}
          isPending={isPending}
          onRequestClick={sendFriendRequest}
        />
      );
    });
  };

  return (
    <div id="scrollableDiv" className="scroll-container">
      {!searchValue && (
        <UserRecentSearches
          recentSearches={recentSearches}
          handleRecentSearchClick={handleRecentSearchClick}
        />
      )}

      {searchValue ? (
        <InfiniteScroll
          dataLength={displayResults.length}
          next={fetchMoreUsers}
          hasMore={hasMore}
          loader={
            <div className="loader-container">
              <ClipLoader
                color="#ff7c62"
                loading={true}
                size={40}
                aria-label="Loading Spinner"
              />
            </div>
          }
          scrollableTarget="scrollableDiv"
          scrollThreshold="50px"
          className="userList"
        >
          {renderSearchResults()}
        </InfiniteScroll>
      ) : (
        <RecommendedFriends
          recommendations={recommendations}
          fetchMoreRecommendations={fetchMoreRecommendations}
          hasMore={hasMoreRecommendations}
          loading={loadingRecommendations}
          sendFriendRequest={sendFriendRequest}
          sentRequest={sentRequest}
        />
      )}

      <style>{`
        .scroll-container {
          height: 80vh;
          overflow: auto;
          background-color: var(--background);
        }
        .userList {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1rem;
        }
        .no-results {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40px 20px;
          text-align: center;
          color: var(--text-primary);
        }
        .no-results p {
          font-size: 16px;
          opacity: 0.8;
        }
        .loader {
          text-align: center;
          padding: 1rem;
          color: var(--text-primary);
        }
      `}</style>
    </div>
  );
};

export default React.memo(SearchUsers);
