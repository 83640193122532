// deckSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  isFilterLoading: false,
  data: [],
  currentIndex: -1,
  currentCard: null,
  expandSearch: false,
  cardHistory: [],
  lastAction: null,
  noResults: false  // Add this
};

const deckSlice = createSlice({
  name: 'deck',
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsFilterLoading: (state, action) => {
      state.isFilterLoading = action.payload;
    },
    setExpandSearch: (state, action) => {
      state.expandSearch = action.payload;
    },
    setData: (state, action) => {
      const newData = Array.from(new Set(action.payload.map(item =>
        JSON.stringify(item)))).map(item => JSON.parse(item));
      state.data = newData;
      state.currentIndex = newData.length - 1;
      // Set currentCard to the item at currentIndex
      state.currentCard = newData.length > 0 ? newData[newData.length - 1] : null;
      state.cardHistory = [];
    },
    swipeCard: (state, action) => {
      const { direction, cardId, source, swipeInstance } = action.payload;
      if (state.currentIndex >= 0) {
        // Store the current card before changing anything
        state.cardHistory.push({
          card: state.data[state.currentIndex],
          index: state.currentIndex,
          direction,
          source,
          swipeInstance
        });

        // Move to next card
        state.currentIndex--;
        state.currentCard = state.currentIndex >= 0 ? state.data[state.currentIndex] : null;
        state.lastAction = 'swipe';
      }
    },
    undoLastSwipe: (state) => {
      const lastSwipe = state.cardHistory.pop();
      if (!lastSwipe) return;

      try {
        // Create restored card
        const restoredCard = {
          ...lastSwipe.card,
          swipeInstance: Date.now()
        };

        // Instead of adding to the array, we want to restore the card to its original position
        state.currentIndex++; // Move index back up
        state.data[state.currentIndex] = restoredCard; // Replace at current index
        state.currentCard = restoredCard;
        state.lastAction = 'undo';
      } catch (error) {
        console.error('Error in undoLastSwipe reducer:', error);
        state.cardHistory.push(lastSwipe);
      }
    },
    resetDeck: (state) => {
      state.currentIndex = state.data.length - 1;
      // Set currentCard to match the reset index
      state.currentCard = state.data[state.data.length - 1] || null;
      state.cardHistory = [];
      state.lastAction = 'reset';
    },
    setNoResults: (state, action) => {
      state.noResults = action.payload;
    }
  }
});

// Selectors
export const selectCurrentCard = (state) => state.deck.currentCard;
export const selectCurrentIndex = (state) => state.deck.currentIndex;
export const selectCanUndo = (state) => state.deck.cardHistory.length > 0;
export const selectDeckEmpty = (state) => state.deck.data.length === 0;

export const {
  setIsFetching,
  setIsFilterLoading,
  setData,
  swipeCard,
  undoLastSwipe,
  resetDeck,
  setExpandSearch,
  setNoResults
} = deckSlice.actions;

export default deckSlice.reducer;
