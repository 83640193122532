import React, { useState, useCallback } from "react";
import { useSelector } from 'react-redux';
import useDebounce from './useDebounce';
import "../assets/userList.css";

const SearchFriends = ({ searchFriends, fetchInitialFriends }) => {
  const [inputValue, setInputValue] = useState('');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const debouncedValue = useDebounce(inputValue, 500);

  const handleSearch = useCallback((e) => {
    setInputValue(e.target.value);
    setIsInitialLoad(false); // Once user starts typing, it's no longer initial load
  }, []);

  React.useEffect(() => {
    let isSubscribed = true;

    if (!isInitialLoad) { // Only run effect if it's not the initial load
      if (debouncedValue !== '') {
        searchFriends(debouncedValue);
      } else {
        fetchInitialFriends(); // Only call this when search is explicitly cleared
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [debouncedValue, searchFriends, fetchInitialFriends, isInitialLoad]);

  return (
    <div className="userList-header">
      <h1>Search Friends</h1>
      <input
        type="text"
        onChange={handleSearch}
        value={inputValue}
        placeholder="Search friends..."
        data-testid="search-input"
      />
    </div>
  );
};

export default React.memo(SearchFriends);
