import React from 'react';

import '../../assets/TermsOfUse.css';

const TermsOfUse = () => {
    return (
      <div className="terms-of-use-container">
            <h1>Flickswipe Terms of Use</h1>

            <p>Last updated: Saturday 21st June 2023</p>

            <p>Welcome to Flickswipe! Please read these Terms of Use ("Terms") carefully as they contain important information regarding your legal rights, remedies, and obligations. By accessing or using the Flickswipe application (“App”), you agree to be bound by these Terms and all applicable laws and regulations.</p>

            <h2>1. Eligibility</h2>

            <p>You must be at least 13 years old to use the App. By using Flickswipe, you represent and warrant that you are of legal age to form a binding contract and meet all of the eligibility requirements.</p>

            <h2>2. Registration and Account Security</h2>

            <p>To use Flickswipe, you must register for an account using a valid email address and create a password. You agree to provide accurate and complete information during registration and keep your account information up-to-date.</p>

            <h2>3. License to Use the App</h2>

            <p>Subject to your compliance with these Terms, Flickswipe grants you a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes.</p>

            <h2>4. User Conduct</h2>

            <p>You agree not to use the App in any manner that is illegal or harmful. You are responsible for all activity that occurs under your account and for ensuring that your use of the App complies with all applicable laws and regulations.</p>

            <h2>5. Content and Services</h2>

            <p>Flickswipe allows you to swipe and match preferences for movies and TV shows with friends. While we strive to provide accurate information, we do not guarantee the accuracy or availability of the content. The App may also contain links to third-party websites or services, which are not controlled by Flickswipe. We are not responsible for the content or availability of third-party websites or services.</p>

            <h2>6. Subscription Services and Payments</h2>

            <p>Flickswipe offers both free and premium subscription services. The premium subscription, which is billed on a recurring basis, provides additional features including but not limited to personalized content, undo swipes, and exclusive early access to new features.</p>

            <p>You may elect to upgrade to a premium subscription at any time. Unless otherwise stated, Flickswipe will bill for the premium subscription fees through the payment method you provide. The subscription automatically renews unless it is cancelled at least 24 hours before the end of the current period. You are responsible for the timely payment of all fees and for providing Flickswipe with a valid payment method.</p>

            <p>Flickswipe reserves the right to change the subscription fees at any time. We will provide you with advance notice of any changes in fees.</p>

            <p>Refunds for subscription fees are provided solely at the discretion of Flickswipe.</p>

            <h2>7. Intellectual Property</h2>

            <p>All content on Flickswipe, including but not limited to text, graphics, logos, and software, is the property of Flickswipe or its licensors and is protected by copyright and other intellectual property laws. You may not copy, modify, distribute, or create derivative works from the content of the App.</p>

            <h2>8. Data Privacy</h2>

            <p>Your privacy is important to us. Please review our Privacy Policy, which is incorporated into these Terms, to understand how we collect, use, and disclose your personal information.</p>

            <h2>9. Disclaimer of Warranties</h2>

            <p>The App is provided "as is" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability or fitness for a particular purpose.</p>

            <h2>10. Limitation of Liability</h2>

            <p>In no event shall Flickswipe, its affiliates, or its licensors be liable for any indirect, incidental, special, punitive, or consequential damages arising out of or in connection with the App or these Terms.</p>

            <h2>11. Indemnification</h2>

            <p>You agree to defend, indemnify, and hold harmless Flickswipe, its affiliates, and licensors from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or in any way connected with your use of the App or your violation of these Terms.</p>

            <h2>12. Changes to These Terms</h2>

            <p>Flickswipe reserves the right to modify these Terms at any time. Your continued use of the App following the posting of changes constitutes your acceptance of the revised Terms.</p>

            <h2>13. Governing Law and Jurisdiction</h2>

            <p>These Terms are governed by the laws of the United Kingdom, without regard to its conflict of laws principles. You agree to submit to the exclusive jurisdiction of the courts located within the United Kingdom for any disputes arising out of or relating to these Terms.</p>

            <h2>14. Contact Information</h2>

            <p>If you have any questions or concerns regarding these Terms, please contact us at contact@flickswipe.org</p>
        </div>
    )
  }

export default TermsOfUse
