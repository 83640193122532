// Card.js

import React, { useCallback, useEffect, useRef } from "react";
import { animated, to } from "react-spring";
import "../../assets/Card.css";
import HeartButton from '../Card Components/HeartButton';
import placeholderImage from '../../assets/images/flickswipe_logo.png';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setThumbnail, setThumbnailLoading } from '../../redux/slices/thumbnailSlice';

const Card = React.memo(({ i, x, y, bind, data, cardIndex }) => {
  const dispatch = useDispatch();
  const thumbnails = useSelector(state => state.thumbnails.cache);
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.userData) || {};

  // Global request cache
  const globalRequestCache = useRef(window.__thumbnailRequests || new Map());
  window.__thumbnailRequests = globalRequestCache.current;

  const fetchThumbnail = useCallback(async (thumbPath) => {
    if (thumbnails[thumbPath]) return thumbnails[thumbPath];

    const cacheKey = thumbPath;
    if (globalRequestCache.current.has(cacheKey)) {
      return globalRequestCache.current.get(cacheKey);
    }

    dispatch(setThumbnailLoading({ thumbPath, isLoading: true }));

    const requestPromise = axios.get(
      `${process.env.REACT_APP_API_URL}/plex_media/thumbnail?thumb_path=${encodeURIComponent(thumbPath)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        responseType: 'blob'
      }
    )
    .then(response => {
      const url = URL.createObjectURL(response.data);
      dispatch(setThumbnail({ thumbPath, url }));
      globalRequestCache.current.delete(cacheKey);
      return url;
    })
    .catch(error => {
      globalRequestCache.current.delete(cacheKey);
      return placeholderImage;
    })
    .finally(() => {
      dispatch(setThumbnailLoading({ thumbPath, isLoading: false }));
    });

    globalRequestCache.current.set(cacheKey, requestPromise);
    return requestPromise;
  }, [dispatch, token, thumbnails]);

  useEffect(() => {
    let isMounted = true;

    if (data?.thumb && isMounted && !thumbnails[data.thumb]) {
      fetchThumbnail(data.thumb);
    }

    return () => {
      isMounted = false;
    };
  }, [data?.thumb, fetchThumbnail]);

  if (!data) return null;

  const thumbnailUrl = data.thumb ? thumbnails[data.thumb] || placeholderImage :
                      data.movie?.poster_path ||
                      data.tv_show?.poster_path ||
                      placeholderImage;

  return (
    <animated.div
      className="animated"
      {...bind(i, data.id, data.ratingKey)}
      style={{
        transform: to([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)
      }}
    >
      <div className="card" data-testid={`card-${data.id || 'placeholder'}`}>
        <img
          key={`${data.id}-${cardIndex}`}
          src={thumbnailUrl}
          alt="poster_picture"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = placeholderImage;
          }}
        />
        {token && (data.id || data.ratingKey) && (
          <HeartButton
            token={token}
            contentId={data.id || data.ratingKey}
            user={user}
            source={data.ratingKey ? 'plex' : 'db'}
          />
        )}
      </div>
    </animated.div>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.i === nextProps.i &&
    prevProps.x === nextProps.x &&
    prevProps.y === nextProps.y &&
    prevProps.data?.id === nextProps.data?.id
  );
});

export default Card;
