// components/Settings/SecuritySection.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isOAuthUser } from '../../utils/authHelpers';
import PasswordStrengthBar from '../PasswordStrengthBar';
import UpdateTwoFactorAuth from '../registrations/UpdateTwoFactorAuth';

import '../../assets/SecuritySection.css';

import {
  setSecurityLoading,
  setSecurityError,
  setSecuritySuccess,
  set2FAEnabled,
  setQRCode,
  setShowQRCode,
  setShow2FAInput,
  setVerificationSuccessful,
  setTOTPSecret,
  resetSecurityState
} from '../../redux/slices/settingsSlice';

const SecuritySection = ({ user, token }) => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const {
    loading,
    error,
    success,
    is2FAEnabled,
    qrCodeSVG,
    showQrCode,
    show2FACodeInput,
    verificationSuccessful,
    totpSecret
  } = useSelector(state => state.settings.security);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user?.id && token) {
        dispatch(setSecurityLoading(true));
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/${user.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            }
          );
          if (response.data && response.data.user) {
            dispatch(set2FAEnabled(response.data.user.totp_enabled));
          }
        } catch (error) {
          dispatch(setSecurityError("Error fetching user details"));
        } finally {
          dispatch(setSecurityLoading(false));
        }
      }
    };

    fetchUserDetails();
  }, [dispatch, user?.id, token]);

  const enable2FA = async () => {
    dispatch(setSecurityLoading(true));
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/${user.id}/enable_totp`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data.qrcode) {
        dispatch(setQRCode(response.data.qrcode));
        dispatch(setTOTPSecret(response.data.totp_secret));
        dispatch(setShow2FAInput(true));
      } else {
        dispatch(setSecurityError("Error enabling 2FA"));
      }
    } catch (error) {
      dispatch(setSecurityError("Error enabling 2FA"));
    } finally {
      dispatch(setSecurityLoading(false));
    }
  };

  const handle2FAVerification = async (verified, status) => {
    if (verified) {
      dispatch(set2FAEnabled(true));
      dispatch(setShow2FAInput(false));
      dispatch(setVerificationSuccessful(true));
      dispatch(setShowQRCode(false));

      setTimeout(() => {
        dispatch(setVerificationSuccessful(false));
      }, 3000);
    } else if (status === "error") {
      dispatch(setSecurityError("Error verifying 2FA code"));
    }
  };

  const toggle2FA = async () => {
    if (is2FAEnabled) {
      if (window.confirm("Are you sure you want to disable 2FA?")) {
        dispatch(setSecurityLoading(true));
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/${user.id}/disable_totp`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );

          if (response.data.message === "2FA disabled") {
            dispatch(set2FAEnabled(false));
            dispatch(resetSecurityState());
            dispatch(setSecuritySuccess("2FA disabled successfully"));
          } else {
            dispatch(setSecurityError("Error updating 2FA settings"));
          }
        } catch (error) {
          dispatch(setSecurityError("Error updating 2FA settings"));
        } finally {
          dispatch(setSecurityLoading(false));
        }
      }
    } else {
      enable2FA();
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      dispatch(setSecurityError("Passwords don't match"));
      return;
    }

    dispatch(setSecurityLoading(true));
    const updatedFields = {
      password,
      password_confirmation: passwordConfirmation,
    };

    if (!isOAuthUser(user)) {
      if (!currentPassword) {
        dispatch(setSecurityError("Current password required"));
        dispatch(setSecurityLoading(false));
        return;
      }
      updatedFields.current_password = currentPassword;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        { user: updatedFields },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        dispatch(setSecuritySuccess("Password updated successfully"));
        clearForm();
      } else {
        dispatch(setSecurityError(response.data.error || "Update failed"));
      }
    } catch (error) {
      dispatch(setSecurityError(error.response?.data?.message || "Update failed"));
    } finally {
      dispatch(setSecurityLoading(false));
    }
  };

  const disable2FA = async () => {
    if (window.confirm("Are you sure you want to disable 2FA?")) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/${user.id}/disable_totp`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.data.message === "2FA disabled") {
          dispatch(set2FAEnabled(false));
          dispatch(setQRCode(null));  // Changed from setQrCodeSVG to dispatch(setQRCode)
          dispatch(setSecuritySuccess("2FA disabled successfully"));
        } else {
          dispatch(setSecurityError("Error updating 2FA settings. Please try again."));
        }
      } catch (error) {
        dispatch(setSecurityError("Error updating 2FA settings. Please try again."));
      }
    }
  };

  const clearForm = () => {
    setPassword('');
    setPasswordConfirmation('');
    setCurrentPassword('');
  };

  return (
    <div className="section-content">
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      {!isOAuthUser(user) && (
        <>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
          />
          <input
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="Confirm Password"
          />
          <PasswordStrengthBar password={password} />

          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Enter Current Password to update"
          />

          <button
            onClick={handlePasswordUpdate}
            disabled={loading}
            className="btn-update-section"
          >
            {loading ? 'Updating...' : 'Update Password'}
          </button>
        </>
      )}

      <button
        className="btn-toggle-2fa"
        onClick={toggle2FA}
        type="button"
        disabled={loading}
      >
        {loading ? 'Loading...' : is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA'}
      </button>

      {qrCodeSVG && showQrCode && (
        <div
          className="qrcode-container"
          data-testid="qrcode-container"
          dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
        />
      )}

      {show2FACodeInput && (
        <UpdateTwoFactorAuth
          user_id={user.id}
          onVerification={handle2FAVerification}
          totpSecret={totpSecret}
        />
      )}

      {verificationSuccessful && (
        <h4 className="twofactorauth-success">Verification successful!</h4>
      )}
    </div>
  );
};

export default SecuritySection;
