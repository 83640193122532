import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import SavedContentDetail from "./SavedContentDetail";

import '../assets/SavedContent.css';

const SavedContent = ({ user, token }) => {
    const [savedContents, setSavedContents] = useState([]);
    const [userId, setUserId] = useState(null);
    const [selectedContentId, setSelectedContentId] = useState(null);
    const [loading, setLoading] = useState(true)

    const fetchSavedContents = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/users/${userId}/saved_contents`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            setSavedContents(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch saved contents', error);
        }
    };

    const handleDelete = async (content_id) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/users/${userId}/saved_contents/${content_id}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }
            );
            // fetchSavedContents(); // Refresh saved contents
            setSavedContents(prevContents => prevContents.filter(content => content.id !== content_id));
        } catch (error) {
            console.error('Failed to delete saved content', error);
        }
    };

    useEffect(() => {
      if (user) {
          setUserId(user.id);
          setLoading(false);
      }
    }, [user]);

    useEffect(() => {
        if (userId) {
            fetchSavedContents();
        }
    }, [userId]);

    if (!userId) {
        return <p>Loading...</p>;
    }

    const handleClick = (contentId) => {
      setSelectedContentId(contentId);
    };

    return (
      <div className='savedContentContainer'>
        <h1 className='savedHeader'>Saved Media</h1>
          {selectedContentId && (
            <div>
              <SavedContentDetail
                attributes={savedContents.find(content => content.id === selectedContentId)}
                closeDetail={() => setSelectedContentId(null)}
                token={token}
                location_preference={user.location_preference.toLowerCase()}
              />
              <div className="modal-overlay content-detail-visible" />
            </div>
          )}
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className='saved-card-container'>
              {savedContents.map(savedContent => {
                return (
                  <div key={savedContent.id} className="saved-content-card" onClick={(e) => {e.stopPropagation(); handleClick(savedContent.id)}}>
                    <img src={savedContent.poster_path} alt={savedContent.title} />
                    <h2>{savedContent.title || savedContent.name}</h2>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="saved-delete-icon"
                      onClick={(e) => {e.stopPropagation(); handleDelete(savedContent.id)}}
                      data-testid={`delete-icon-${savedContent.id}`}
                    />
                  </div>
                );
              })}
            </div>
          )}
      </div>
    );
};

export default SavedContent;
