// src/components/RecommendedFriends.js
import React from "react";
import { useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";

import '../assets/RecommendedFriends.css';

const RecommendedFriends = ({
  recommendations,
  fetchMoreRecommendations,
  hasMore,
  loading,
  sendFriendRequest,
  sentRequest
}) => {
  const user = useSelector((state) => state.user.userData);

  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  return (
    <div className="recommendedFriends">
      <div className="section-header">
        <h2>People you may know</h2>
      </div>
      <div id="scrollableDiv" className="recommended-scroll-container">
        <InfiniteScroll
          dataLength={recommendations.length}
          next={fetchMoreRecommendations}
          hasMore={hasMore}
          loader={<div className="loader">
            <ClipLoader
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              cssOverride={override}
            /></div>}
          scrollableTarget="scrollableDiv"
          scrollThreshold="50px"
        >
          <div className="recommendations-list">
            {recommendations.length > 0 ? (
              recommendations.map((recommendedUser, i) => (
                <div className="recommendation-card" key={recommendedUser.id}>
                  <div className="user-image-container">
                    <img
                      className="user-avatar"
                      data-testid="profile_image"
                      src={recommendedUser.profile_image_url || require('../assets/images/dummy-pic.jpg')}
                      alt={`${recommendedUser.firstname}'s profile`}
                    />
                  </div>
                  <div className="user-info">
                    <h3>{`${recommendedUser.firstname} ${recommendedUser.lastname}`}</h3>
                    <div className="mutual-friends">
                      {recommendedUser.mutual_friends_count > 0 && (
                        <div className="mutual-friends-avatars">
                          {recommendedUser.mutual_friend_images.map((imageUrl, index) => (
                            <img
                              key={index}
                              className="mutual-friend-avatar"
                              src={imageUrl || require('../assets/images/dummy-pic.jpg')}
                              alt={`Mutual friend ${index + 1}`}
                            />
                          ))}
                        </div>
                      )}
                      <div className="mutual-count" role="status">
                        {recommendedUser.mutual_friends_count} mutual friend{recommendedUser.mutual_friends_count !== 1 ? 's' : ''}
                      </div>
                    </div>
                    <div className="action-buttons">
                      <button
                        className="add-friend-btn"
                        data-testid={`requestbtn-${i}`}
                        disabled={sentRequest[recommendedUser.id]}
                        onClick={() => sendFriendRequest(recommendedUser)}
                      >
                        {sentRequest[recommendedUser.id] ? "Pending" : "Add friend"}
                      </button>
                      {/* <button className="remove-btn">Remove</button> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-state">
                No recommendations available at this time
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default RecommendedFriends;
