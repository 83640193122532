import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../assets/UserPreferences.css'

const UserPreference = ({user, token, saveUserPreferences}) => {
  const [streamingProviders, setStreamingProviders] = useState([]);
  const [savedProviders, setSavedProviders] = useState([]);
  const [saving, setSaving] = useState(false);

  const prime = "https://image.tmdb.org/t/p/w342/emthp39XA2YScoYL1p0sdbAH2WA.jpg"
  const apple = "https://image.tmdb.org/t/p/w342/6uhKBfmtzFqOcLousHwZuzcrScK.jpg"
  const disney = "https://image.tmdb.org/t/p/w342/7rwgEs15tFwyR9NPQ5vpzxTj19Q.jpg"
  const netflix = "https://image.tmdb.org/t/p/w342/t2yyOv40HZeVlLjYsCsPHnWLk4W.jpg";
  const hulu = "https://image.tmdb.org/t/p/w342/zxrVdFjIjLqkfnwyghnfywTn3Lh.jpg";
  const showtime = "https://image.tmdb.org/t/p/w342/4kL33LoKd99YFIaSOoOPMQOSw1A.jpg";
  const starz = "https://image.tmdb.org/t/p/w342/eWp5LdR4p4uKL0wACBBXapDV2lB.jpg";
  const paramount = "https://image.tmdb.org/t/p/w342/xbhHHa1YgtpwhC8lb1NQ3ACVcLd.jpg";
  const iplayer = "https://image.tmdb.org/t/p/w342/zY5SmHyAy1CoA3AfQpf58QnShnw.jpg";
  const hbomax = "https://image.tmdb.org/t/p/w342/Ajqyt5aNxNGjmF9uOfxArGrdf3X.jpg";
  const peacock = "https://image.tmdb.org/t/p/w342/xTHltMrZPAJFLQ6qyCBjAnXSmZt.jpg";
  const vudu = "https://image.tmdb.org/t/p/w342/sgWh6DeaQCDP9rOgYcXPmwnaBPc.png";
  const tubi = "https://image.tmdb.org/t/p/w342/4cpzjflOhR6mfy0BDalsanTcoQo.png";
  const crave = "https://image.tmdb.org/t/p/w342/xlVkcuR2NqruUHh8gPaAw9BvOCT.png";
  const shudder = "https://image.tmdb.org/t/p/w342/Ap2jtXPy3QlLMi1GYLtasq7VoN3.png";
  const now = "https://image.tmdb.org/t/p/w342/y7mZSw1FV99yfawxOISBQTvtJxM.jpg";
  const btsport = "https://image.tmdb.org/t/p/w342/fWTSrSb5TDSBXyDei59fvcS0ZkG.jpg";
  const britbox = "https://image.tmdb.org/t/p/w342/dtU2zKZvtdKgSKjyKekp8t0Ryd1.jpg"
  const youtubepremium = "https://image.tmdb.org/t/p/w342/6IPjvnYl6WWkIwN158qBFXCr2Ne.jpg";
  const rakutentv = "https://image.tmdb.org/t/p/w342/4OMk9wkAMwjcVM905BDyDC3iIgC.png";
  const bfiplayer = "https://image.tmdb.org/t/p/w342/qk2nEYrhd2GoHp4HU5VyGgChUQG.png";
  const chili = "https://image.tmdb.org/t/p/w342/ok5uxZvtMSInfNwIzHMksFNVYz4.png";
  const acorntv = "https://image.tmdb.org/t/p/w342/xzrHiDSO2IlFrBZZ6aKnSjxIwIJ.png";
  const hbo = "https://image.tmdb.org/t/p/w342/tuomPhY2UtuPTqqFnKMVHvSb724.png";
  const discovery = "https://image.tmdb.org/t/p/w342/1D1bS3Dyw4ScYnFWTlBOvJXC3nb.png";
  const abc = "https://image.tmdb.org/t/p/w342/2uy2ZWcplrSObIyt4x0Y9rkG6qO.png";
  const fox = "https://image.tmdb.org/t/p/w342/1DSpHrWyOORkL9N2QHX7Adt31mQ.png";
  const cinemax = "https://image.tmdb.org/t/p/w342/6mSHSquNpfLgDdv6VnOOvC5Uz2h.png";
  const amc = "https://image.tmdb.org/t/p/w342/alqLicR1ZMHMaZGP3xRQxn9sq7p.png";
  const cw = "https://image.tmdb.org/t/p/w342/ge9hzeaU7nMtQ4PjkFlc68dGAJ9.png";
  const nbc = "https://image.tmdb.org/t/p/w342/cm111bsDVlYaC1foL0itvEI4yLG.png";
  const cbs = "https://image.tmdb.org/t/p/w342/wju8KhOUsR5y4bH9p3Jc50hhaLO.png";
  const pbs = "https://image.tmdb.org/t/p/w342/hp2Fs7AIdsMlEjiDUC1V8Ows2jM.png";
  const cartoonnetwork = "https://image.tmdb.org/t/p/w342/c5OC6oVCg6QP4eqzW6XIq17CQjI.png";
  const adultswim = "https://image.tmdb.org/t/p/w342/tHZPHOLc6iF27G34cAZGPsMtMSy.png";
  const nick = "https://image.tmdb.org/t/p/w342/ikZXxg6GnwpzqiZbRPhJGaZapqB.png";
  const espn = "https://image.tmdb.org/t/p/w342/w9le4FUeXVlKLfnlkuk1djRrena.png";
  const comedycentral = "https://image.tmdb.org/t/p/w342/6ooPjtXufjsoskdJqj6pxuvHEno.png";
  const bravo = "https://image.tmdb.org/t/p/w342/wX5HsfS47u6UUCSpYXqaQ1x2qdu.png";
  const channel5 = "https://image.tmdb.org/t/p/w342/bMuKs6xuhI0GHSsq4WWd9FsntUN.png";
  const skysports = "https://image.tmdb.org/t/p/w342/xeyAPBYArO8q60Z7UJcXf6WPXBX.png";
  const skyone = "https://image.tmdb.org/t/p/w342/dVBHOr0nYCx9GSNesTVb1TT52Xj.png";
  const bbcamerica = "https://image.tmdb.org/t/p/w342/8Js4sUaxjE3RSxJcOCDjfXvhZqz.png";
  const eurosport = "https://image.tmdb.org/t/p/w342/AfhbW2Y6X9uwoZAgoP0cOfSPoH7.png";
  const hboeurope = "https://image.tmdb.org/t/p/w342/mz2k1VSA0AQsEWoE1go8yMQAzJK.jpg";
  const nationalgeographicchannel = "https://image.tmdb.org/t/p/w342/q9rPBG1rHbUjII1Qn98VG2v7cFa.png";
  const youtube = "https://image.tmdb.org/t/p/w342/ffK8xNcIu0L5X9bxBJyO6qkaPvT.jpg";
  const espnplus = "https://image.tmdb.org/t/p/w342/mnrrbM7g7gvthN6iyYZ8ndrGKix.png";
  const mubi = "https://image.tmdb.org/t/p/w342/bVR4Z1LCHY7gidXAJF5pMa4QrDS.jpg";
  const skygo = "https://image.tmdb.org/t/p/w342/fBHHXKC34ffxAsQvDe0ZJbvmTEQ.jpg";
  const hotstar = "https://image.tmdb.org/t/p/w342/7Fl8ylPDclt3ZYgNbW2t7rbZE9I.jpg"
  const itvx = "https://image.tmdb.org/t/p/w342/clC87JljoZKeAHIwdlxd1AUn6cn.jpg";
  const all4 = "https://image.tmdb.org/t/p/w342/kJ9GcmYk5zJ9nJtVX8XjDo9geIM.jpg";

  const providerLogos = {
    apple,
    disney,
    iplayer,
    netflix,
    now,
    prime,
    mubi,
    hotstar,
    all4,
    britbox,
    skygo,
    hulu,
    hbomax,
    hbo,
    itvx,
    bravo,
    paramount,
    peacock,
    showtime,
    starz,
    vudu,
    tubi,
    crave,
    discovery,
    abc,
    fox,
    cinemax,
    amc,
    cw,
    nbc,
    cbs,
    pbs,
    cartoonnetwork,
    adultswim,
    nick,
    espn,
    comedycentral,
    shudder,
    btsport,
    youtubepremium,
    rakutentv,
    bfiplayer,
    chili,
    acorntv,
    channel5,
    skysports,
    skyone,
    bbcamerica,
    eurosport,
    hboeurope,
    nationalgeographicchannel,
    youtube,
    espnplus,
  };

  const formatProviderName = (name) => {
    return name.toLowerCase().split(' ').join('_');
  };

  const fetchUserPreferences = async () => {

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_preferences`, config);
      if (response.data && response.data.user_preference.streaming_providers) {
        return response.data.user_preference.streaming_providers;
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      return [];
    }
  };

  const fetchStreamingProviders = async () => {

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/streaming_providers`, config);
      const providerNames = Object.keys(response.data.providers);
      return providerNames.map(providerName => ({ provider_name: formatProviderName(providerName) }));
    } catch (error) {
      console.error('Error fetching streaming providers:', error);
      return []; // return an empty array in case of an error
    }
  };

  const formatProviders = (providers, savedProviders) => {
    return providers.filter(provider => {
      return !savedProviders.some(savedProvider => savedProvider.provider_name === provider.provider_name);
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (!user || !token) { // assuming getJWT() returns the token
        return; // if user or token is not available, exit early from fetchData function
      }

      const userPreferences = await fetchUserPreferences();
      setSavedProviders(userPreferences);
      const providers = await fetchStreamingProviders();
      setStreamingProviders(formatProviders(providers, userPreferences));
    }
    fetchData();
  }, [user, token]);



  const handleAddProvider = async (provider) => {
    setSaving(true);
    const formattedProvider = { ...provider, provider_name: formatProviderName(provider.provider_name) };
    setSavedProviders([...savedProviders, formattedProvider]);
    setStreamingProviders(streamingProviders.filter(p => p.provider_name !== formattedProvider.provider_name));
    await saveUserPreferences([...savedProviders, formattedProvider]);
    setSaving(false);
  };

  const handleRemoveProvider = async (provider) => {
    setSaving(true);
    const formattedProvider = { ...provider, provider_name: formatProviderName(provider.provider_name) };
    setSavedProviders(savedProviders.filter(p => p.provider_name !== formattedProvider.provider_name));
    setStreamingProviders([...streamingProviders, formattedProvider]);
    await saveUserPreferences(savedProviders.filter(p => p.provider_name !== formattedProvider.provider_name));
    setSaving(false);
  };

  return (
    <div>
      <div className='provider-header'>
        <h2>Current Streaming Services</h2>
        <p>Selecting these will show only content from that streaming service. This can limit the amount of items shown.</p>
      </div>
      <div className="provider-grid">
        {streamingProviders.map((provider, index) => (
          <div
            key={index}
            className="provider-logo"
            onClick={!saving ? () => handleAddProvider(provider) : null}
          >
            <img src={providerLogos[provider.provider_name]} data-testid={provider.provider_name} alt={provider.provider_name} />
          </div>
        ))}
      </div>
      {savedProviders.length > 0 ? (
          <div className='provider-header'>
            <h2>Your Streaming Services</h2>
          </div>
        ) : null}
      <div className="provider-grid">
        {savedProviders.map((provider, index) => (
          <div
            key={index}
            className="provider-logo saved"
            onClick={!saving ? () => handleRemoveProvider(provider) : null}
          >
            <img src={providerLogos[provider.provider_name]} data-testid={provider.provider_name} alt={provider.provider_name} />
            <div className="remove-icon">X</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserPreference;
