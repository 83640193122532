import React, { useState, CSSProperties, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import "../../assets/modal.css";
import imdbLogo from '../../assets/images/imdb_logo.png';
import metaCriticLogo from '../../assets/images/metacritic-logo-original.png';
import rottenLogo from '../../assets/images/Rotten_tomato_logo.png';
import tmdbLogo from '../../assets/images/tmdb_logo_small.svg'
import axios from "axios";
import { useSelector } from 'react-redux';
import { selectCurrentCard } from '../../redux/slices/deckSlice';

function Modal({ closeModal, data }) {
  const currentCard = useSelector(selectCurrentCard);
  const [loading, setLoading] = useState(true);
  const [loadingArt, setLoadingArt] = useState(true);
  const [loadingTrailer, setLoadingTrailer] = useState(false);
  const token = useSelector((state) => state.token.value);

  // Determine isPlex from data directly
  const isPlex = data?.ratingKey ? true : false;
  const [trailerURL, setTrailerURL] = useState("");
  const [imageArtUrl, setImageArtUrl] = useState(null);

    useEffect(() => {
      if (isPlex && data.Extras && data.Extras.Video && data.Extras.Video.Media && data.Extras.Video.Media.Part && data.Extras.Video.Media.Part.key) {
        const fetchTrailer = async () => {
          setLoadingTrailer(true);
          const trailerPath = data.Extras.Video.Media.Part.key;
          try {
            const encodedTrailerPath = encodeURIComponent(trailerPath);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/plex_media/trailer?trailer_path=${encodedTrailerPath}`, {
              headers: {
                Authorization: "Bearer " + token,
                Accept: 'video/mp4;charset=UTF-8',
              },
              responseType: 'arraybuffer'
            });

            let blob = new Blob([response.data], { type: 'video/mp4' });
            let dataUrl = URL.createObjectURL(blob);
            setTrailerURL(dataUrl);

          } catch (error) {
            console.error('Failed to fetch the trailer:', error);
          } finally {
            setLoadingTrailer(false);
          }
        };
        fetchTrailer();
      }
      return () => {
        setTrailerURL("");
      };
    }, [isPlex, data, token]);


    useEffect(() => {
      if (data.art) {
        const fetchArtwork = async () => {
          setLoadingArt(true);
          try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/plex_media/art?art_path=${encodeURIComponent(data.art)}`, {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
                Accept: "application/json",
              }
            });

            let blob = await response.blob();
            let dataUrl = URL.createObjectURL(blob);
            setImageArtUrl(dataUrl);
          } catch (error) {
            console.error("Failed to fetch artwork:", error);
          } finally {
            setLoadingArt(false); // End loading once done
          }
        };
        fetchArtwork();
      }
      return () => {
        setImageArtUrl(null);
      };
    }, [data.art, token]);


    const hideSpinner = () => setLoading(false);

    const extractGenres = (data) => {
      if (isPlex && data.Genre) {
          // If data.Genre is an array
          if (Array.isArray(data.Genre)) {
              return data.Genre.map(genre => genre.tag);
          }
          // If data.Genre is an object (single genre)
          else if (typeof data.Genre === 'object' && data.Genre.tag) {
              return [data.Genre.tag];
          }
      } else if (data.genres) {
          // If data has genres property (assumed to be from the database)
          return data.genres;
      }
      return [];  // default to an empty array if no genres found
    }

    const renderVideoContent = () => {
      if (loadingTrailer) {
          return (
              <div className="loader">
                  <ClipLoader
                      loading={loadingTrailer}
                      size={30}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      cssOverride={override}
                  />
              </div>
          );
      }

      if (trailerURL) {
          return (
              <video
                  controls
                  width="100%"
                  height="100%"
                  onLoad={hideSpinner}
                  className="modalTrailer"
                  title={isPlex ? data.title : (data.name || data.title)}
                  poster={imageArtUrl}
              >
                  <source src={trailerURL} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          );
      }

      if (data.video) {
          return (
              <iframe
                  onLoad={hideSpinner}
                  src={data.video}
                  title={isPlex ? data.title : (data.name || data.title)}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
                  className="modalTrailer"
                  loading="eager"
              />
          );
      }

      return null;  // Return null if no valid video content
    };

    const override: CSSProperties = {
        borderColor: "#ff7c62",
        height: "45px",
        width: "40px"
    };

    return (
      <div className="modalBackground">
          <div className="titleCloseBtn">
              <FontAwesomeIcon
                  onClick={() => closeModal(false)}
                  icon={faSquareXmark}
                  size="3x"
              />
          </div>
          <div className="modalHeader">
              <div className="modalTitle">
                  <h2>{isPlex ? data.title : (data.name || data.title)}</h2>
              </div>
          </div>
          <div className="modalBody">
                <div className="video">
                    {renderVideoContent()}
                </div>
                <div className="modalDescription">
                    <p>{isPlex ? data.summary : data.overview}</p>
                </div>
                <div className="modalGenres">
                  {extractGenres(data).map((genre, i) => (
                      <li className="modalGenre" key={i}>
                          {genre}
                      </li>
                  ))}
                </div>
                <div className="modalRating">
                  {isPlex ? (
                      <ul>
                          {data.audienceRating && (
                              <li>
                                  {data.audienceRatingImage && data.ratingImage && data.ratingImage.includes('rottentomatoes') && (
                                      <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                                  )}
                                  {data.rating}
                              </li>
                          )}
                          {data.Rating && (
                              <li>
                                  {data.Rating.image && data.Rating.image.includes('themoviedb') && (
                                      <img src={tmdbLogo} alt="TMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                                  )}
                                  {data.Rating.value}
                              </li>
                          )}
                      </ul>
                  ) : (
                      data.ratings && data.ratings.length > 0 && (
                          <ul>
                              {data.ratings.map((rating, index) => (
                                  <li key={index}>
                                      {rating.Source === 'Internet Movie Database' && (
                                          <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                                      )}
                                      {rating.Source === 'Metacritic' && (
                                          <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                                      )}
                                      {rating.Source === 'Rotten Tomatoes' && (
                                          <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                                      )}
                                      {rating.Value}
                                  </li>
                              ))}
                          </ul>
                      )
                  )}
              </div>
            </div>
        </div>
    );
}

export default Modal;
