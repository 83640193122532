export const isOAuthUser = (user) => {
  // A user is considered an OAuth user if they:
  // 1. Signed up with Google (has google_id)
  // 2. Signed up with Facebook (has facebook_id)
  // 3. Used Plex SSO to sign up (has plex_id but no password)
  return !!(
    user?.google_id ||
    user?.facebook_id ||
    (user?.plex_id && !user?.has_password)
  );
};

// Helper function to check if a user has linked their Plex account
export const hasLinkedPlexAccount = (user) => {
  return !!user?.plex_id;
};

// Helper function to check if user originally signed up with Plex SSO
export const isPlexSSOUser = (user) => {
  return !!(user?.plex_id && !user?.has_password);
};
