// index.js remains mostly the same, but let's add touch events
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setAppHeight } from './components/setAppHeight';
import { Provider } from 'react-redux';
import store from './redux/store';

// Add both resize and orientation change handlers
window.addEventListener('resize', setAppHeight);
window.addEventListener('orientationchange', () => {
  // Small delay to ensure new dimensions are calculated
  setTimeout(setAppHeight, 100);
});

// Initial setup
setAppHeight();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
