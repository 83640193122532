import React, { useEffect, useState, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown, faBars, faUndo } from '@fortawesome/free-solid-svg-icons'

import { useSprings, animated, useSpring } from "@react-spring/web";
import { useGesture } from "react-with-gesture";
import { createConsumer } from "@rails/actioncable";

import MatchNotifications from "./MatchNotifications";
import StripeSubscription from "./registrations/StripeSubscription";
import Heartbeat from "../useHeartbeat";
import Card from "./Card";
import Modal from "./modal";
import DeckFiltering from "./DeckFiltering";
import "../assets/Deck.css";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const limit = 15
const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable`);

const to = i => ({
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
  delay: 0,
});
const from = i => ({ rot: 0, scale: 1.5, y: -100 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;

function Deck({user, loggedIn, token, subscriptionEndDate, cancelAtPeriodEnd, subscriptionStatus}) {
  const [nope] = useState(() => new Set());
  const [data, setData] = useState([])
  const [activateModal, setActivateModal] = useState(false);
  const [detail, setShowDetail] = useState(false);
  const [detailRequest, setDetailRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [likeProps, setLike] = useSpring(() => ({ opacity: 0, scale: 1 }));
  const [dislikeProps, setDislike] = useSpring(() => ({ opacity: 0, scale: 1 }));
  const [expandSearch, setExpandSearch] = useState(false);
  // const [remainingMorePresses, setRemainingMorePresses] = useState(2 - (parseInt(localStorage.getItem("moreButtonPressCount")) || 0));
  // const [remainingLessPresses, setRemainingLessPresses] = useState(2 - (parseInt(localStorage.getItem("lessButtonPressCount")) || 0));
  // const [remainingUndoPresses, setRemainingUndoPresses] = useState(2 - (parseInt(localStorage.getItem("undoButtonPressCount")) || 0));
  const [swipeCounter, setSwipeCounter] = useState(0)
  const [swipedCards, setSwipedCards] = useState([]);
  const [currentCard, setCurrentCard] = useState(null)
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [swipeContentId, setSwipeContentId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fetchingText, setFetchingText] = useState('Fetching More Items');
  const [lastPreference, setLastPreference] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);

  const handleGenreChange = (newSelectedGenres) => {
    setSelectedGenres(newSelectedGenres);
  }

  const handleRatingChange = (newRatings) => {
    setSelectedRatings(newRatings);
  };

  const getSelectedGenreIds = (selectedGenres) => {
    return Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);
  };

  useEffect(() => {
    const selectedGenreIds = getSelectedGenreIds(selectedGenres);
    const fetchDeck = async () => {
        if (isFetching) return;
        setIsFetching(true);

        let config = {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating=${rating.source}&rating_value=${rating.ratingValue}`).join('') : ""}`,
            config
          )
            .then((response) => {
                setData(response.data);
                setIsFetching(false);
                setCurrentIndex(response.data.length - 1);
            })
            .catch((error) => {
                setIsFetching(false);
                console.error(error);
            });
    };
    if (token) {
        fetchDeck();
    }
  }, [token, selectedGenres, selectedRatings]);

  const [props, set] = useSprings(data.length, i => ({
    ...to(i),
    from: from(i)
  }));

  const handleIndicators = (down, xDir) => {
    const likeOpacity = xDir > 0 && down ? 25 : 0;
    const dislikeOpacity = xDir < 0 && down ? 25 : 0;
    const flashDuration = 2;
    setTimeout(() => {
      setLike({ opacity: likeOpacity, scale: likeOpacity ? 1.2 : 1 });
      setDislike({ opacity: dislikeOpacity, scale: dislikeOpacity ? 1.2 : 1 });
    }, flashDuration);

  };

  const bind = useGesture(
      ({
        args: [index, id],
        down,
        delta: [xDelta],
        distance,
        direction: [xDir],
        velocity,
        event,
      }) => {
        handleIndicators(down, xDir);

        if (!down) {
          const screenWidth = window.innerWidth;
          const threshold = screenWidth / 3.975;

          // Card has left the visible screen towards the left (dislike)
          if (xDelta < -threshold) {
            setSwipeDirection('dislike');
            setSwipeContentId(id);
          }
          // Card has left the visible screen towards the right (like)
          else if (xDelta > threshold) {
            setSwipeDirection('like');
            setSwipeContentId(id);
          }

        if (xDelta < -threshold || xDelta > threshold) {
          setSwipeCounter(prevCount => prevCount + 1);
          setSwipedCards([...swipedCards, {id: id, direction: xDelta > threshold ? 'like' : 'dislike'}])
          setCurrentIndex(prevIndex => prevIndex - 1);
        }

        if (Math.abs(xDelta) > threshold) {
          nope.add(index);
        }
      }
      set(i => {
        if (index !== i) return;

        const isNope = nope.has(index);

        const x = isNope ? (200 + window.innerWidth) * Math.sign(xDelta) : down ? xDelta : 0;

        const rot = xDelta / 100 + (isNope ? Math.sign(xDelta) * 10 * Math.abs(xDelta) / 100 : 0);

        const scale = down ? 1.1 : 1;
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 25, tension: down ? 800 : isNope ? 200 : 500 }
        };
      }, setLoading(false));
    }
  , [token]);

  useEffect(() => {
    const postSwipeAction = async () => {
      // Ensure we have a token and a swipe direction
      if (!token || !swipeDirection) return;

      // Now that we have the token and the swipe direction, make the POST request
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/${swipeDirection === 'like' ? 'liked_contents' : 'disliked_contents'}`, {
          content_id: swipeContentId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        });
      } catch (error) {
        console.error(error);
      }

      // Reset the swipe direction after the request is done
      setSwipeDirection(null);
    };

    postSwipeAction();
  }, [token, swipeDirection]);

  const undoLastSwipe = async () => {
    // Check if there are any remaining presses
    // if (remainingUndoPresses <= 0) return;

    // Update the remaining presses
    // const newUndoButtonPressCount = (parseInt(localStorage.getItem("undoButtonPressCount")) || 0) + 1;
    // localStorage.setItem("undoButtonPressCount", newUndoButtonPressCount);
    // setRemainingUndoPresses(2 - newUndoButtonPressCount);

    if (swipedCards.length === 0) return;

    const lastSwipedCard = swipedCards[swipedCards.length - 1];
    const newSwipedCards = swipedCards.slice(0, -1);

    setSwipedCards(newSwipedCards);

    // fetch the last swiped card and bring it back to the deck
    const fetchedCard = await axios.get(`${process.env.REACT_APP_API_URL}/contents/card/${lastSwipedCard.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
    setData([...data, fetchedCard.data]);
    setCurrentIndex(prevIndex => prevIndex + 1);
  };

  const resetButtonPressCountsIfNeeded = () => {
    const today = new Date().toISOString().slice(0, 10);
    const lastPressedDate = localStorage.getItem('lastPressedDate');

    if (lastPressedDate !== today) {
      localStorage.setItem('lastPressedDate', today);
      localStorage.setItem('moreButtonPressCount', '0');
      localStorage.setItem('lessButtonPressCount', '0');
      localStorage.setItem('undoButtonPressCount', '0');
      return true;
    }

    return false;
  }

  const shouldDisableButtons = () => {
    if (resetButtonPressCountsIfNeeded()) {
      return false;
    }

    const moreButtonPressCount = parseInt(localStorage.getItem('moreButtonPressCount')) || 0;
    const lessButtonPressCount = parseInt(localStorage.getItem('lessButtonPressCount')) || 0;

    return moreButtonPressCount >= 2 && lessButtonPressCount >= 2;
  };

  const handleMoreCards = async (preference, buttonType, callback) => {
    const selectedGenreIds = getSelectedGenreIds(selectedGenres);
    if (isFetching || shouldDisableButtons()) return;
    setIsFetching(true);
    setOffset(offset + limit);
    setLastPreference(preference);

    if (buttonType === 'more') {
      // const newMoreButtonPressCount = (parseInt(localStorage.getItem("moreButtonPressCount")) || 0) + 1;
      // localStorage.setItem("moreButtonPressCount", newMoreButtonPressCount);
      // setRemainingMorePresses(2 - newMoreButtonPressCount);
    } else if (buttonType === 'less') {
      // const newLessButtonPressCount = (parseInt(localStorage.getItem("lessButtonPressCount")) || 0) + 1;
      // localStorage.setItem("lessButtonPressCount", newLessButtonPressCount);
      // setRemainingLessPresses(2 - newLessButtonPressCount);
    }
    // setResetState(offset)
    // setTimeout(() => (nope.clear() || set(i => to(i))) && setLoading(true), 600);

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // Update the API URL to include the preference parameter
    axios
    .get(
      `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&preference=${preference}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating=${rating.source}&rating_value=${rating.ratingValue}`).join('') : ""}`,
      config
    )
    .then((response) => {
      setData(response.data);
      setIsFetching(false);
      setCurrentIndex(response.data.length - 1);
      callback();
    })
    .catch((error) => {
      setIsFetching(false);
      console.error(error);
    });
  };

const handleDiscoverCards = async (callback) => {
  const selectedGenreIds = getSelectedGenreIds(selectedGenres);
  if (isFetching) return;
  setIsFetching(true);
  setOffset(offset + limit);

  let config = {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  axios
    .get(
      `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating=${rating.source}&rating_value=${rating.ratingValue}`).join('') : ""}`,
      config
    )
    .then((response) => {
      setData(response.data);
      setIsFetching(false);
      setCurrentIndex(response.data.length - 1);
      callback();
    })
    .catch((error) => {
      setIsFetching(false);
      console.error(error);
    });
  };


  const clickHandler = () => {
    setActivateModal(true);
    setDetailRequest(true);
    fetchDetail();
  };

  const fetchDetail = async (id) => {
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/${currentCard}`, config);
      setDetailRequest(false);
      setShowDetail(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (resetButtonPressCountsIfNeeded()) {
  //     setRemainingMorePresses(2);
  //     setRemainingLessPresses(2);
  //     setRemainingUndoPresses(2);
  //   }
  // }, []);

  const expandSearchResults = async () => {
    const selectedGenreIds = getSelectedGenreIds(selectedGenres);
    if (isFetching) return;
    setIsFetching(true);
    setExpandSearch(true)

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
    .get(
      `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}&expand_search=true${lastPreference ? `&preference=${lastPreference}` : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating=${rating.source}&rating_value=${rating.ratingValue}`).join('') : ""}`,
      config
      )
      .then((response) => {
        setData(response.data);
        setIsFetching(false);
        setCurrentIndex(response.data.length - 1);
      })
      .catch((error) => {
        setIsFetching(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex < data.length) {
        setCurrentCard(data[currentIndex].id);
    }
  }, [data, currentIndex]);

  useEffect(() => {
    let timeoutId;

    if (isFetching) {
      let messages = [
        'I feel the need... the need for speed. But clearly, I don’t have it!',
        '“To infinity and beyond!” - If only my loading speed was like Buzz Lightyear\'s',
        '“I’m king of the world!” – Me if this ever loads',
        'Searching the Matrix for your content...',
        'May the Force be with you... and my loading speed',
        'Hold on to your butts... Jurassic Loading in progress',
        'Wingardium Loadiosa! Is it working?',
        '“I’ll be back” – Me coming back with your content, eventually',
        '“Here’s looking at you, kid.” – Me to the server, hoping it hurries up',
        'Engaging warp speed! (Star Trek style, but slower)',
        '“My precious!” – What I’ll say when it finally loads',
        'Just like Inception, we need to go deeper to fetch the items!',
        '“Houston, we have a problem.” - Too slow loading speed!',
        '“Life is like a box of chocolates; you never know what you’re gonna get.” Just like this loading time!',
        '“A wizard is never late, nor is he early, he arrives precisely when he means to.” - Gandalf on loading times',
        '“There’s no place like home” – and there’s no time like loading time',
        'This isn’t as fast as light-speed travel in Star Wars, but it’s close...ish',
        'Keep calm and wait for the Transformers to bring your data',
        '“Nobody puts Baby in a corner.” Except slow loading times, apparently',
        'Beetlejuice! Beetlejuice! Beetlejuice! Did it load yet?'
      ];

      for (let i = messages.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [messages[i], messages[j]] = [messages[j], messages[i]];
      }

      let messageIndex = 0;
      // Change the text every 3 seconds
      timeoutId = setInterval(() => {
        messageIndex = (messageIndex + 1) % messages.length;
        setFetchingText(messages[messageIndex]);
      }, 3000);
    }

    // Cleanup: clear interval when component unmounts or when isFetching changes to false
    return () => clearInterval(timeoutId);
  }, [isFetching]);

  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  const LikeIndicator = ({ style }) => (
    <animated.div
      className="like-dislike-indicator"
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "10rem",
        color: "#28a745",
        userSelect: "none",
        zIndex: 5,
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
      }}
    >
      <FontAwesomeIcon icon={faThumbsUp} />
    </animated.div>
  );

  const DislikeIndicator = ({ style }) => (
    <animated.div
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "10rem",
        color: "#dc3545",
        userSelect: "none",
        zIndex: 5,
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
      }}
    >
      <FontAwesomeIcon icon={faThumbsDown} />
    </animated.div>
  );

  const cards = props.map(({ x, y, rot, scale, id }, i) => (
    <Card
      i={i}
      x={x}
      y={y}
      id={id}
      trans={trans}
      data={data}
      bind={bind}
      key={i}
      rot={rot}
      scale={scale}
      token={token}
      user={user}
      setCurrentCard={setCurrentCard}
    />
  ));

  return (
    <div id="deck" data-testid="deck">
      <LikeIndicator style={likeProps}/>
      <DislikeIndicator style={dislikeProps} />
      {cards}
      {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && !isFetching && currentIndex >= 0 && currentIndex < data.length && <button onClick={undoLastSwipe} data-testid="undobtn" className="undo-button">
          <FontAwesomeIcon icon={faUndo} />
      </button>}
      {!isFetching && currentIndex >= 0 && currentIndex < data.length && <button onClick={() => clickHandler()} className="fetchDetail-button" data-testid="fetchdetailsbtn">
          <FontAwesomeIcon icon={faBars} />
      </button>}
      <Heartbeat loggedIn={loggedIn} user={user} />
      <MatchNotifications cable={cable} user={user} token={token} loggedIn={loggedIn} />
      {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && (
        <DeckFiltering onGenreChange={handleGenreChange} onRatingChange={handleRatingChange} token={token} />
      )}
      <div className="deck-container">
        {isFetching ? (
          <div className="fetchingContainer">
            <ClipLoader
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
              cssOverride={override}
            />
            <p className="isFetchingText">{fetchingText}</p>
          </div>
        ) : (
          <div className="optionsContainer" style={{ display: swipeCounter >= data.length ? 'block' : 'none' }}>
          <div className="button-container">
            { data.length !== 0 &&
              <button
                className="load-button"
                onClick={() => { handleDiscoverCards(() => { nope.clear() || set(i => to(i)); setLoading(true); setSwipeCounter(0); }); }}>
                Explore more titles
              </button>
            }
            {((subscriptionStatus === '') || (subscriptionStatus === 'canceled')) && (
            <div className="stripeMinimal-container">
              <StripeSubscription
                token={token}
                minimalInformation
                subscriptionEndDate={subscriptionEndDate}
                cancelAtPeriodEnd={cancelAtPeriodEnd}
                subscriptionStatus={subscriptionStatus}
              />
            </div>
            )}
            {data.length === 0 && !expandSearch && !isFetching && (
                <div className="expand-search">
                    <p>You've swiped through all the content from your preferred providers before! Click the button below to discover more from other providers.</p>
                    <button className="load-button" onClick={expandSearchResults}>Discover More</button>
                </div>
            )}
            {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && data.length !== 0 && (
              <>
                <button
                  className="load-button"
                  onClick={() => {
                    handleMoreCards("more", "more", () => {
                      nope.clear() || set(i => to(i));
                      setLoading(true);
                      setSwipeCounter(0);
                    });
                  }}
                >
                  <p>Similar to what you like</p>
                </button>
                <button
                  className="load-button"
                  onClick={() => {
                    handleMoreCards("less", "less", () => {
                      nope.clear() || set(i => to(i));
                      setLoading(true);
                      setSwipeCounter(0);
                    });
                  }}
                >
                  <p>Different from what you like</p>
                </button>
              </>
            )}
            </div>
          </div>
        )}
      </div>
      {activateModal ? ( detailRequest === false ? ( <Modal {...detail} closeModal={setActivateModal} /> ) : (
        <ClipLoader
          size={40}
          color="#ff7c62"
          className="modalSpinner"
        /> )) : ( "" )}
    </div>
  );
}

export default Deck;
