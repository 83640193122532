import React from 'react';
import { useSelector } from 'react-redux';
import plexLogo from '../assets/images/plex-logo.png';
import '../assets/PlexDirectMediaLink.css';

const PlexDirectMediaLink = ({ ratingKey }) => {
  const token = useSelector((state) => state.token.value);

  const handleRedirect = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/plex_media/prepare_redirect_to_plex/${ratingKey}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.redirectUrl; // Perform the redirect
      } else {
        console.error('Failed to get redirect URL');
      }
    } catch (error) {
      console.error('Error fetching redirect URL:', error);
    }
  };

  return (
    <div className='watch_on_container'>
      <a href="/" onClick={handleRedirect} style={{ color: 'inherit', textDecoration: 'none' }}>
        <img src={plexLogo} alt="plexLogo" />
        <div className="open_in_plex">Open in Plex</div>
      </a>
    </div>
  );
};

export default PlexDirectMediaLink;
