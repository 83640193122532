import React, { useEffect, useRef, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import ContentDetail from './ContentDetail';
import Confetti from 'react-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';

import axios from 'axios';

import '../assets/MatchNotifications.css'

function MatchNotifications({ user, token, loggedIn }) {
  const [matched, setMatched] = useState(false);
  const [data, setData] = useState([]);
  const [friend, setFriend] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContentDetail, setShowContentDetail] = useState(null);

  const cableRef = useRef(null);
  const subscriptionRef = useRef(null);

  // console.log(data)
  // console.log(friend)
  // console.log(content)

  // console.log(matched)

  useEffect(() => {
    if (loggedIn && user) {
      (async () => {
        cableRef.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);
        subscriptionRef.current = cableRef.current.subscriptions.create(
          { channel: 'MatchChannel', user_id: user.id },
          {
            received: (data) => {
              // console.log("Received data:", data);
              setData(data.payload);

              if (data.payload) {
                setMatched(true);
              }
            },
          }
        );
      })();
    }

    return () => {
      if (subscriptionRef.current) {
        // console.log('disconnecting and unsubscribing...')
        subscriptionRef.current.unsubscribe();
      }
      if (cableRef.current) {
        cableRef.current.disconnect();
      }
    };
  }, [user, token, loggedIn]);

  useEffect(() => {
    if (matched) {
      (async () => {
        let config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          }
        }
        const fetchContent = async () => {
          try {
            const [currentUserResponse, friendResponse, contentResponse] = await Promise.all([
              axios.get(`${process.env.REACT_APP_API_URL}/users/${data.user_id}`, config),
              axios.get(`${process.env.REACT_APP_API_URL}/users/${data.friend_id}`, config),
              axios.get(`${process.env.REACT_APP_API_URL}/contents/${data.content_id}`, config)
            ]);
            setCurrentUser(currentUserResponse.data.user)
            setFriend(friendResponse.data.user);
            setContent(contentResponse.data);
            setLoading(false);
            // Move handleMatch() inside fetchContent() and call it after all three requests have completed successfully
            await axios.post(`${process.env.REACT_APP_API_URL}/matches`, {
              user_id: currentUserResponse.data.user.id,
              friend_id: friendResponse.data.user.id,
              content_id: data.content_id,
            }, config);
          } catch (error) {
            console.error(error);
          }
        };
        fetchContent();
      })();
    }
  }, [data.user_id, data.friend_id, data.content_id, matched, token]);

  const handleWatchNow = () => {
    setShowContentDetail(data.content_id);
  };


  const handleNoThanks = () => {
    setMatched(false);
  };

  return (
    <div>
      <CSSTransition
        in={matched}
        timeout={300}
        classNames="matched-popup"
        unmountOnExit
      >
        <div className="popup">
          <div className="popup-content">
            <p>You've matched</p>
            <h3>{content.title || content.name}</h3>
            <img className="popup-poster" src={content.poster_path} alt={content.title} />
            <p className="matched-friend">
              {user.id === currentUser.id
                ? `with your friend ${friend.firstname}`
                : `with your friend ${currentUser.firstname}`}
            </p>
            <div className="matched-button-container">
              <button data-testid='btn-yes' className="watch-now" onClick={handleWatchNow}>
                <FontAwesomeIcon icon={faThumbsUp} /> Watch Now
              </button>
              <button data-testid='btn-no' className="no-thanks" onClick={handleNoThanks}>
                <FontAwesomeIcon icon={faThumbsDown} /> No Thanks
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
      {matched && (
        <div className="confetti-container">
          <div className="matched-modal-overlay" onClick={handleNoThanks} />
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </div>
      )}
      {showContentDetail && (
        <ContentDetail
          token={token}
          contentId={showContentDetail}
          closeDetail={() => setShowContentDetail(null)}
          user={currentUser}
          friend={friend}
        />
      )}
    </div>
  );
}

export default MatchNotifications;
