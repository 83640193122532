import React, { useEffect, useState, CSSProperties } from 'react';
import axios from 'axios';
import RatingSlider from '../RatingSlider';
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from 'react-redux';

import '../../assets/DeckFiltering.css';

function DeckFiltering({
  onGenreChange,
  onRatingChange,
  plexLibraries,
  selectedPlexLibraries,
  onSelectPlexLibrary,
  selectedCollections,
  onSelectCollection
}) {
    // Existing core state
    const [genres, setGenres] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [typedStrings, setTypedStrings] = useState([]);
    const [currentString, setCurrentString] = useState("");
    const [stringIndex, setStringIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [plexDropdownVisible, setPlexDropdownVisible] = useState(false);
    const [collectionsByType, setCollectionsByType] = useState({});
    const [expandedTypes, setExpandedTypes] = useState([]);

    // New pending state for button implementation
    const [pendingGenres, setPendingGenres] = useState([]);
    const [pendingRatings, setPendingRatings] = useState([]);
    const [pendingPlexLibraries, setPendingPlexLibraries] = useState([]);
    const [pendingCollections, setPendingCollections] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);

    const token = useSelector((state) => state.token.value);

    const override: CSSProperties = {
        borderColor: "#ff7c62",
        height: "24px",
        width: "24px"
    };

    const areArraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      const sorted1 = [...arr1].sort();
      const sorted2 = [...arr2].sort();
      return sorted1.every((value, index) => value === sorted2[index]);
    };

    const areRatingsEqual = (ratings1, ratings2) => {
        return JSON.stringify(ratings1) === JSON.stringify(ratings2);
    };

    // Initialize pending state with current selections
    useEffect(() => {
        setPendingPlexLibraries(selectedPlexLibraries);
    }, [selectedPlexLibraries]);

    useEffect(() => {
        setPendingCollections(selectedCollections);
    }, [selectedCollections]);

    // Typed string effect
    useEffect(() => {
        // Prepare Strings for Typed
        let newStrings = genres.map(genre => `${genre.name}`);
        if (pendingRatings.length > 0) {
            newStrings = newStrings.concat(pendingRatings.map(rating => `${rating.source}`));
        }

        // Randomize array using Durstenfeld shuffle
        for (let i = newStrings.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newStrings[i], newStrings[j]] = [newStrings[j], newStrings[i]];
        }

        setTypedStrings(newStrings);
    }, [genres, pendingRatings]);

    // Handle clicking outside dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
          // Check if the click/touch is outside filter-container
          if (dropdownVisible && !event.target.closest(".filter-container")) {
              setDropdownVisible(false);
          }
          // Also check if the user is clicking/touching far from the content (sides of screen)
          if (dropdownVisible && (event.clientX < 20 || event.clientX > window.innerWidth - 20)) {
              setDropdownVisible(false);
          }
      };

      // Add passive: true to improve scroll performance
      const options = { passive: true };

      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside, options);
      document.addEventListener("touchend", handleClickOutside, options);

      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("touchstart", handleClickOutside);
          document.removeEventListener("touchend", handleClickOutside);
      };
    }, [dropdownVisible]);

    useEffect(() => {
      if (!dropdownVisible) return;

      let touchStartY = 0;
      const handleTouchStart = (e) => {
          touchStartY = e.touches[0].clientY;
      };

      const handleTouchEnd = (e) => {
          const touchEndY = e.changedTouches[0].clientY;
          const touchDiff = Math.abs(touchEndY - touchStartY);

          // If the user has scrolled more than 100px and then stops
          // Check if they're near the top or bottom of the scrollable area
          if (touchDiff > 100) {
              const element = e.target.closest('.scrollable-container');
              if (element) {
                  const { scrollTop, scrollHeight, clientHeight } = element;
                  if (scrollTop === 0 || scrollTop + clientHeight >= scrollHeight) {
                      // We're at the top or bottom, prevent touch getting stuck
                      e.preventDefault();
                      e.stopPropagation();
                  }
              }
          }
      };

      document.addEventListener('touchstart', handleTouchStart, { passive: true });
      document.addEventListener('touchend', handleTouchEnd, { passive: false });

      return () => {
          document.removeEventListener('touchstart', handleTouchStart);
          document.removeEventListener('touchend', handleTouchEnd);
      };
    }, [dropdownVisible]);

    // Fetch genres
    useEffect(() => {
        const fetchGenres = async () => {
            let config = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/filters`, config);
                setGenres(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        if (token) {
            fetchGenres();
        }
    }, [token]);

    // Fetch ratings
    useEffect(() => {
        const fetchRatings = async () => {
            let config = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/ratings`, config);
                const initialRatings = response.data.map((rating) => ({
                    source: rating.source,
                    ratingValue: 0,
                }));
                initialRatings.push({ source: "Plex", ratingValue: 0 });
                setPendingRatings(initialRatings);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        if (token) {
            fetchRatings();
        }
    }, [token]);

    // Fetch collections
    useEffect(() => {
        const fetchCollections = async () => {
            let config = {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            const libraryKeys = plexLibraries.map((library) => library.key);
            const apiUrl = `${process.env.REACT_APP_API_URL}/plex_media/collections?library_keys=${libraryKeys.join(',')}`;

            try {
                const response = await axios.get(apiUrl, config);
                const groupedCollections = response.data.reduce((acc, collection) => {
                    const capitalizedType = collection.type.charAt(0).toUpperCase() + collection.type.slice(1);
                    if (!acc[capitalizedType]) {
                        acc[capitalizedType] = [];
                    }
                    acc[capitalizedType].push(collection);
                    return acc;
                }, {});
                setCollectionsByType(groupedCollections);
            } catch (error) {
                console.error(error);
            }
        };

        if (token && plexLibraries.length > 0) {
            fetchCollections();
        }
    }, [token, plexLibraries]);

    // Typing effect
    useEffect(() => {
        if (typedStrings.length === 0) return;

        const currentWord = typedStrings[stringIndex];
        const typingSpeed = isDeleting ? 50 : 150;

        const timer = setTimeout(() => {
            if (!isDeleting && charIndex < currentWord.length) {
                setCurrentString(prev => prev + currentWord[charIndex]);
                setCharIndex(prev => prev + 1);
            } else if (isDeleting && charIndex > 0) {
                setCurrentString(prev => prev.slice(0, -1));
                setCharIndex(prev => prev - 1);
            } else if (charIndex === currentWord.length) {
                setIsDeleting(true);
            } else if (charIndex === 0 && isDeleting) {
                setIsDeleting(false);
                setStringIndex((prevIndex) => (prevIndex + 1) % typedStrings.length);
            }
        }, typingSpeed);

        return () => clearTimeout(timer);
    }, [charIndex, stringIndex, typedStrings, isDeleting]);

    // Handler functions
    const handleOptionClick = (id) => {
      setTimeout(() => {
          const updatedGenres = !pendingGenres.includes(id)
              ? [...pendingGenres, id]
              : pendingGenres.filter((genreId) => genreId !== id);
          setPendingGenres(updatedGenres);
          // Compare with original selections
          setHasChanges(
              !areArraysEqual(updatedGenres, []) ||
              !areArraysEqual(pendingPlexLibraries, selectedPlexLibraries) ||
              !areArraysEqual(pendingCollections, selectedCollections)
          );
      }, 10);
    };


    const removeSelectedGenre = (id) => {
      const updatedGenres = pendingGenres.filter((genreId) => genreId !== id);
      setPendingGenres(updatedGenres);
      setHasChanges(
          !areArraysEqual(updatedGenres, []) ||
          !areArraysEqual(pendingPlexLibraries, selectedPlexLibraries) ||
          !areArraysEqual(pendingCollections, selectedCollections)
      );
    };

    const handleRatingChange = (source, newValue) => {
      const updatedRatings = pendingRatings.map((rating) =>
          rating.source === source ? { ...rating, ratingValue: newValue } : rating
      );
      setPendingRatings(updatedRatings);
      setHasChanges(
          !areArraysEqual(pendingGenres, []) ||
          !areRatingsEqual(updatedRatings, pendingRatings) ||
          !areArraysEqual(pendingPlexLibraries, selectedPlexLibraries) ||
          !areArraysEqual(pendingCollections, selectedCollections)
      );
    };


    const handlePlexLibraryChange = (libraryKey) => {
      const updatedLibraries = pendingPlexLibraries.includes(libraryKey)
          ? pendingPlexLibraries.filter((key) => key !== libraryKey)
          : [...pendingPlexLibraries, libraryKey];
      setPendingPlexLibraries(updatedLibraries);
      setHasChanges(
          !areArraysEqual(pendingGenres, []) ||
          !areArraysEqual(updatedLibraries, selectedPlexLibraries) ||
          !areArraysEqual(pendingCollections, selectedCollections)
      );
    };

    const handleCollectionChange = (collectionKey) => {
      const updatedCollections = pendingCollections.includes(collectionKey)
          ? pendingCollections.filter((key) => key !== collectionKey)
          : [...pendingCollections, collectionKey];
      setPendingCollections(updatedCollections);
      setHasChanges(
          !areArraysEqual(pendingGenres, []) ||
          !areArraysEqual(pendingPlexLibraries, selectedPlexLibraries) ||
          !areArraysEqual(updatedCollections, selectedCollections)
      );
    };

    const toggleTypeExpansion = (type) => {
        setExpandedTypes(expandedTypes.includes(type)
            ? expandedTypes.filter((t) => t !== type)
            : [...expandedTypes, type]
        );
    };

    // New function to apply all pending changes
    const applyFilters = () => {
        // Convert genres to object format expected by parent
        const genresObject = genres.reduce((acc, genre) => {
            acc[genre.id] = pendingGenres.includes(genre.id);
            return acc;
        }, {});

        // Call all parent callbacks with pending values
        onGenreChange(genresObject);
        onRatingChange(pendingRatings);
        onSelectPlexLibrary(pendingPlexLibraries);
        onSelectCollection(pendingCollections);

        setHasChanges(false);
        setDropdownVisible(false);
    };

    return (
        <div className="filter-container">
            <div className="input-field" data-testid="inputfieldbox" onClick={() => setDropdownVisible(!dropdownVisible)}>
                {loading ? (
                    <ClipLoader
                        loading={true}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        cssOverride={override}
                    />
                ) : pendingGenres.length === 0 && !dropdownVisible && (
                    <div className="typed-strings">
                        <p>Filter by: {currentString}</p>
                    </div>
                )}
                {pendingGenres.map((id) => {
                    const genre = genres.find((genre) => genre.id === id);
                    return genre ? (
                        <div key={id} className="selected-genre">
                            {genre.name}
                            <span className="remove-genre" onClick={(e) => { e.stopPropagation(); removeSelectedGenre(id); }}>&times;</span>
                        </div>
                    ) : null;
                })}
            </div>
            {dropdownVisible && (
                <div className="scrollable-container">
                    <div className="dropdown" data-testid="dropdown">
                        <input
                            type="text"
                            className="filter-input"
                            placeholder="Filter..."
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        {genres
                            .filter((genre) => genre.name.toLowerCase().includes(filter.toLowerCase()))
                            .map((genre) => (
                                <div
                                    key={genre.id}
                                    className="dropdown-option"
                                    onClick={() => handleOptionClick(genre.id)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={pendingGenres.includes(genre.id)}
                                        readOnly
                                    />
                                    {genre.name}
                                </div>
                            ))}
                    </div>
                    {plexLibraries.length > 0 && (
                        <div className="plex-library-selector">
                            <div
                                className="plex-library-dropdown"
                                onClick={() => setPlexDropdownVisible(!plexDropdownVisible)}
                            >
                                <span>
                                    {pendingPlexLibraries.length > 0
                                        ? `Selected Libraries (${pendingPlexLibraries.length})`
                                        : 'Select Plex Libraries'}
                                </span>
                                <i className={`arrow ${plexDropdownVisible ? 'up' : 'down'}`}></i>
                            </div>
                            {plexDropdownVisible && (
                                <div className="plex-library-options">
                                    {plexLibraries.map((library) => (
                                        <div key={library.key} className="plex-library-option">
                                            <input
                                                type="checkbox"
                                                id={`plex-library-${library.key}`}
                                                checked={pendingPlexLibraries.includes(library.key)}
                                                onChange={() => handlePlexLibraryChange(library.key)}
                                            />
                                            <label htmlFor={`plex-library-${library.key}`}>
                                                {library.title}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="collections-container">
                        {Object.entries(collectionsByType).map(([type, collections]) => (
                            <div key={type} className="collection-type">
                                <div
                                    className="collection-type-dropdown"
                                    onClick={() => toggleTypeExpansion(type)}
                                >
                                    <span>Plex {type} Collections</span>
                                    <i className={`arrow ${expandedTypes.includes(type) ? 'up' : 'down'}`}></i>
                                </div>
                                {expandedTypes.includes(type) && (
                                    <div className="collection-type-options">
                                        {collections.map((collection) => (
                                            <div key={collection.key} className="collection-option">
                                                <input
                                                    type="checkbox"
                                                    id={`collection-${collection.key}`}
                                                    checked={pendingCollections.includes(collection.key)}
                                                    onChange={() => handleCollectionChange(collection.key)}
                                                />
                                                <label htmlFor={`collection-${collection.key}`}>
                                                    {collection.title}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="ratings-container" data-testid="rating-slider">
                        {pendingRatings
                            .filter(rating => rating.source)
                            .map((rating) => (
                                <div
                                    className="rating-slider"
                                    data-testid={`rating-slider-${rating.source}`}
                                    key={rating.source}
                                >
                                    <RatingSlider
                                        rating={rating}
                                        onRatingChange={handleRatingChange}
                                    />
                                </div>
                            ))}
                    </div>
                    <div className="filter-actions">
                        <button
                            className="apply-filters-btn"
                            onClick={hasChanges ? applyFilters : () => setDropdownVisible(false)}>
                            {hasChanges ? 'Apply Filters' : 'Close'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default DeckFiltering;
