import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { setIsFetching, setData, resetDeck } from '../redux/slices/deckSlice';

const useDiscoverCards = (selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset, expandSearch, setOffset) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);
  const isFetching = useSelector((state) => state.deck.isFetching);

  const handleDiscoverCards = async (callback) => {
    const selectedGenreIds = Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);
    if (isFetching) return;

    dispatch(setIsFetching(true));
    setOffset(offset + limit);

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios.get(
      `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}${expandSearch ? "&expand_search=true" : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating[]=${rating.source}&rating_value[]=${rating.ratingValue}`).join('') : ""}&selected_libraries=${selectedPlexLibraries.join(",")}&selected_collections=${selectedCollections.join(",")}`,
      config
    )
      .then((response) => {
        const { contents, metadata } = response.data;
        if (metadata.no_results) {
          dispatch(setData([]));
        } else {
          dispatch(setData(contents));
          dispatch(resetDeck());
        }
        dispatch(setIsFetching(false));
        callback();
      })
      .catch((error) => {
        dispatch(setIsFetching(false));
        console.error(error);
      });
  };

  return { handleDiscoverCards };
};

export default useDiscoverCards;
