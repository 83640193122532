import React, { useState, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';

const StripeStatusHandler = ({ user, loggedIn, token, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd }) => {

  useEffect(() => {
    let subscription;

    if (loggedIn && user && token) {
      const authToken = token;
      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${authToken}`);

      subscription = cable.subscriptions.create(
        { channel: 'StripeChannel', auth_token: authToken, user: user.id },
        {
          connected: () => {
            console.log(`User: ${user.id} successfully connected to StripeChannel`);
          },
          disconnected: () => {
            console.log(`User: ${user.id} disconnected from StripeChannel`);
          },
          received: (data) => {
            console.log(`User: ${user.id} received data in StripeChannel`, data);
            setSubscriptionStatus(data.subscription_status);
            setCancelAtPeriodEnd(data.cancel_at_period_end);
            setSubscriptionEndDate(data.current_period_end);
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [loggedIn, user, token, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd]);

  return null;
};

export default StripeStatusHandler
