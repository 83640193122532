import React from 'react';
import { useSelector } from 'react-redux';
import '../assets/LoggedInHomepage.css';
import flickswipeLogo from '../assets/images/flickswipe_logo.png';
import Tutorial from './Tutorial';
import { Link } from 'react-router-dom';

const LoggedInHomepage = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const subscriptionStatus = useSelector((state) => state.subscription.status);
  const endDate = useSelector((state) => state.subscription.endDate);
  const cancelAtPeriodEnd = useSelector((state) => state.subscription.cancelAtPeriodEnd);

  const isPremium = subscriptionStatus === 'trialing' || subscriptionStatus === 'active';

  const PremiumContent = () => (
    <div className="home-premium">
      <h2>Welcome to Premium!</h2>
      <p>Enjoy unlimited undo swipes and personalized content!</p>
      {cancelAtPeriodEnd && (
        <p className="subscription-notice">
          Your subscription will end on {new Date(endDate).toLocaleDateString()}
        </p>
      )}
      <div className="premium-features">
        <Tutorial />
      </div>
    </div>
  );


  const FreemiumContent = () => (
    <div className="home-premium">
      <h2>Get Flickswipe Premium</h2>
      <p>Unlimited undo swipes, personalised content and much more!</p>
      <button className="home-free-btn">
        <Link to="/premium">Try it Free!</Link>
      </button>
    </div>
  );

  return (
    <div className="LoggedIn-homepage-container">
      <div className="LoggedIn-logo-container">
        <img src={flickswipeLogo} alt="Flickswipe logo" className="LoggedIn-flickswipe-logo" />
      </div>
      <div className="LoggedIn-tagline-container">
        <h1 className="LoggedIn-tagline">Swipe, Match, Watch!</h1>
        {isPremium ? <PremiumContent /> : <FreemiumContent />}
      </div>
    </div>
  );
};

export default LoggedInHomepage;
