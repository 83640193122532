import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../assets/UserPreferences.css';
import plexLogo from '../assets/images/plex-logo.png';

const UserPreference = ({ saveUserPreferences }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);

  const [allProviders, setAllProviders] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showFeedback, setShowFeedback] = useState('');

  const initialFetchRef = useRef(false);

  const formatProviderName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '_');
  };

  const getProviderLogo = (provider) => {
    if (provider.provider_name.toLowerCase() === 'plex') {
      return plexLogo;
    } else if (provider.source === 'tmdb' && provider.logo_path) {
      return `https://image.tmdb.org/t/p/original${provider.logo_path}`;
    } else if (provider.source === 'rapid_api' && provider.imageSet) {
      return provider.imageSet.whiteImage || provider.imageSet.lightThemeImage || provider.imageSet.darkThemeImage;
    } else if (provider.logo_path) {
      return provider.logo_path;
    }
    return null; // No logo available
  };

  const fetchUserPreferences = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user_preferences`, config);
      // Handle both cases: existing preferences and no preferences
      if (response.data) {
        if (response.data.status === 'success' && response.data.user_preference) {
          return response.data.user_preference.streaming_providers || [];
        }
        // If no preferences exist yet, return empty array
        return [];
      }
      return [];
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      // On error, return empty array rather than failing
      return [];
    }
  };

  const fetchAvailableProviders = async () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/contents/streaming_providers`, config);
      const providers = response.data.providers || {};

      return Object.values(providers).map(provider => ({
        provider_name: provider.name,
        logo_path: provider.logo_path,
        imageSet: provider.imageSet,
        source: provider.source,
        themeColorCode: provider.themeColorCode
      }));
    } catch (error) {
      console.error('Error fetching available providers:', error);
      return [];
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!user || !token || initialFetchRef.current) return;
      initialFetchRef.current = true;

      const userPreferences = await fetchUserPreferences();
      const providers = await fetchAvailableProviders();

      // Always add Plex if user has a plex_id
      if (user.plex_id && !providers.some(p => p.provider_name.toLowerCase() === 'plex')) {
        providers.push({
          provider_name: 'Plex',
          logo_path: plexLogo,
          source: 'tmdb',
          imageSet: undefined,
          themeColorCode: '#ffffff'
        });
      }

      const combinedProviders = providers
        .map(provider => ({
          ...provider,
          isSelected: userPreferences.some(p =>
            p.provider_name.toLowerCase() === provider.provider_name.toLowerCase()
          )
        }))
        .sort((a, b) => a.provider_name.localeCompare(b.provider_name));

      setAllProviders(combinedProviders);
    }
    fetchData();
  }, [user, token]);

  const handleToggleProvider = async (provider) => {
    if (saving) return;
    setSaving(true);

    const updatedProviders = [...allProviders];
    const index = updatedProviders.findIndex(p =>
      p.provider_name.toLowerCase() === provider.provider_name.toLowerCase()
    );

    if (index !== -1) {
      updatedProviders[index] = {
        ...updatedProviders[index],
        isSelected: !updatedProviders[index].isSelected
      };
    }

    // Get only selected providers for saving
    const selectedProviders = updatedProviders
      .filter(p => p.isSelected)
      .map(p => ({
        provider_name: formatProviderName(p.provider_name),
        logo_path: getProviderLogo(p),
        themeColorCode: p.themeColorCode,
        source: p.source
      }));

    try {
      await saveUserPreferences(selectedProviders);
      setAllProviders(updatedProviders);
      setShowFeedback(provider.isSelected ? 'Service removed' : 'Service added');
    } catch (error) {
      console.error('Error updating preferences:', error);
      setShowFeedback('Failed to update service');
    }

    setTimeout(() => setShowFeedback(''), 2000);
    setSaving(false);
  };

  const renderProviderContent = (provider) => {
    const logoUrl = getProviderLogo(provider);
    const separateWords = (text) => {
      return text
        // Handle special cases first
        .replace(/\+/g, ' plus ')  // Replace + with 'plus'
        .replace(/withads$/g, ' with ads')  // Handle 'withads' at the end
        .replace(/amazonchannel$/g, ' amazon channel')  // Handle 'amazonchannel' at the end
        .replace(/appletvchannel$/g, ' apple tv channel')  // Handle 'appletvchannel' at the end
        .replace(/plusamazon/g, ' plus amazon')  // Handle 'plusamazon'
        .replace(/tvplus/g, ' tv plus')  // Handle 'tvplus'
        // Handle specific provider prefixes/suffixes
        .replace(/^all4$/g, 'All 4')  // Special case for All4
        .replace(/^bfi/g, 'BFI ')  // Handle BFI prefix
        .replace(/^itv/g, 'ITV ')  // Handle ITV prefix
        .replace(/^mgm/g, 'MGM ')  // Handle MGM prefix
        // Basic camelCase splitting
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Clean up any double spaces
        .replace(/\s+/g, ' ')
        .trim()
        // Capitalize each word
        .split(' ')
        .map(word => {
          // Don't capitalize certain words unless they're at the start
          const lowercaseWords = ['with', 'and', 'or', 'in', 'on', 'at', 'to'];
          return lowercaseWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    };

    return (
      <div
        className="user-pref-provider-logo-container"
        style={{ backgroundColor: provider.themeColorCode || '#f0f0f0' }}
      >
        <div className="user-pref-provider-image">
          {logoUrl ? (
            <img src={logoUrl} data-testid={provider.provider_name} alt={provider.provider_name} />
          ) : (
            <div className="user-pref-provider-name">{provider.provider_name}</div>
          )}
        </div>
        <div className="user-pref-provider-label">{separateWords(provider.provider_name)}</div>
      </div>
    );
  };

  const selectedCount = allProviders.filter(p => p.isSelected).length;

  return (
    <div>
      <div className='user-pref-provider-header'>
        <h2>Streaming Services</h2>
        <p>Tap a service to add or remove it from your list</p>
        {selectedCount > 0 && (
          <p className="user-pref-provider-count">
            {selectedCount} service{selectedCount !== 1 ? 's' : ''} selected
          </p>
        )}
      </div>

      {showFeedback && (
        <div className="user-pref-feedback-message">
          {showFeedback}
        </div>
      )}

      {allProviders.length > 0 ? (
        <div className="user-pref-provider-grid">
          {allProviders.map((provider, index) => (
            <div
              key={index}
              className={`user-pref-provider-logo ${provider.isSelected ? 'selected' : ''}`}
              onClick={() => handleToggleProvider(provider)}
            >
              {renderProviderContent(provider)}
              {provider.isSelected && (
                <div className="user-pref-selected-overlay">
                  <span className="user-pref-checkmark">✓</span>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="user-pref-no-providers-message">
          No streaming services currently available for selection.
        </div>
      )}
    </div>
  );
};

export default UserPreference;
