import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import useDebounce from "./useDebounce";
import SearchUsers from "./SearchUsers";
import { ClipLoader } from "react-spinners";
import "../assets/userList.css";

const UserList = () => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchComplete, setIsSearchComplete] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sentRequest, setSentRequest] = useState({});
  const [sentInvitationsList, setSentInvitationsList] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // State for recommendations
  const [recommendations, setRecommendations] = useState([]);
  const [hasMoreRecommendations, setHasMoreRecommendations] = useState(true);
  const [recommendationsPage, setRecommendationsPage] = useState(1);
  const [loadingRecommendations, setLoadingRecommendations] = useState(false);

  // State for recent searches
  const [recentSearches, setRecentSearches] = useState(() => {
    const savedSearches = localStorage.getItem("recentSearches");
    return savedSearches ? JSON.parse(savedSearches) : [];
  });

  const initialFetchRef = useRef(false);
  const debouncedSearchValue = useDebounce(searchValue, 350);

  // Handle search input change
  const handleSearch = useCallback((e) => {
    setSearchValue(e.target.value);
    setIsInitialLoad(false);
    setPage(1);
    setSearchResults([]);
  }, []);

  // Fetch recommendations
  const fetchRecommendations = async (pageNumber) => {
    if (!token || !user) return;

    setLoadingRecommendations(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommended_friends?page=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const { users, total_pages, current_page } = response.data;
      setHasMoreRecommendations(current_page < total_pages);

      if (pageNumber === 1) {
        setRecommendations(users);
      } else {
        setRecommendations(prev => [...prev, ...users]);
      }
      setRecommendationsPage(current_page + 1);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    } finally {
      setLoadingRecommendations(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && token && !initialFetchRef.current) {
        initialFetchRef.current = true;
        try {
          // Fetch user requests
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/pending/${user.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSentInvitationsList(response.data.pending_users);
          const updatedSentRequests = {};
          response.data.pending_users.forEach((invitation) => {
            updatedSentRequests[invitation.user.id] = true;
          });
          setSentRequest(updatedSentRequests);

          // Fetch initial recommendations
          fetchRecommendations(1);
        } catch (error) {
          console.error('Error fetching pending requests:', error);
        }
      }
    };

    fetchUserData();
  }, [user, token]);

  // Search users function
  const searchUsers = useCallback(async (searchPage = 1) => {
    if (!token || !user || !debouncedSearchValue.trim()) return;

    setLoading(true);
    setIsSearchComplete(false);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search?search=${encodeURIComponent(debouncedSearchValue.trim())}&page=${searchPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const { users, total_pages, current_page } = response.data;
      setHasMore(current_page < total_pages);

      if (searchPage === 1) {
        setSearchResults(users);
      } else {
        setSearchResults(prev => [...prev, ...users]);
      }
      setPage(current_page + 1);

      // Update recent searches
      if (debouncedSearchValue.trim().length >= 3) {
        setRecentSearches(prev => {
          const newSearches = [
            { value: debouncedSearchValue.trim() },
            ...prev.filter(s => s.value !== debouncedSearchValue.trim()).slice(0, 3)
          ];
          localStorage.setItem("recentSearches", JSON.stringify(newSearches));
          return newSearches;
        });
      }
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setLoading(false);
      setIsSearchComplete(true);
    }
  }, [debouncedSearchValue, token, user]);

  // Effect for handling search
  useEffect(() => {
    let isSubscribed = true;

    if (!isInitialLoad) {
      if (debouncedSearchValue.trim() !== '') {
        searchUsers(1);
      } else {
        setSearchResults([]);
        setHasMore(false);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [debouncedSearchValue, searchUsers, isInitialLoad]);

  // Handle sending friend request
  const sendFriendRequest = async (friend) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/invitations`,
        { user_id: user.id, friend_id: friend.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      );
      setSentRequest(prev => ({ ...prev, [friend.id]: true }));
    } catch (error) {
      console.error("Error sending friend request:", error);
    }
  };

  // Handle recent search click
  const handleRecentSearchClick = (search) => {
    setSearchValue(search.value);
    setIsInitialLoad(false);
  };

  // Load more users on scroll
  const fetchMoreUsers = useCallback(() => {
    if (!loading && hasMore) {
      searchUsers(page);
    }
  }, [loading, hasMore, page, searchUsers]);

  return loggedIn && user ? (
    <div className="user-list-container">
      <div className="userList-header">
        <h1>Search Users</h1>
        <input
          type="text"
          onChange={handleSearch}
          value={searchValue}
          placeholder="Search for users..."
          data-testid="search-input"
        />
      </div>

      {loading && searchResults.length === 0 ? (
        <div className="user-list-container">
          <div className="loader-wrapper">
            <ClipLoader
              color="#ff7c62"
              loading={true}
              size={40}
              aria-label="Loading Spinner"
            />
          </div>
        </div>
      ) : (
        <SearchUsers
          searchValue={searchValue}
          displayResults={searchResults}
          fetchMoreUsers={fetchMoreUsers}
          hasMore={hasMore}
          loading={loading}
          sendFriendRequest={sendFriendRequest}
          sentInvitationsList={sentInvitationsList}
          sentRequest={sentRequest}
          recentSearches={recentSearches}
          handleRecentSearchClick={handleRecentSearchClick}
          recommendations={recommendations}
          fetchMoreRecommendations={() => fetchRecommendations(recommendationsPage)}
          hasMoreRecommendations={hasMoreRecommendations}
          loadingRecommendations={loadingRecommendations}
          isSearchComplete={isSearchComplete}
        />
      )}
    </div>
  ) : null;
};

export default React.memo(UserList);
