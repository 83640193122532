import React from "react";
import '../assets/NotificationCards.css';

const NotificationCard = ({
  firstname,
  lastname,
  imageUrl,
  onAccept,
  onReject,
  testIndex
}) => {
  return (
    <div className="notificationCard">
      <img className="notificationImage" src={imageUrl} alt="profile-pic" />
      <div className="notificationContent">
        <div className="notificationInfo">
          <h2 className="userName">{firstname} {lastname}</h2>
        </div>
        <div className="notificationActions">
          <button
            data-testid={`acceptBtn-${testIndex}`}
            className="acceptBtn"
            onClick={onAccept}
          >
            Confirm
          </button>
          <button
            data-testid={`rejectBtn-${testIndex}`}
            className="rejectBtn"
            onClick={onReject}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
