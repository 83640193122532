import React, { useState, useEffect } from 'react';
import { createConsumer } from '@rails/actioncable';

function useHeartbeat(user, loggedIn, jwt) {
  const HEARTBEAT_INTERVAL = 30 * 1000; // send heartbeat every 30 seconds
  const INACTIVITY_TIMEOUT = 60 * 1000; // disconnect after 60 seconds of inactivity
  const [cable, setCable] = useState(null);

  useEffect(() => {
    let cleanup;

    if (loggedIn && user && jwt) {
      const authToken = jwt;
      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${authToken}`);

      setCable(cable);

      let subscription;
      let lastActive = Date.now();
      let heartbeatInterval;
      let inactivityCheckInterval;

      function connect() {
        if (cable && !subscription) {
          // console.log(`User: ${user.id} Connect to UserStatusChannel`);
          subscription = cable.subscriptions.create(
            { channel: 'UserStatusChannel', auth_token: authToken, user_id: user.id },
            {
              received: (data) => {
                // console.log(`User: ${user.id} Online`);
                setCable(cable);
                updateLastActive();
              },
              connected: () => {
                // console.log(`User: ${user.id} Connected to UserStatusChannel`);
                // send initial heartbeat
                sendHeartbeat();
                // start heartbeat interval
                heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
                // start inactivity check interval
                inactivityCheckInterval = setInterval(checkInactivity, INACTIVITY_TIMEOUT / 2);
              },
              disconnected: () => {
                // console.log(`User: ${user.id} Disconnected from UserStatusChannel`);
                clearInterval(heartbeatInterval);
                clearInterval(inactivityCheckInterval);
                subscription = null;
                setTimeout(() => {
                  connect(); // Reinitialize the subscription after reconnecting
                }, 5000);
              },
            }
          );
        } else {
          console.log("WebSocket not connected or subscription already exists, retrying...");
          setTimeout(connect, 1000);
        }
      }

      connect();

      function updateLastActive() {
        lastActive = Date.now();
        cable.connect();
      }

      function checkInactivity() {
        if (Date.now() - lastActive >= INACTIVITY_TIMEOUT) {
          clearInterval(heartbeatInterval);
          clearInterval(inactivityCheckInterval);
          if (subscription) {
            subscription.unsubscribe();
            subscription = null;
          }
          cable.disconnect();
          // console.log(`User: ${user.id} Inactive`);
        } else {
          clearInterval(heartbeatInterval);
          heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
        }
      }

      function sendHeartbeat() {
        if (Date.now() - lastActive < INACTIVITY_TIMEOUT) {
          // console.log(`User: ${user.id} Heartbeat`);
          if (subscription) {
            subscription.send({ type: "heartbeat" });
          }
        }
      }

      // Add event listeners for inactivity detection
      const updateInactivityTimeout = () => {
        clearInterval(heartbeatInterval);
        clearInterval(inactivityCheckInterval);
        heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
        inactivityCheckInterval = setInterval(checkInactivity, INACTIVITY_TIMEOUT / 2);
        updateLastActive();
      };

      document.addEventListener('touchstart', updateInactivityTimeout);
      document.addEventListener('click', updateInactivityTimeout);

      cleanup = () => {
        // console.log(`User: ${user.id} Offline`);
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
        if (cable) {
          cable.disconnect();
        }

        // Remove event listeners
        document.removeEventListener('touchstart', updateInactivityTimeout);
        document.removeEventListener('click', updateInactivityTimeout);

        // Clear timeouts
        clearInterval(heartbeatInterval);
        clearInterval(inactivityCheckInterval);
      };
    }
    return () => {
      if (cleanup) {
        cleanup();
      }
    };}, [loggedIn, user, jwt]);

    return null;
  }

  export default useHeartbeat;
