import React, { useState } from 'react';

import '../../assets/SignupImageUpload.css'

function SignupImageUpload(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleImageChange = (event) => {
    setSelectedFile(event.target.files[0]);

    if (!event.target.files[0]) return;

    // Update the parent component with the selected file
    props.setImage(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  return (
    <div className="signup-file-upload-wrapper">
      <label htmlFor="file-upload" className="signup-file-upload-label">
      <div className="signup-file-upload-button-text">
        Upload Profile Image
      </div>
      </label>
      <input
        id="file-upload"
        type="file"
        onChange={handleImageChange}
        className="signup-file-upload-input"
      />
      {fileName && <p className="signup-image-upload-success">Selected file: {fileName}</p>}
    </div>
  );
}

export default SignupImageUpload;
