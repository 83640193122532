import React, { useState, useEffect, useRef } from 'react';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import axios from 'axios';
import { useSelector } from 'react-redux';

import ContentModal from "./ContentModal";

import '../assets/MatchList.css';

const MatchList = () => {
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.userData);

  const [matchedFriends, setMatchedFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [likedContent, setLikedContent] = useState(null);
  const [selectedFriendName, setSelectedFriendName] = useState(null);
  const [likedContentLoading, setLikedContentLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [thumbnailDataUrls, setThumbnailDataUrls] = useState({});
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  const initialFetchRef = useRef(false);

  const fetchThumbnailData = async (thumbPath) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/plex_media/thumbnail?thumb_path=${encodeURIComponent(thumbPath)}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          responseType: 'blob',
        }
      );

      const dataUrl = URL.createObjectURL(response.data);

      setThumbnailDataUrls((prevDataUrls) => {
        const newDataUrls = { ...prevDataUrls, [thumbPath]: dataUrl };
        return newDataUrls;
      });
    } catch (error) {
      console.error('Failed to fetch thumbnail:', error);
    }
  };

  useEffect(() => {
    const fetchMatches = async () => {
      if (user?.id && token && !initialFetchRef.current) {  // Add ref check
        initialFetchRef.current = true;  // Set ref to true
        let config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };

        setPageLoading(true);

        axios
          .get(`${process.env.REACT_APP_API_URL}/matches/${user.id}`, config)
          .then(res => {
            setMatchedFriends(res.data);
            setLoading(false);
            setPageLoading(false);
          });
      }
    };

    fetchMatches();
  }, [user, token]);

  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
    setSelectedFriendName(friend.firstname || 'No data found');
    setLikedContentLoading(true); // Set loading state to true before fetching content
    setShowModal(true);
  };

  useEffect(() => {
    const fetchLikedContent = async () => {
      if (selectedFriend && token && user?.id) {
        const dbItemsPromise = axios.get(
          `${process.env.REACT_APP_API_URL}/matches/${user.id}/${selectedFriend.friend.friend_id}`,
          {
            params: {
              location_preference: user.location_preference
            },
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );

        const plexItemsPromise = selectedFriend.friend.plex_rating_keys
          ? axios.post(
              `${process.env.REACT_APP_API_URL}/plex_media/plex_items`,
              { rating_keys: selectedFriend.friend.plex_rating_keys },
              {
                headers: {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              }
            )
          : Promise.resolve({ data: [] });

        Promise.all([dbItemsPromise, plexItemsPromise])
          .then(([dbItemsResponse, plexItemsResponse]) => {
            const updatedDbItems = dbItemsResponse.data.map(item => {
              const locationKey = 'gb';
              const locationStreamingInfo = item.streaming_info?.[locationKey] || {};

              // Process watch links
              const processedWatchLinks = Object.entries(locationStreamingInfo.watch_links || {}).map(([provider, details]) => ({
                provider,
                link: details.link,
                type: details.type
              }));

              return {
                ...item,
                flatrate_providers: locationStreamingInfo.flatrate_providers || [],
                buy_providers: locationStreamingInfo.buy_providers || [],
                rent_providers: locationStreamingInfo.rent_providers || [],
                processed_watch_links: processedWatchLinks
              };
            });

            const combinedItems = [...updatedDbItems, ...plexItemsResponse.data];
            setLikedContent(combinedItems);

            // Fetch thumbnail data for Plex items
            const plexItems = combinedItems.filter(item => item.plex_item_details);
            plexItems.forEach((item) => {
              if (item.plex_item_details?.thumb) {
                fetchThumbnailData(item.plex_item_details.thumb);
              }
            });

            setLikedContentLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching liked content:', error);
            setLikedContentLoading(false);
          });
      }
    };

    if (showModal) {
      fetchLikedContent();
    }
  }, [selectedFriend, token, user, showModal]);
  const closeModal = () => {
    setSelectedFriend(null);
    setShowModal(false);
  };

  return (
    <div className="match-list-container">
      <div className="matchListHeader">
        <h2>Friends who've matched with you</h2>
      </div>

      {pageLoading ? (
        <div className="loader-container">
          <ClipLoader
            size={45}
            color="#ff7c62"
            aria-label="Loading Spinner"
            data-testid="loader"
            css={override}
          />
        </div>
      ) : (
        <>
          {Object.keys(matchedFriends).length === 0 ? (
            <div className="card-matches-message">
              <h2>No matches with any of your friends. Get swiping!</h2>
            </div>
          ) : (
            <div className="scroll-container">
              <div className="card-container">
                {matchedFriends.map(friend => {
                  const friendImageUrl = friend.profile_image_url
                    ? friend.profile_image_url
                    : require('../assets/images/dummy-pic.jpg');

                  return (
                    <div
                      className="matchCard"
                      key={friend.friend_id}
                      onClick={() => handleFriendClick({ friend: friend })}
                    >
                      <div className="match-card-content">
                        <img
                          className="match-card-image"
                          src={friendImageUrl}
                          alt="profile-pic"
                        />
                        <div className="cardInfo">
                          <div className="card-name">
                            <div className="card-firstname text-ellipsis">
                              {friend.firstname || 'No data'}
                            </div>
                            <div className="card-lastname text-ellipsis">
                              {friend.lastname || 'No data'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}

      {showModal && (
        <ContentModal
          token={token}
          closeModal={closeModal}
          loading={likedContentLoading}
          selectedFriend={selectedFriend}
          likedContent={likedContent ? likedContent.map(content => ({
            ...content,
            thumbnail_data: thumbnailDataUrls[content.plex_item_details?.thumb] || content.plex_item_details?.thumbnail_data
          })) : []}
          isOpen={showModal}
          user={user}
          thumbnailDataUrls={thumbnailDataUrls}
        />
      )}
    </div>
  );
}

export default MatchList;
