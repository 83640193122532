import React, { useEffect, useState, CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUndo } from '@fortawesome/free-solid-svg-icons'

import { useSprings, useSpring } from "@react-spring/web";
import { useGesture } from "react-with-gesture";
import { createConsumer } from "@rails/actioncable";
import { useSelector, useDispatch } from 'react-redux';

import {
  setIsFetching,
  setIsFilterLoading,
  setData,
  swipeCard,
  undoLastSwipe,
  selectCurrentCard,
  selectCurrentIndex,
  selectCanUndo,
  resetDeck
} from '../../redux/slices/deckSlice';

import MatchNotifications from "./MatchNotifications";
import StripeSubscription from "../registrations/StripeSubscription";
import Card from "./Card";
import Modal from "./modal";
import DeckFiltering from "./DeckFiltering";
import LikeIndicator from './LikeIndicator';
import DislikeIndicator from './DislikeIndicator';
import useDeckFetch from '../../Hooks/useDeckFetch';
import useMoreCards from '../../Hooks/useMoreCards';
import useDiscoverCards from '../../Hooks/useDiscoverCards';
import useFetchingText from '../../Hooks/useFetchingText';
import useSwipeActions from '../../Hooks/useSwipeActions';
import useSwipeIndicators from '../../Hooks/useSwipeIndicators';

import "../../assets/Deck.css";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const limit = 15

const to = i => ({
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
  delay: 0,
});
const from = i => ({ rot: 0, scale: 1.5, y: -100 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;

    function Deck() {
      const dispatch = useDispatch();
      const currentCard = useSelector(selectCurrentCard);
      const currentIndex = useSelector(selectCurrentIndex);
      const canUndo = useSelector(selectCanUndo);
      // Redux States usage //

      const data = useSelector((state) => state.deck.data);
      const user = useSelector((state) => state.user.userData);
      const token = useSelector((state) => state.token.value);
      const isFetching = useSelector((state) => state.deck.isFetching);
      const isFilterLoading = useSelector((state) => state.deck.isFilterLoading);
      const subscriptionStatus = useSelector((state) => state.subscription.status);
      const noResults = useSelector((state) => state.deck.noResults);

      const isInitializing = useSelector((state) => state.deck.isFetching || state.deck.isFilterLoading);
      const hasCompletedInitialLoad = useSelector((state) =>
        !state.deck.isFetching &&
        !state.deck.isFilterLoading &&
        (state.deck.data.length > 0 || state.deck.noResults)
      );

      // Derived state to control button visibility
      const shouldShowButtons = currentIndex === -1 && hasCompletedInitialLoad && !isInitializing;

    // Component state //
    const [nope, setNope] = useState(() => new Set());
    const [activateModal, setActivateModal] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [detailRequest, setDetailRequest] = useState(false);
    const [offset, setOffset] = useState(1);
    // const [expandSearch, setExpandSearch] = useState(false);
    const [plexLibraries, setPlexLibraries] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState({});
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [selectedPlexLibraries, setSelectedPlexLibraries] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);

    const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);

    // Hooks
    const fetchingText = useFetchingText(isFetching);
    const { likeProps, dislikeProps, handleIndicators } = useSwipeIndicators();

    const {
      swipeCounter,
      setSwipeCounter,
      swipeDirection,
      setSwipeDirection,
      swipeContentId,
      setSwipeContentId,
      undoLastSwipe,
      swipedCards,
      setSwipedCards,
      isProcessing
    } = useSwipeActions(token, data, currentIndex, dispatch, setData);

    const {
      error,
      expandSearch,
      setExpandSearch,
      lastPreference,
      setLastPreference,
      initialLoading
    } = useDeckFetch(token, user, selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset);

    const { handleMoreCards } = useMoreCards(selectedGenres, selectedRatings, selectedPlexLibraries, selectedCollections, limit, offset, expandSearch, setOffset, setLastPreference);

    const { handleDiscoverCards } = useDiscoverCards(
      selectedGenres,
      selectedRatings,
      selectedPlexLibraries,
      selectedCollections,
      limit,
      offset,
      expandSearch,
      setOffset
    );

    const handleGenreChange = (newSelectedGenres) => {
      setSelectedGenres(newSelectedGenres);
    };

    const handleRatingChange = (newRatings) => {
      setSelectedRatings(newRatings);
    };

    const handlePlexLibraryChange = (newPlexLibraries) => {
      setSelectedPlexLibraries(newPlexLibraries);
    };

    const handleUndo = () => {
      if (canUndo) {
        dispatch(undoLastSwipe());
      }
    };

    const getSelectedGenreIds = (selectedGenres) => {
      return Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);
    };

    const handleCollectionChange = (newCollections) => {
      setSelectedCollections(newCollections);
    };

    const resetFilters = () => {
      setSelectedGenres({});
      setSelectedRatings([]);
      setSelectedPlexLibraries([]);
      setSelectedCollections([]);
    };

    const [props, set] = useSprings(data.length, i => ({
      ...to(i),
      from: from(i)
    }));

    // const [props, set] = useSprings(Math.max(0, data.length), i => {
    //   return {
    //     ...to(i),
    //     from: from(i)
    //   };
    // });

    const bind = useGesture(
      ({
        args: [index, id, ratingKey],
        down,
        delta: [xDelta],
        distance,
        direction: [xDir],
        velocity,
        event,
      }) => {
        handleIndicators(down, xDir);

        if (!down) {
          const screenWidth = window.innerWidth;
          const threshold = screenWidth / 3.975;

          if (Math.abs(xDelta) > threshold) {
            const source = ratingKey ? 'plex' : 'db';
            const cardId = id || ratingKey;
            const direction = xDelta > threshold ? 'like' : 'dislike';
            const swipeInstance = Date.now();

            setNope(prev => {
              const newNope = new Set(prev);
              newNope.add(index);
              return newNope;
            });

            dispatch(swipeCard({
              direction,
              cardId,
              source,
              swipeInstance
            }));

            // Set swipe states
            setSwipeDirection(direction);
            setSwipeContentId(cardId);
          }
        }

        set(i => {
          if (index !== i) return;
          const isNope = nope.has(index);
          const x = isNope ? (200 + window.innerWidth) * Math.sign(xDelta) : down ? xDelta : 0;
          const rot = xDelta / 100 + (isNope ? Math.sign(xDelta) * 10 * Math.abs(xDelta) / 100 : 0);
          const scale = down ? 1.1 : 1;

          return {
            x,
            rot,
            scale,
            delay: undefined,
            config: { friction: 25, tension: down ? 800 : isNope ? 200 : 500 }
          };
        });
      }
    );

    const clickHandler = (id) => {
      if (!id) return;
      setActivateModal(true);
      setDetailRequest(true);
      fetchDetail(id);
    };

    const fetchDetail = async (id) => {
      if (!id || !currentCard) return;

      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };

      try {
        const source = currentCard.ratingKey ? 'plex' : 'db';
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contents/${id}?source=${source}`,
          config
        );
        setDetailRequest(false);
        setShowDetail(response.data);
      } catch (error) {
        console.error('Error fetching detail:', error);
        setDetailRequest(false);
      }
    };

    const expandSearchResults = async () => {
      resetFilters();
      const selectedGenreIds = Object.keys(selectedGenres).filter(genreId => selectedGenres[genreId]);

      dispatch(setIsFetching(true));
      dispatch(setIsFilterLoading(true));
      setExpandSearch(true);

      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const url = `${process.env.REACT_APP_API_URL}/contents?limit=${limit}&offset=${offset}&filters=${selectedGenreIds.join(",")}&expand_search=true${lastPreference ? `&preference=${lastPreference}` : ""}${selectedRatings.length > 0 ? selectedRatings.map(rating => `&rating[]=${rating.source}&rating_value[]=${rating.ratingValue}`).join('') : ""}&selected_libraries=${selectedPlexLibraries.join(",")}&selected_collections=${selectedCollections.join(",")}`;

      try {
        const response = await axios.get(url, config);
        const newData = response.data.contents || [];
        dispatch(setData(newData));
        dispatch(resetDeck());
        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
      } catch (error) {
        dispatch(setIsFetching(false));
        dispatch(setIsFilterLoading(false));
      }
    };

    useEffect(() => {
      if (data.currentIndex !== undefined) {
        setNope(new Set());
      }
    }, [currentIndex]);

    useEffect(() => {
      const fetchPlexLibraries = async () => {
        // Only proceed if user has Plex access token
        if (!user?.plex_access_token) {
          setPlexLibraries([]); // Set empty array for non-Plex users
          return;
        }

        let config = {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };

        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/plex_media/plex-libraries`, config);
          setPlexLibraries(response.data);
        } catch (error) {
          console.error("Error fetching Plex libraries:", error);
          setPlexLibraries([]); // Ensure we have a fallback empty array on error
        }
      };

      if (token && user) {
        fetchPlexLibraries();
      }
    }, [token, user]);

    const override: CSSProperties = {
      borderColor: "#ff7c62",
      height: "45px",
      width: "40px"
    };

    const cards = props.map(({ x, y, rot, scale }, i) => {
      const item = data[i];
      if (!item) return null;

      const isVisible = i <= currentIndex;
      if (!isVisible) return null;

      return (
        <Card
          key={item?.swipeInstance || item?.id || item?.ratingKey}
          i={i}
          x={x}
          y={y}
          rot={rot}
          scale={scale}
          trans={trans}
          data={item}
          bind={bind}
        />
      );
    }).filter(Boolean);

    // Separate the action buttons into top controls and bottom controls
    const TopControls = () => (
      <>
        {!isFetching && data.length > 0 && currentCard && (
          <button
            onClick={() => clickHandler(currentCard.ratingKey || currentCard.id)}
            className="fetchDetail-button"
            data-testid="fetchdetailsbtn"
            aria-label="Fetch Details"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        )}

        {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) &&
          !isFetching && data.length > 0 && canUndo && currentIndex !== -1 && (
            <button
              onClick={undoLastSwipe}
              disabled={isProcessing}
              data-testid="undobtn"
              className="undo-button"
              aria-label="Undo"
            >
              <FontAwesomeIcon icon={faUndo} />
            </button>
        )}
      </>
    );

    return (
      <div id="deck" data-testid="deck">
        <LikeIndicator style={likeProps} />
        <DislikeIndicator style={dislikeProps} />

        {isFilterLoading ? (
          <div className="filter-loading">
          </div>
        ) : (
          <>
            {data.length > 0 && cards}
            <TopControls />
          </>
        )}

        <MatchNotifications cable={cable} />

        {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && (
          <DeckFiltering
            onGenreChange={handleGenreChange}
            onRatingChange={handleRatingChange}
            plexLibraries={plexLibraries}
            selectedPlexLibraries={selectedPlexLibraries}
            onSelectPlexLibrary={handlePlexLibraryChange}
            selectedCollections={selectedCollections}
            onSelectCollection={handleCollectionChange}
          />
        )}

        <div className="deck-container">
          {isInitializing ? (
            <div className="fetchingContainer">
              <ClipLoader
                loading={true}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
                cssOverride={override}
              />
              <p className="isFetchingText">{fetchingText}</p>
            </div>
          ) : (
            <div
              className="optionsContainer"
              style={{ display: shouldShowButtons ? 'block' : 'none' }}
            >
              <div className="button-container">
                {/* Explore more button */}
                <button
                  className="load-button"
                  onClick={() => {
                    handleDiscoverCards(() => {
                      nope.clear();
                      set(i => to(i));
                      setSwipeCounter(0);
                    });
                  }}
                >
                  Explore more titles
                </button>

                {/* Non-subscribed users see this */}
                {((subscriptionStatus === '') || (subscriptionStatus === 'canceled')) && (
                  <div className="subscription-pitch-container">
                    <StripeSubscription showSalesPitch={true} />
                  </div>
                )}

                {/* Expand search message when no more cards */}
                {noResults && !expandSearch && !isFetching && (
                  <div className="expand-search">
                    <p>You've swiped through all the content from your preferred providers before! Click the button below to discover more from other providers.</p>
                    <button
                      className="load-button"
                      onClick={() => {
                        expandSearchResults();
                        resetFilters();
                      }}
                    >
                      Discover More
                    </button>
                  </div>
                )}

                {/* Premium user buttons */}
                {((subscriptionStatus === 'active') || (subscriptionStatus === 'trialing')) && (
                  <>
                    <button
                      className="load-button"
                      onClick={() => {
                        handleMoreCards("more", "more", () => {
                          nope.clear();
                          set(i => to(i));
                          setSwipeCounter(0);
                        });
                      }}
                    >
                      <p>Similar to what you like</p>
                    </button>
                    <button
                      className="load-button"
                      onClick={() => {
                        handleMoreCards("less", "less", () => {
                          nope.clear();
                          set(i => to(i));
                          setSwipeCounter(0);
                        });
                      }}
                    >
                      <p>Different from what you like</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {activateModal ? (
          detailRequest === false ? (
            <Modal data={showDetail} closeModal={setActivateModal} />
          ) : (
            <div className="modalSpinner">
            <ClipLoader
              size={40}
              color="#ff7c62"
            />
            </div>
          )
        ) : null}
      </div>
    );
  };
export default Deck;
