import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faSearch, faBell, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as SwipeCardLogo } from '../assets/images/swipe-card-svg.svg';

import SwitchTheme from './SwitchTheme';


import '../assets/LoggedInNavbar.css';

const Navbar = ({ loggedIn, user, notifyCount, logOut, storedTheme, setStoredTheme, setTheme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const menuRef = useRef();

  // Function to check if the current route is active
  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // If menu is open and click is outside of the menu, then close it
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);


  return (
    <header className="navbar">
      <div data-testid="navbar-container" className="navbar-container" ref={menuRef}>
      <nav className="navbar-nav-icons">
          <Link to="/users" className={`nav-link-icons${isActive('/users') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faSearch} />
            <p className="nav-icon-header">Find Friends</p>
          </Link>
          <Link to="/matches" className={`nav-link-icons${isActive('/matches') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faUserFriends} />
            <p className="nav-icon-header">Matches</p>
          </Link>
          <Link to="/deck" className={`nav-link-icons${isActive('/deck') ? ' selected' : ''}`}>
            <SwipeCardLogo className={`swipe-icon-cards${isActive('/deck') ? ' selected' : ''}`} />
            <p className="nav-icon-header">Swipe</p>
          </Link>
          <Link to="/notifications" className={`nav-link-icons${isActive('/notifications') ? ' selected' : ''}`}>
            <FontAwesomeIcon icon={faBell} />
            {notifyCount > 0 && (
              <div className="notification-count">{notifyCount}</div>
            )}
            <p className="nav-icon-header">Notifications</p>
          </Link>
          <div
            className={`nav-link-icons${isActive('#') ? ' selected' : ''}`}
            onClick={toggleMenu}
          >
          <div className="menu-icon-container">
            {menuOpen ? (
              <FontAwesomeIcon
                icon={faTimes}
                className="menu-icon"
                style={{
                  position: "absolute",
                  bottom: "10rem",
                  right: "1rem",
                  color: "#ff7c62",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="menu-icon"
                data-testid="closebtn"
              />
            )}
            </div>
            <p className="nav-icon-header">Menu</p>
          </div>
        </nav>
        <nav data-testid='navbar-nav' className={`navbar-nav ${menuOpen ? 'show' : ''}`}>
        <div className="menu-toggle">
          <SwitchTheme setTheme={setTheme} storedTheme={storedTheme} setStoredTheme={setStoredTheme} />
        </div>
          <Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link>
          {!loggedIn && <Link to="/login" className="nav-link" onClick={() => setMenuOpen(false)}>Login</Link>}
          {!loggedIn && <Link to="/signup" className="nav-link" onClick={() => setMenuOpen(false)}>Signup</Link>}
          {loggedIn && user && <Link to="/update" className="nav-link" onClick={() => setMenuOpen(false)}>My Account</Link>}
          {loggedIn && user && <Link to="/saved_content" className="nav-link" onClick={() => setMenuOpen(false)}>Saved Content</Link>}
          {loggedIn && user && <Link to="/friends" className="nav-link" onClick={() => setMenuOpen(false)}>Friends</Link>}
          {loggedIn && user && <button className="nav-link logout-btn" onClick={logOut}>Log Out</button>}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
