import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { ClipLoader } from "react-spinners";
import '../../assets/StripeSubscription.css';
import StripeMinimal from '../StripeMinimal';

function StripeSubscription({ token, subscriptionStatus, subscriptionEndDate, cancelAtPeriodEnd, minimalInformation}) {
  const stripe = useStripe();
  const elements = useElements();

  const [showCardSection, setShowCardSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Subscribing...");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState(subscriptionStatus);
  const [currentSubscriptionEndDate, setCurrentSubscriptionEndDate] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  const [currency, setCurrency] = useState(null)

  useEffect(() => {
    if ((subscriptionStatus !== undefined) && (subscriptionEndDate !== undefined)) {
      setCurrentSubscriptionStatus(subscriptionStatus);
      setLoaded(true);
    }
  }, [subscriptionStatus, subscriptionEndDate]);

  useEffect(() => {
    if (token) {
      const fetchSubscriptionPrice = async () => {
        try {
          let config = {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };

          const response = await axios.get(`${process.env.REACT_APP_API_URL}/subscriptions/price`, config);
          setSubscriptionPrice(response.data.price);
          setCurrency(response.data.currency);
        } catch (error) {
          console.error('Error fetching subscription price:', error.response ? error.response.data : error);
        }
      };

      fetchSubscriptionPrice();
    }
  }, [token, subscriptionStatus, subscriptionEndDate]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoadingMessage(`Processing...`);
    setErrorMessage(''); // Clear any previous error messages

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('Error:', error);
      setLoading(false);
      setErrorMessage(error.message); // <-- Set error message
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    } else {
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const subscription = await axios.post(`${process.env.REACT_APP_API_URL}/create_subscription`, { payment_method_id: paymentMethod.id }, config);
        setLoading(false);
        setSuccessMessage("Thanks for subscribing, you're now a premium Flickswipe member!");
        setCurrentSubscriptionStatus('active'); // Set subscription status to active
        setCurrentSubscriptionEndDate(subscription.data.subscription.endDate);
        setShowCardSection(false); // Hide the card section

        // Hide success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 4000);
        window.location.reload();
      } catch (error) {
        console.error('Error creating subscription:', error.response ? error.response.data : error);
        setLoading(false);
        setErrorMessage('Error creating subscription.'); // <-- Set error message
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    }
  };

  const handleRenew = async () => {
    setLoading(true);
    setLoadingMessage("Renewing subscription...");

    let config = {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/renew_subscription`, {}, config);
        setLoading(false);
        setCurrentSubscriptionEndDate(response.data.subscription.endDate);
        setSuccessMessage("You have successfully renewed your subscription.");
        setCurrentSubscriptionStatus('active'); // Set subscription status to active

        // Hide success message after 5 seconds
        setTimeout(() => {
            setSuccessMessage('');
        }, 4000);
        window.location.reload();
    } catch (error) {
        console.error('Error renewing subscription:', error.response ? error.response.data : error);
        setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    setLoading(true);
    setLoadingMessage("Unsubscribing...");

    let config = {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/subscriptions`, config);
        setLoading(false);
        setSuccessMessage("You have successfully cancelled your subscription.");
        setCurrentSubscriptionStatus(response.data.subscription.status);
        setCurrentSubscriptionEndDate(response.data.subscription.endDate);

        // Hide success message after 5 seconds
        setTimeout(() => {
            setSuccessMessage('');
        }, 4000);
        window.location.reload();
    } catch (error) {
        console.error('Error canceling subscription:', error.response ? error.response.data : error);
        setLoading(false);
    }
  };

  const handleCancelTrial = async () => {
    setLoading(true);
    setLoadingMessage("Canceling trial...");

    let config = {
        headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/cancel_trial`, {}, config);
        setLoading(false);
        setSuccessMessage("You have successfully cancelled your trial.");
        setCurrentSubscriptionStatus(response.data.subscription.status);

        // Hide success message after 5 seconds
        setTimeout(() => {
            setSuccessMessage('');
        }, 4000);
        window.location.reload();
    } catch (error) {
        console.error('Error canceling trial:', error.response ? error.response.data : error);
        setLoading(false);
    }
  };


  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  const cardOptions = {
    style: {
      base: {
        iconColor: '#ff7c62',
        color: '#ff7c62',
        fontWeight: '900',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#ff7c62',
        },
        '::placeholder': {
          color: '#ff7c62',
        },
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    },
  };

  const renderSubscriptionDetails = () => {
    const formatDate = (date) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    }
    if (currentSubscriptionStatus === 'trialing' && subscriptionEndDate) {
      return (
        <div className="subscription-details">
          <p>You are currently in your trial period, which ends on <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>After that, you'll be billed monthly at a rate of {`${currency === 'usd' ? '$' : '£'}${(subscriptionPrice / 100).toFixed(2)}`}.</p>
        </div>
      );
    } else if (currentSubscriptionStatus === 'active' && subscriptionEndDate && !cancelAtPeriodEnd) {
      return (
        <div className="subscription-details">
          <p>Your subscription is currently active. Upon cancelling, your premium access will remain effective until <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>For more information regarding the cancellation process, please reach out to us at contact@flickswipe.org.</p>
        </div>
      );
    } else if (currentSubscriptionStatus === 'canceled' && subscriptionEndDate) {
      return (
        <div className="subscription-details">
          <p>Your subscription has been canceled and won't auto-renew. Your premium access will expire on <strong>{formatDate(subscriptionEndDate)}</strong>.</p>
          <p>For further details about our cancellation policy, feel free to contact us at contact@flickswipe.org.</p>
        </div>
      );
    } else if (currentSubscriptionStatus === 'active' && subscriptionEndDate && cancelAtPeriodEnd) {
      return (
        <div className="subscription-details">
          <p>Your subscription has been canceled but you can still enjoy premium access until <strong>{formatDate(subscriptionEndDate)}</strong>. You can reactivate your subscription at any time if you wish to resume monthly payments before your premium access expires.</p>
          <p>For additional support or inquiries on the reactivation process, don't hesitate to contact us at contact@flickswipe.org.</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="stripe-subscription">
        {minimalInformation ? (
          <StripeMinimal
              handleSubmit={handleSubmit}
              stripe={stripe}
              loading={loading}
              cardOptions={cardOptions}
              subscriptionPrice={subscriptionPrice}
              currency={currency}
              loadingMessage={loadingMessage}
              successMessage={successMessage}
              errorMessage={errorMessage}
              cssOverride={override}
              token={token}
          />
        ) : (
            <>
                {loaded && !showCardSection && !successMessage && (
                    <>
                        <div className='subInfo'>
                          <h2 data-testid="subscription-details-header">Subscription Details</h2>
                            <p>
                                This is a monthly subscription and can be cancelled at any time. Your payments are securely processed through Stripe.
                            </p>
                            <p>Monthly Subscription price: {subscriptionPrice ? `${currency === 'usd' ? '$' : '£'}${(subscriptionPrice / 100).toFixed(2)}` : 'Loading...'}</p>
                        </div>

                        {/* # Unsubscribe */}
                        {(currentSubscriptionStatus === 'active' && !cancelAtPeriodEnd) && (
                            <>
                                <button onClick={handleUnsubscribe}>Unsubscribe</button>
                                {renderSubscriptionDetails()}
                            </>
                        )}
                        {/* # Reactivate Subscription */}
                        {(currentSubscriptionStatus === 'active' && cancelAtPeriodEnd) && (
                            <>
                                <button onClick={handleRenew}>Reactivate Subscription</button>
                                {renderSubscriptionDetails()}
                            </>
                        )}
                        {/* # Renew Subscription */}
                        {currentSubscriptionStatus === 'canceled'  && subscriptionEndDate !== null && (
                            <>
                                <button onClick={() => setShowCardSection(true)}>Renew Subscription</button>
                                {renderSubscriptionDetails()}
                            </>
                        )}
                        {/* # Cancel Trial Subscription */}
                        {currentSubscriptionStatus === 'trialing' && (
                            <>
                                <button onClick={handleCancelTrial}>Cancel Trial</button>
                                {renderSubscriptionDetails()}
                            </>
                        )}
                        {/* # If user is new then start 7 day free trial */}
                        {currentSubscriptionStatus !== 'active' && currentSubscriptionStatus !== 'canceled' && currentSubscriptionStatus !== 'trialing' && subscriptionEndDate === null && (
                            <>
                                <button data-testid="start-trial" onClick={() => setShowCardSection(true)}>Start 7 Day Free Trial</button>
                                <p>After 7 days, you'll be billed monthly at a rate of {subscriptionPrice ? `${currency === 'usd' ? '$' : '£'}${(subscriptionPrice / 100).toFixed(2)}` : 'Loading...'}.</p>
                            </>
                        )}
                        {/* # User used to be subscribed but cancelled now the option to Subscribe Again */}
                        {currentSubscriptionStatus === 'canceled' && new Date(subscriptionEndDate) < new Date() && (
                            <>
                                <button onClick={() => setShowCardSection(true)}>Subscribe Again</button>
                                {renderSubscriptionDetails()}
                            </>
                        )}
                    </>
                )}

                {showCardSection && (
                    <form onSubmit={handleSubmit}>
                        <CardElement options={cardOptions} />
                        <button type="submit" disabled={!stripe || loading}>Submit</button>
                    </form>
                )}

                {loading && (
                    <div className="process-loader">
                        <ClipLoader
                            size={35}
                            cssOverride={override}
                        />
                        <p>{loadingMessage}</p>
                    </div>
                )}

                {successMessage && (
                    <div className="success-message">
                        <p>{successMessage}</p>
                    </div>
                )}

                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
            </>
        )}
    </div>
  );
}


const stripePromise = loadStripe('pk_live_51NKiqsG5Mz4L3PxkxbmzxsqQX95eRZ5RGvbHCKXrxD0AaZplq2AqeOakbwBdBSZ7zy2JXjfc1QobG3LnhshEVSF900YOhmMyEq');

function WrappedStripeSubscription({ token, subscriptionStatus, subscriptionEndDate, cancelAtPeriodEnd, minimalInformation }) {
  return (
    <Elements stripe={stripePromise} options={{ locale: 'auto' }}>
      <StripeSubscription
        token={token}
        subscriptionStatus={subscriptionStatus}
        subscriptionEndDate={subscriptionEndDate}
        cancelAtPeriodEnd={cancelAtPeriodEnd}
        minimalInformation={minimalInformation}
      />
    </Elements>
  );
}

export default WrappedStripeSubscription;
