import './App.css';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { createConsumer } from '@rails/actioncable';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useLocalStorage from 'use-local-storage'
import Cookies from 'js-cookie';

import Deck from "./components/Deck";
import SignUp from './components/registrations/Signup';
import Login from './components/registrations/Login';
import Update from './components/registrations/Update';
import UsersList from './components/UsersList';
import Notifications from './components/Notifications';
import MatchList from './components/MatchList';
import ContentDetail from './components/ContentDetail';
import LoggedInNavbar from './components/LoggedInNavbar';
import LoggedOutNavbar from './components/LoggedOutNavbar';
import useHeartbeat from './useHeartbeat';
import Home from './components/Home';
import ConfirmAccount from './components/ConfirmAccount';
import ResetPassword from './components/registrations/ResetPassword'
import ForgotPassword from './components/registrations/ForgotPassword';
import RevertChanges from './components/registrations/RevertChanges';
import ResetPasswordAndEmail from './components/registrations/ResetPasswordAndEmail';
import PrivacyPolicy from './components/terms&policies/PrivacyPolicy';
import TermsOfUse from './components/terms&policies/TermsOfUse';
import SavedContent from './components/SavedContent';
import LoggedInHomepage from './components/LoggedInHomepage';
import NotificationsHandler from './components/NotificationsHandler';
import Friends from './components/Friends';
import StripeStatusHandler from './components/StripeStatusHandler';
import StripeSubscription from './components/registrations/StripeSubscription';

import axios from 'axios';

import { setCurrentUser, getJWT, logOut } from './utils/appHelpers';

export default function App() {
  const [storedTheme, setStoredTheme] = useLocalStorage('theme', null);
  const [theme, setTheme] = useState(storedTheme || 'light');

  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [cable, setCable] = useState(null);
  const [shouldRefreshRequests, setShouldRefreshRequests] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(false);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);

  useEffect(() => {
    const toggleTheme = (themePreference) => {
      if (storedTheme === null) {
        setTheme(themePreference.matches ? 'dark' : 'light');
      }
    }

    const darkThemePreference = window.matchMedia('(prefers-color-scheme: dark)');
    if (storedTheme === null) {
      toggleTheme(darkThemePreference);
    }

    darkThemePreference.addListener(toggleTheme);
    return () => {
      darkThemePreference.removeListener(toggleTheme);
    }
  }, [storedTheme]);

  useEffect(() => {
    document.body.dataset.theme = theme;
    setStoredTheme(theme);
  }, [theme]);

  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

  async function handleSilentLogin() {
    const refreshToken = sessionStorage.getItem("refresh_token");

    if (refreshToken) {
      const fingerprint = navigator.userAgent;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/silent_login`,
        { refresh_token: refreshToken },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-Fingerprint": fingerprint
          },
        }
      );

      if (response.data.jwt && response.data.user) {
        setCurrentUser(response.data.user, response.data.jwt, response.data.exp, refreshToken, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd, setToken, setLoggedIn, setUser);
        Cookies.set('fingerprint', fingerprint, { expires: 1, secure: true });
      }
    }
  }

  const silentLoginCalled = useRef(false);

  useEffect(() => {
    if (!silentLoginCalled.current && sessionStorage.getItem("refresh_token")) {
      handleSilentLogin();
      silentLoginCalled.current = true;
    }
  }, []);

  useEffect(() => {
    if (token && user && loggedIn) {
      const fetchSentInvites = async () => {
        let config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };
        axios
          .get(`${process.env.REACT_APP_API_URL}/sentinvites/${user.id}`, config)
          .then((response) => {
            setNotifyCount(response.data.pending_users.length);
          });
      };
      fetchSentInvites();
    }
  }, [token]);

  const handleSetCurrentUser = (currentUser, token, exp, refresh_token) => {
    setCurrentUser(currentUser, token, exp, refresh_token, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd, setToken, setLoggedIn, setUser);
  };

  const handleGetJwt = async (token) => {
    await getJWT(token, setToken, setUser, setLoggedIn, cable);
  };

  const handleLogout = () => {
    logOut(setUser, setToken, setLoggedIn, cable);
  };

  useHeartbeat(user, loggedIn, token, getJWT(token, setToken, logOut, setUser, setLoggedIn, cable));

  return (
    <>
      <BrowserRouter>
        <NotificationsHandler setShouldRefreshRequests={setShouldRefreshRequests} user={user} loggedIn={loggedIn} token={token} setNotifyCount={setNotifyCount} />
        <StripeStatusHandler user={user} loggedIn={loggedIn} token={token} setCancelAtPeriodEnd={setCancelAtPeriodEnd} setSubscriptionStatus={setSubscriptionStatus} setSubscriptionEndDate={setSubscriptionEndDate} />
        {loggedIn && (
          <LoggedInNavbar setTheme={setTheme} storedTheme={storedTheme} setStoredTheme={setStoredTheme} loggedIn={loggedIn} user={user} notifyCount={notifyCount} logOut={handleLogout} />
        )}
        {!loggedIn && (
          <LoggedOutNavbar loggedIn={loggedIn} user={user} notifyCount={notifyCount} logOut={handleLogout} />
        )}
        <Routes>
          <Route exact path="/" element={loggedIn ? <LoggedInHomepage /> : <Home loggedIn={loggedIn} user={user} />} />
          <Route exact path="/premium" element={<StripeSubscription minimalInformation token={token} subscriptionEndDate={subscriptionEndDate} cancelAtPeriodEnd={cancelAtPeriodEnd} subscriptionStatus={subscriptionStatus} />} />
          <Route exact path="/confirm/:token" element={<ConfirmAccount />} />
          <Route path="/confirm/"  element={<ConfirmAccount />} />
          <Route exact path="/login" element={!loggedIn && user ? <Login setCurrentUser={handleSetCurrentUser} /> : <Navigate to="/" />} />
          # CHECK SIGNUP FOR CORRECT JWT. REPLACE WITH TOKEN or Method getJWT
          <Route exact path="/signup" element={!loggedIn ? <SignUp getJWT={handleGetJwt} /> : <Navigate to="/home" />} />
          <Route exact path="/password_resets/:token" element={<ResetPassword />} />
          <Route exact path="/password_resets/:token/revert_email" element={<ResetPasswordAndEmail />} />
          <Route exact path="/users/revert_changes" element={<RevertChanges />} />
          <Route exact path="/forgot_password" element={<ForgotPassword />} />
          <Route exact path="/update" element={<Update subscriptionEndDate={subscriptionEndDate} cancelAtPeriodEnd={cancelAtPeriodEnd} subscriptionStatus={subscriptionStatus} loggedIn={loggedIn} logOut={handleLogout} user={user} token={token} />} />
          <Route exact path="/deck" element={<Deck subscriptionEndDate={subscriptionEndDate} cancelAtPeriodEnd={cancelAtPeriodEnd} subscriptionStatus={subscriptionStatus} user={user} loggedIn={loggedIn} token={token} />} />
          <Route exact path="/users" element={<UsersList loggedIn={loggedIn} user={user} token={token} />} />
          <Route exact path="/contents/:contentId" element={<ContentDetail user={user} token={token} />} />
          <Route exact path="/matches" element={<MatchList user={user} loggedIn={loggedIn} token={token} />} />
          <Route exact path="/saved_content" element={<SavedContent user={user} loggedIn={loggedIn} token={token} />} />
          <Route exact path="/notifications" element={<Notifications shouldRefreshRequests={shouldRefreshRequests} setShouldRefreshRequests={setShouldRefreshRequests} notifyCount={notifyCount} setNotifyCount={setNotifyCount} user={user} token={token} loggedIn={loggedIn} />} />
          <Route exact path="/friends" element={<Friends user={user} token={token} loggedIn={loggedIn} />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<TermsOfUse />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
