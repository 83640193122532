import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import '../../assets/ResetLikeDislike.css';

const ResetLikeDislike = ({ user }) => {
  const token = useSelector((state) => state.token.value);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage('');
        setError('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, error]);

  const handleReset = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/${user.id}/reset_liked_disliked`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setMessage(response.data.message);
      setIsConfirmOpen(false);
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to reset preferences');
    }
  };

  return (
    <div className="section-content">
      <div className="warning-box">
        <h3>Reset All Likes & Dislikes</h3>
        <p className="warning-text">
          This action will permanently delete all your saved Liked and Disliked content, including:
        </p>
        <ul className="warning-list">
          <li>All liked movies and TV shows</li>
          <li>All disliked movies and TV shows</li>
          {user.plex_id && <li>All Plex liked and disliked content</li>}
          <li>All your matches with other users</li>
        </ul>
        <p className="warning-text">
          This action will remove your matches with other users, but their matches with you will remain intact. This action cannot be undone!
        </p>
      </div>

      {message && (
        <div className="success-message">
          <p>{message}</p>
        </div>
      )}
      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}

      {!isConfirmOpen ? (
        <button
          className="btn-warning"
          onClick={() => setIsConfirmOpen(true)}
        >
          Reset All Likes, Dislikes & Matches
        </button>
      ) : (
        <div className="confirm-buttons">
          <button
            className="btn-cancel"
            onClick={() => setIsConfirmOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn-confirm-warning"
            onClick={handleReset}
          >
            Confirm Full Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetLikeDislike;
