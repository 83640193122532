import React, { useEffect, useState } from 'react';
import { createConsumer } from '@rails/actioncable';
import axios from 'axios';
import NotificationAlert from './NotificationAlert';

const NotificationsHandler = ({ user, loggedIn, token, setNotifyCount, setShouldRefreshRequests }) => {
  const [currentCount, setCurrentCount] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isNotificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    let subscription;

    const fetchNotificationsCount = () => {
      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      axios.get(`${process.env.REACT_APP_API_URL}/sentinvites/${user.id}`, config)
        .then((response) => {
          const count = response.data.pending_users.length;
          setNotifyCount(count);
          setCurrentCount(count);
        });
    };

    if (loggedIn && user && token) {
      fetchNotificationsCount();

      const authToken = token;
      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${authToken}`);

      subscription = cable.subscriptions.create(
        { channel: 'InviteChannel', auth_token: authToken, user_id: user.id },
        {
          connected: () => {
            // console.log(`User: ${user.id} successfully connected to InviteChannel`);
          },
          disconnected: () => {
            // console.log(`User: ${user.id} disconnected from InviteChannel`);
          },
          received: (data) => {
            // console.log(`User: ${user.id} received data in InviteChannel`);

            if (data.notification.includes("invite from user")) {
              if (data.user && data.user.online) {
                const newCount = currentCount + 1;
                setNotifyCount(newCount);
                setCurrentCount(newCount);
                setShouldRefreshRequests(true);
              }
            } else if (data.notification.includes("accepted your friend request")) {
              if (data.accepter) {
                setNotificationMessage(data.notification);
                setNotificationVisible(true);
              }
            }
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [loggedIn, user, token, setNotifyCount, currentCount]);

  const onNotificationClose = () => {
    setNotificationVisible(false);
  };

  return (
    <NotificationAlert
      message={notificationMessage}
      visible={isNotificationVisible}
      onClose={onNotificationClose}
    />
  );
};

export default NotificationsHandler;
