import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';

import '../assets/SavedContentDetail.css';
import imdbLogo from '../assets/images/imdb_logo.png'
import metaCriticLogo from '../assets/images/metacritic-logo-original.png'
import rottenLogo from '../assets/images/Rotten_tomato_logo.png'
import justwatch_logo from '../assets/images/JustWatch-logo.webp'

const SavedContentDetail = ({ location_preference, token, attributes, closeDetail }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (attributes) {
      setLoading(false);
    }
  }, [attributes]);

  const prime = "https://image.tmdb.org/t/p/w342/emthp39XA2YScoYL1p0sdbAH2WA.jpg"
  const apple = "https://image.tmdb.org/t/p/w342/6uhKBfmtzFqOcLousHwZuzcrScK.jpg"
  const disney = "https://image.tmdb.org/t/p/w342/7rwgEs15tFwyR9NPQ5vpzxTj19Q.jpg"
  const iplayer = "https://image.tmdb.org/t/p/w342/zY5SmHyAy1CoA3AfQpf58QnShnw.jpg"
  const netflix = "https://image.tmdb.org/t/p/w342/t2yyOv40HZeVlLjYsCsPHnWLk4W.jpg"
  const now = "https://image.tmdb.org/t/p/w342/y7mZSw1FV99yfawxOISBQTvtJxM.jpg"
  const mubi = "https://image.tmdb.org/t/p/w342/bVR4Z1LCHY7gidXAJF5pMa4QrDS.jpg"
  const hotstar = "https://image.tmdb.org/t/p/w342/7Fl8ylPDclt3ZYgNbW2t7rbZE9I.jpg"
  const all4 = "https://image.tmdb.org/t/p/w342/kJ9GcmYk5zJ9nJtVX8XjDo9geIM.jpg"
  const britbox = "https://image.tmdb.org/t/p/w342/dtU2zKZvtdKgSKjyKekp8t0Ryd1.jpg"
  const sky_go = "https://image.tmdb.org/t/p/w342/fBHHXKC34ffxAsQvDe0ZJbvmTEQ.jpg"
  const hulu = "https://image.tmdb.org/t/p/w342/zxrVdFjIjLqkfnwyghnfywTn3Lh.jpg";
  const itvx = "https://image.tmdb.org/t/p/w342/clC87JljoZKeAHIwdlxd1AUn6cn.jpg";
  const hbo_max = "https://image.tmdb.org/t/p/w342/Ajqyt5aNxNGjmF9uOfxArGrdf3X.jpg";
  const paramount = "https://image.tmdb.org/t/p/w342/xbhHHa1YgtpwhC8lb1NQ3ACVcLd.jpg";
  const peacock = "https://image.tmdb.org/t/p/w342/xTHltMrZPAJFLQ6qyCBjAnXSmZt.jpg";
  const showtime = "https://image.tmdb.org/t/p/w342/4kL33LoKd99YFIaSOoOPMQOSw1A.jpg";
  const starz = "https://image.tmdb.org/t/p/w342/eWp5LdR4p4uKL0wACBBXapDV2lB.jpg";

  const providerLogos = {
    apple,
    disney,
    iplayer,
    netflix,
    now,
    prime,
    mubi,
    hotstar,
    all4,
    britbox,
    paramount_plus: paramount,
    sky_go,
    hulu,
    hbo_max,
    hbo: hbo_max,
    itvx,
    paramount,
    peacock,
    showtime,
    starz
  };

  let locationBuyProviders = `${location_preference}_buy_providers`;
  let locationRentProviders = `${location_preference}_rent_providers`;
  let locationFlatrateProviders = `${location_preference}_flatrate_providers`;
  let locationWatchLinks = `${location_preference}_watch_links`;

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : attributes ? (
        <div className="SavedContentDetail-container">
          <div className="SavedContentDetail-card">
            <div className="SavedClose-container">
              <FontAwesomeIcon
                onClick={closeDetail}
                icon={faSquareXmark}
                size="3x"
              />
            </div>
            <div className="SavedSocial-media-container">
              {attributes.facebook_id && attributes.facebook_id !== 'https://www.facebook.com/' && (
                <FontAwesomeIcon
                  icon={faFacebookF}
                  onClick={() => window.open(attributes.facebook_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="facebookicon"
                />
              )}
              {attributes.instagram_id && attributes.instagram_id !== 'https://www.instagram.com/' && (
                <FontAwesomeIcon
                  icon={faInstagram}
                  onClick={() => window.open(attributes.instagram_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="instagramicon"
                />
              )}
              {attributes.twitter_id && attributes.twitter_id !== 'https://twitter.com/' && (
                <FontAwesomeIcon
                  icon={faTwitter}
                  onClick={() => window.open(attributes.twitter_id, '_blank')}
                  className="SavedSocial-icon"
                  data-testid="twittericon"
                />
              )}
            </div>
            <h2>{attributes.name || attributes.title}</h2>
            <div className="SavedMedia-runtime-container">
              <h5>{attributes.media_type === 'tv' ? 'TV Show' : 'Movie'}</h5>
              {attributes.runtime && (
                <p>{attributes.runtime} mins</p>
              )}
            </div>
            <img className="SavedContentDetailPoster" src={attributes.poster_path} alt={attributes.name || attributes.title} />
            <p>{attributes.overview}</p>
            {attributes.ratings && attributes.ratings.length > 0 && (
              <div className="SavedProviders-section">
                <ul>
                  {attributes.ratings.map((rating, index) => (
                    <li key={index}>
                      {rating.Source === 'Internet Movie Database' ? (
                        <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Metacritic' ? (
                        <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Rotten Tomatoes' ? (
                        <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : (
                        rating.Source
                      )}{rating.Value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {attributes.director && attributes.director !== 'N/A' && (
              <p>
                <strong>Director:</strong> {attributes.director}
              </p>
            )}
            {attributes.awards && attributes.awards !== 'N/A' && attributes.awards !== 0 && (
              <p>
                <strong>Awards:</strong> {attributes.awards}
              </p>
            )}
            {typeof attributes.revenue === 'number' && attributes.revenue !== 0 && (
              <p>
                <strong>Total Revenue:</strong> ${attributes.revenue}
              </p>
            )}
            {
              attributes[locationWatchLinks] &&
              Object.values(attributes[locationWatchLinks]).some((links) => links.length > 0) && (
                <h3>Watch Now Direct Link</h3>
              )
            }

            {attributes[locationWatchLinks] && Object.keys(attributes[locationWatchLinks]).map((provider, index) => {
              const seenTypes = new Set();
                const uniqueLinks = attributes[locationWatchLinks][provider].filter((link) => {
                  if (seenTypes.has(link.type)) {
                    return false;
                  } else {
                    seenTypes.add(link.type);
                    return true;
                  }
                });

                return (
                  uniqueLinks.length > 0 && (
                    <div className="SavedProviders-section" key={index}>
                      {uniqueLinks.map((link, idx) => (
                        ((link.watchLink && link.watchLink.trim() !== '') || (link.link && link.link.trim() !== '')) ? (
                          <div key={idx} className="SavedProvider-item">
                            <a href={link.watchLink || link.link} target="_blank" rel="noopener noreferrer">
                              <img
                                src={providerLogos[provider]}
                                alt={`${provider} Logo`}
                                className="SavedProvider-logo"
                              />
                            </a>
                            <div className="SavedType-info">
                              <span className="SavedType-label">{link.type.charAt(0).toUpperCase() + link.type.slice(1)}</span>
                              {link.price && link.price.formatted && (
                                <span className="SavedType-amount">({link.price.formatted})</span>
                              )}
                            </div>
                          </div>
                        ) : null
                      ))}
                    </div>
                  )
                );
            })}


            {(attributes[locationBuyProviders] && attributes[locationBuyProviders].length > 0) ||
              (attributes[locationRentProviders] && attributes[locationRentProviders].length > 0) ||
              (attributes[locationFlatrateProviders] && attributes[locationFlatrateProviders].length > 0)
              ? (<h3>More Provider Info</h3>)
              : null
            }

            {attributes[locationBuyProviders] && attributes[locationBuyProviders].length > 0 && (
              <div className="SavedProviders-section">
                <h4>Where to Buy</h4>
                <div className="SavedProvider-container">
                  {attributes[locationBuyProviders].map((buy, index) => (
                    <img key={index} alt={`${buy.provider_name} Logo`} src={buy.logo_path} className="SavedProvider-logo" />
                  ))}
                </div>
              </div>
            )}

            {attributes[locationRentProviders] && attributes[locationRentProviders].length > 0 && (
              <div className="SavedProviders-section">
              <h4>Where to Rent</h4>
                <div className="SavedProvider-container">
                  {attributes[locationRentProviders].map((rent, index) => (
                    <img key={index} alt={`${rent.provider_name} Logo`} src={rent.logo_path} className="SavedProvider-logo" />
                  ))}
                </div>
              </div>
            )}

            {attributes[locationFlatrateProviders] && attributes[locationFlatrateProviders].length > 0 && (
              <div className="SavedProviders-section">
                <h4>Free with Subscription</h4>
                <div className="SavedProvider-container">
                  {attributes[locationFlatrateProviders].map((free, index) => (
                    <img key={index} alt={`${free.provider_name} Logo`} src={free.logo_path} className="SavedProvider-logo" />
                  ))}
                </div>
              </div>
            )}

            <div className="savedContentDetail-credits-container">
              <p>Provider Info sourced from JustWatch</p>
              <img className="justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
            </div>

            </div>
          </div>
        ) : (
          <div>Content not found</div>
      )}
    </>
  );
};

export default SavedContentDetail;
