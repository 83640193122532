import React, { useEffect, useState } from "react";
import { useSpring, useSprings, animated } from "@react-spring/web";
import { useGesture } from "react-with-gesture";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

import LikeIndicator from './Deck/LikeIndicator';
import DislikeIndicator from './Deck/DislikeIndicator';

import HomeCard from "./HomeCard";
import axios from "axios";

import '../assets/HomeDeck.css'
import { ClipLoader } from "react-spinners";

const to = i => ({
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
  delay: 0,
});
const from = i => ({ rot: 0, scale: 1.5, y: -100 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;

function HomeDeck() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activateModal, setActivateModal] = useState(false);
  const [detail, setShowDetail] = useState(false);
  const [detailRequest, setDetailRequest] = useState(false);
  const [likeProps, setLike] = useSpring(() => ({ opacity: 0, scale: 1 }));
  const [dislikeProps, setDislike] = useSpring(() => ({ opacity: 0, scale: 1 }));


  const handleIndicators = (down, xDir) => {
    const likeOpacity = xDir > 0 && down ? 25 : 0;
    const dislikeOpacity = xDir < 0 && down ? 25 : 0;
    const scale = down ? 1.2 : 1;

    setLike({ opacity: likeOpacity, scale: scale });
    setDislike({ opacity: dislikeOpacity, scale: scale });
  };

  const [props, set] = useSprings(data.length, i => ({
    ...to(i),
    from: from(i)
  }));

  const fetchItems = async () => {
    const apiKey = process.env.REACT_APP_TMDB_API_KEY;
    const popular_tv_api = `https://api.themoviedb.org/3/trending/tv/day?api_key=${apiKey}&language=en-US`;
    const popular_movie_api = `https://api.themoviedb.org/3/trending/movie/day?api_key=${apiKey}&language=en-US`;
    const apiUrl = Math.random() < 0.5 ? popular_movie_api : popular_tv_api; // Randomly select the API URL

    const response = await axios.get(apiUrl);
    const results = response.data.results;
    const randomSlice = getRandomSlice(results, 8); // Get a random slice of 5 items
    setData(randomSlice);
    setLoading(false); // Set loading to false once the data has been fetched
  };

  const getRandomSlice = (array, size) => {
    const shuffled = array.sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, size); // Extract the first 'size' items from the shuffled array
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const [nope] = useState(() => new Set());

  const bind = useGesture(
    ({
      args: [index],
      down,
      delta: [xDelta],
      distance,
      direction: [xDir],
      velocity
    }) => {
      handleIndicators(down, xDir);

      if (!down) {
        const screenWidth = window.innerWidth;
        const threshold = screenWidth / 3.975;

        if (Math.abs(xDelta) > threshold) {
          nope.add(index);
          const direction = xDelta > threshold ? 'like' : 'dislike';
        }
      }

      set(i => {
        if (index !== i) return;
        const isNope = nope.has(index);
        const x = isNope ? (200 + window.innerWidth) * Math.sign(xDelta) : down ? xDelta : 0;
        const rot = xDelta / 100 + (isNope ? Math.sign(xDelta) * 10 * Math.abs(xDelta) / 100 : 0);
        const scale = down ? 1.1 : 1;

        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 25, tension: down ? 800 : isNope ? 200 : 500 }
        };
      });
    }
  );

  if (loading) {
    return <div data-testid='loading'>
    <ClipLoader
      loading={loading}
      size={50}
      color="#ff7c62"
    /></div>;
  }

  const LikeIndicator = ({ style }) => (
    <animated.div
      className="like-dislike-indicator"
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "10rem",
        color: "#28a745",
        userSelect: "none",
        zIndex: 5,
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
      }}
    >
      <FontAwesomeIcon icon={faThumbsUp} data-testid="like-indicator"/>
    </animated.div>
  );

  const DislikeIndicator = ({ style }) => (
    <animated.div
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "10rem",
        color: "#dc3545",
        userSelect: "none",
        zIndex: 5,
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
      }}
    >
      <FontAwesomeIcon icon={faThumbsDown} data-testid="dislike-indicator"/>
    </animated.div>
  );

  const cards = props.map(({ x, y, rot, scale }, i) => (
    <animated.div
      key={i}
      className="homeAnimated"
      style={{
        transform: x.interpolate((x) => `translate3d(${x}px, 0, 0)`)
      }}
    >
      <HomeCard
        i={i}
        x={x}
        y={y}
        data={data[i]}
        bind={bind}
        trans={trans}
        rot={rot}
        scale={scale}
        ShowDetail={setShowDetail}
        DetailRequest={setDetailRequest}
        ActivateModal={setActivateModal}
      />
    </animated.div>
  ))

  return (
    <div id="HomeDeck" data-testid="HomeDeck">
      <LikeIndicator style={likeProps}></LikeIndicator>
      <DislikeIndicator style={dislikeProps}></DislikeIndicator>
      {cards}
    </div>
  );
}

export default HomeDeck;
