import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { undoLastSwipe } from '../redux/slices/deckSlice';
import { clearThumbnails } from '../redux/slices/thumbnailSlice';

const useSwipeActions = (token, data, currentIndex, dispatch, setData) => {
  const [swipeCounter, setSwipeCounter] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [swipeContentId, setSwipeContentId] = useState(null);

  const postSwipeAction = async () => {
    if (!token || !swipeDirection || !swipeContentId || isProcessing) return;

    setIsProcessing(true);
    const item = data.find(i => i.id === swipeContentId || i.ratingKey === swipeContentId);
    if (!item) {
      setIsProcessing(false);
      return;
    }

    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/${
        item.ratingKey ?
          (swipeDirection === 'like' ? 'liked_plex_contents' : 'disliked_plex_contents') :
          (swipeDirection === 'like' ? 'liked_contents' : 'disliked_contents')
      }`;

      await axios.post(endpoint, {
        content_id: swipeContentId
      }, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      });

    } catch (error) {
      console.error('Swipe action error:', error);
    } finally {
      setSwipeDirection(null);
      setSwipeContentId(null);
      setIsProcessing(false);
    }
  };

  const handleUndoLastSwipe = () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      dispatch(undoLastSwipe());
      setSwipeCounter(prev => Math.max(0, prev - 1));
      // Reset any swipe states
      setSwipeDirection(null);
      setSwipeContentId(null);
    } finally {
      setIsProcessing(false);
    }
  };

  // Effect to handle swipe actions
  useEffect(() => {
    if (swipeDirection && swipeContentId) {
      postSwipeAction();
    }
  }, [swipeDirection, swipeContentId]);

  return {
    swipeCounter,
    setSwipeCounter,
    swipeDirection,
    setSwipeDirection,
    swipeContentId,
    setSwipeContentId,
    undoLastSwipe: handleUndoLastSwipe,  // Return with original name but point to new function
    isProcessing
  };
};

export default useSwipeActions;
