import { useSpring } from "@react-spring/web";
import { useSelector } from 'react-redux';
import { selectCurrentCard } from '../redux/slices/deckSlice';

const useSwipeIndicators = () => {
  const currentCard = useSelector(selectCurrentCard);
  const [likeProps, setLike] = useSpring(() => ({
    opacity: 0,
    scale: 1,
    config: {
      tension: 500,
      friction: 35,  // Increased friction to make it linger
      clamp: true    // Ensures smooth transition
    }
  }));
  const [dislikeProps, setDislike] = useSpring(() => ({
    opacity: 0,
    scale: 1,
    config: {
      tension: 500,
      friction: 35,  // Increased friction to make it linger
      clamp: true    // Ensures smooth transition
    }
  }));

  const handleIndicators = (down, xDir) => {
    if (!currentCard) return;

    const likeOpacity = xDir > 0 && down ? 25 : 0;
    const dislikeOpacity = xDir < 0 && down ? 25 : 0;

    setLike({
      opacity: likeOpacity,
      scale: likeOpacity ? 1.2 : 1,
      immediate: down,
      config: { tension: 500, friction: down ? 35 : 50 }  // More friction when releasing
    });
    setDislike({
      opacity: dislikeOpacity,
      scale: dislikeOpacity ? 1.2 : 1,
      immediate: down,
      config: { tension: 500, friction: down ? 35 : 50 }  // More friction when releasing
    });
  };

  return { likeProps, dislikeProps, handleIndicators };
};

export default useSwipeIndicators;
