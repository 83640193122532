import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../assets/LoggedOutNavbar.css';

const LoggedOutNavbar = ({ loggedIn }) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <header className="loggedout-navbar">
      <div className="loggedout-navbar-container">
        {!loggedIn && (
          <nav className="loggedout-navbar-nav-icons">
            <Link to="/" className={`loggedout-nav-link-icons${isActive('/') ? ' selected' : ''}`}>
              <p className="loggedout-nav-icon-header">Home</p>
            </Link>
            <Link to="/login" className={`loggedout-nav-link-icons${isActive('/login') ? ' selected' : ''}`}>
              <p className="loggedout-nav-icon-header">Login</p>
            </Link>
            <Link to="/signup" className={`loggedout-nav-link-icons${isActive('/signup') ? ' selected' : ''}`}>
              <p className="loggedout-nav-icon-header">Signup</p>
            </Link>
          </nav>
        )}
      </div>
    </header>
  );
};

export default LoggedOutNavbar;
