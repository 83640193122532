import React, { useEffect, useState, useRef } from 'react';
import { createConsumer } from '@rails/actioncable';
import axios from 'axios';
import NotificationAlert from './NotificationAlert';
import { useSelector } from 'react-redux';

const NotificationsHandler = ({ setNotifyCount, setShouldRefreshRequests }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const [currentCount, setCurrentCount] = useState(0);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const initialFetchRef = useRef(false);

  const fetchNotificationsCount = async () => {
    if (!user || !token) return;

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sentinvites/${user.id}`,
        config
      );
      const count = response.data.pending_users.length;
      setCurrentCount(count);
      setNotifyCount(count);
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  useEffect(() => {
    let subscription;
    if (user && token && !initialFetchRef.current) {
      initialFetchRef.current = true;
      fetchNotificationsCount(); // Initial fetch only happens once

      const cable = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}/cable?auth_token=${token}`);
      subscription = cable.subscriptions.create(
        { channel: 'InviteChannel', auth_token: token, user_id: user.id },
        {
          connected: () => {
            // console.log('Connected to InviteChannel');
          },
          received: (data) => {
            if (data.notification.includes("invite from user")) {
              if (data.user && data.user.online) {
                const newCount = currentCount + 1;
                setCurrentCount(newCount);
                setNotifyCount(newCount);
                setShouldRefreshRequests(true);
              }
            } else if (data.notification.includes("accepted your friend request")) {
              if (data.accepter) {
                setNotificationMessage(data.notification);
                setNotificationVisible(true);
              }
            }
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [user, token]);

  // We can remove this effect since we're updating setNotifyCount directly in the other places
  // This was likely causing one of the duplicate updates
  // useEffect(() => {
  //   setNotifyCount(currentCount);
  // }, [currentCount, setNotifyCount]);

  return (
    <NotificationAlert
      message={notificationMessage}
      visible={isNotificationVisible}
      onClose={() => setNotificationVisible(false)}
    />
  );
};

export default NotificationsHandler;
