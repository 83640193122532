import React from 'react';
import '../assets/Checkbox.css';

function CheckboxList({ options, selectedOptions, onChange, isProvider, testId }) {
  const handleButtonClick = (event) => {
    const option = event.target.name;

    let updatedOptions = selectedOptions.slice();
    if (selectedOptions.includes(option)) {
      updatedOptions = updatedOptions.filter((selectedOption) => selectedOption !== option);
    } else {
      updatedOptions.push(option);
    }
    onChange(updatedOptions);
  };

  return (
    <div className="checkboxContainer" data-testid={testId}>
      {options.map((option, index) => (
        <label key={option} className={`checkboxLabel${isProvider ? ' provider' : ''}`}>
        {!isProvider && (
          <button
            name={option}
            onClick={handleButtonClick}
            className={`button ${option.toLowerCase()} ${selectedOptions.includes(option) ? 'buttonSelected' : ''}`}
          >
            {option}
          </button>
        )}
        {isProvider && (
          <img
            src={option}
            name={option}
            alt={`provider-logo ${index}`}
            className={`providerImage${selectedOptions.includes(option) ? 'checked' : ''}`}
            onClick={handleButtonClick}
          />
        )}
        </label>
      ))}
    </div>
  );
}

export default CheckboxList;
