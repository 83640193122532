import React, { useEffect, useState } from "react";

import "../assets/userList.css";
import useDebounce from './useDebounce';

const SearchFriends = ({
  loggedIn,
  user,
  searchFriends,
  fetchInitialFriends,
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500); // 500 ms debounce delay

  const [isMounted, setIsMounted] = useState(false); // New state for tracking if the component is mounted

  useEffect(() => {
    setIsMounted(true); // Set isMounted to true when the component mounts
    return () => setIsMounted(false); // Reset isMounted when the component unmounts
  }, []);

  const handleSearch = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (loggedIn && user && isMounted) { // Now checks if the component is fully mounted
      if (debouncedInputValue) {
        searchFriends(debouncedInputValue);
      } else {
        fetchInitialFriends(true);
      }
    }
  }, [loggedIn, user, debouncedInputValue, isMounted]); // Added isMounted to the dependencies array

  return (
    <>
      <div className="userList-header">
        <h1>Search Friends</h1>
        <input type="text" onChange={handleSearch} value={inputValue} />
      </div>
    </>
  );
};

export default SearchFriends;
