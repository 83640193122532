import React from "react";
import UserRecentSearches from './UserRecentSearches'

import "../assets/userList.css";

import InfiniteScroll from "react-infinite-scroll-component";

const SearchUsers = ({
  loggedIn,
  user,
  searchValue,
  setSearchValue,
  displayResults,
  fetchMoreUsers,
  hasMore,
  loading,
  sendFriendRequest,
  sentInvitationsList,
  sentRequest,
  setSentRequest,
  recentSearches,
  handleRecentSearchClick,
  imageUrl,
}) => {
  const handleSearch = (e) => {
    if (loggedIn && user) {
      setSearchValue(e.target.value);
    }
  };

  const handleClick = (user) => {
    sendFriendRequest(user);
    setSentRequest({ ...sentRequest, [user.id]: true });
  };

  return (
    <>
      <div className="userList-header">
        <h1>Search Users</h1>
        <input type="text" onChange={handleSearch} value={searchValue} placeholder='Search for users...'/>
      </div>
      <UserRecentSearches
        recentSearches={recentSearches}
        handleRecentSearchClick={handleRecentSearchClick}
      />
      <div id="scrollableDiv" style={{ height: "80vh", overflow: "auto", backgroundColor: "var(--background)" }}>
        <InfiniteScroll
          dataLength={displayResults.length}
          next={fetchMoreUsers}
          hasMore={hasMore}
          loader={loading ? <div className="loader">Loading ...</div> : null}
          scrollableTarget="scrollableDiv"
          scrollThreshold="50px"
        >
          <div className="userList">
            {displayResults
              .filter(result => result.id !== user.id)
              .map((user, i) => {
                const isPending =
                  sentInvitationsList.some((invitation) => invitation.friend_id === user.id) ||
                  sentRequest[user.id];

                return (
                  <div className="userCard" key={user.id}>
                    <img
                      className="userImage"
                      data-testid="profile_image"
                      src={user.profile_image_url ? user.profile_image_url : require('../assets/images/dummy-pic.jpg')}
                      alt="profile-pic"
                    />
                    <div className="UserBody">
                      <h2>{user.firstname}</h2>
                      <p>{user.lastname}</p>
                    </div>
                    <button data-testid={`requestbtn-${i}`} disabled={isPending} onClick={() => handleClick(user)}>
                      {isPending ? "Pending" : "Send Request"}
                    </button>
                  </div>
                );
            })}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};


export default SearchUsers;
