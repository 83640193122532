import React, { useState, useEffect } from 'react';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import axios from 'axios';

import ContentModal from "./ContentModal";

import '../assets/MatchList.css'

const MatchList = ({ user, loggedIn, token }) => {
  const [matchedFriends, setMatchedFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [likedContent, setLikedContent] = useState(null);
  const [selectedFriendName, setSelectedFriendName] = useState(null);
  const [likedContentLoading, setLikedContentLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const override = css`
    display: block;
    margin: 0 auto;
  `;

  useEffect(() => {
    const fetchMatches = async () => {
      if (user.id && token) {
        let config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };

        setPageLoading(true);

        axios
          .get(`${process.env.REACT_APP_API_URL}/matches/${user.id}`, config)
          .then(res => {
            setMatchedFriends(res.data);
            setLoading(false);
            setPageLoading(false);
          });
      }
    };

    fetchMatches();
  }, [user.id, token]);

  useEffect(() => {
    const fetchLikedContent = async () => {
      if (selectedFriend && token) {
        setLikedContentLoading(true);

        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/matches/${user.id}/${selectedFriend.friend.friend_id}`,
            {
              params: {
                location_preference: user.location_preference
              },
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            }
          )
          .then((res) => {
            setLikedContent(res.data);
            setLikedContentLoading(false);
          });
      }
    };

    fetchLikedContent();
  }, [selectedFriend, token]);

  const handleFriendClick = (friend) => {
    setSelectedFriend(friend);
    setSelectedFriendName(friend.firstname || 'No data found'); // Save friend's firstname to state
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedFriend(null);
    setShowModal(false);
  };

  return (
    <>
      <div className='matchListHeader'><h2>Friends who've matched with you</h2></div>
      {pageLoading ? (
        <div className="loader-container">
          <ClipLoader
            size={35}
            aria-label="Loading Spinner"
            data-testid="loader"
            css={override}
          />
        </div>
      ) : (
        <>
          {Object.keys(matchedFriends).length === 0 ? (
            <div className='card-matches-message'>
              <h2>No matches with any of your friends. Get swiping!</h2>
            </div>
          ) : (
            <div className='scroll-container'>
            <div className='card-container'>
              {matchedFriends.map(friend => {
                const friendImageUrl = friend.profile_image_url ? friend.profile_image_url : require('../assets/images/dummy-pic.jpg');

                return (
                  <div className='matchCard' key={friend.friend_id} onClick={() => handleFriendClick({ friend: friend })}>
                    <img className="match-card-image" src={friendImageUrl} alt="profile-pic" />
                    <div className='cardInfo'>
                      <div className="card-firstname">
                        {friend.firstname || 'No data found'}
                      </div>
                      <div className='card-lastname'>
                        {friend.lastname || 'No data found'}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
      )}
      {showModal && likedContent !== null && (
        <ContentModal
          token={token}
          closeModal={closeModal}
          loading={likedContentLoading}
          selectedFriend={selectedFriend}
          likedContent={likedContent}
          isOpen={showModal}
          user={user}
        />
      )}
    </>
  );
}

export default MatchList;
