import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isOAuthUser } from '../../utils/authHelpers';
import {
  setPersonalInfoLoading,
  setPersonalInfoError,
  setPersonalInfoSuccess
} from '../../redux/slices/settingsSlice';
import { setUser } from '../../redux/slices/userSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import SearchableCountryDropdown from '../Common/SearchableCountryDropdown';

import '../../assets/update.css';

const PersonalInfoSection = ({ user, token }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector(state => state.settings.personalInfo);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [locationPreference, setLocationPreference] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCountries();
  }, [token]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setPersonalInfoLoading(true));

    const updatedFields = {
      firstname: firstname || undefined,
      lastname: lastname || undefined,
      location_preference: locationPreference || undefined,
    };

    // Only add email to updatedFields if user is not OAuth
    if (!isOAuthUser(user)) {
      updatedFields.email = email || undefined;

      if (email) {
        if (!currentPassword) {
          dispatch(setPersonalInfoError("Current password required for email update"));
          dispatch(setPersonalInfoLoading(false));
          return;
        }
        updatedFields.current_password = currentPassword;
      }
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        { user: updatedFields },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        // Only handle email update specially for non-OAuth users
        if (!isOAuthUser(user) && email && email !== user.email) {
          // Clear auth state and redirect for email updates
          dispatch(setLoggedIn(false));
          dispatch(setUser(null));
          dispatch(setToken(null));
          sessionStorage.clear();
          navigate('/', {
            state: {
              message: 'Please check your email to confirm your new address. You will need to log in again after confirming.',
              type: 'info'
            }
          });
        } else {
          // For all other updates, just update the user data and show success
          dispatch(setPersonalInfoSuccess("Personal information updated successfully"));
          dispatch(setUser(response.data.user));
          clearForm();
        }
      } else {
        dispatch(setPersonalInfoError(response.data.error || "Update failed"));
      }
    } catch (error) {
      dispatch(setPersonalInfoError(error.response?.data?.message || "Update failed"));
    } finally {
      dispatch(setPersonalInfoLoading(false));
    }
  };

  const clearForm = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setCurrentPassword('');
  };

  return (
    <div className="section-content">
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <input
        type="text"
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
        placeholder="New Firstname"
      />
      <input
        type="text"
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
        placeholder="New Lastname"
      />

      {!isOAuthUser(user) && (
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="New Email"
        />
      )}

      <SearchableCountryDropdown
        countries={countries}
        value={locationPreference}
        onChange={setLocationPreference}
        placeholder="Select Country"
        locationPreference={user.location_preference}
      />

      {!isOAuthUser(user) && email && (
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Enter Current Password to update email"
        />
      )}

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="btn-update-section"
      >
        {loading ? 'Updating...' : 'Update Personal Info'}
      </button>
    </div>
  );
};

export default PersonalInfoSection;
