import React, { useEffect, useState } from 'react';
import axios from 'axios';

import PasswordStrengthBar from '../PasswordStrengthBar';
import UpdateTwoFactorAuth from './UpdateTwoFactorAuth';
import ImageUpload from './ImageUpload';
import UserPreference from '../UserPreference';
import StripeSubscription from './StripeSubscription';

import '../../assets/update.css'
import tmdb_logo from '../../assets/images/tmdb_logo.svg'
import { Link } from 'react-router-dom';

function Update({ user, loggedIn, token, logOut, subscriptionEndDate, cancelAtPeriodEnd, subscriptionStatus}) {
  const [firstname, setfirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState(false)
  const [validationErrors, setValidationErrors] = useState([]);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [updating, setUpdating] = useState(false);
  const [qrCodeSVG, setQrCodeSVG] = useState(null);
  const [showQrCode, setShowQrCode] = useState(true);
  const [is2FAEnabled, setIs2FAEnabled] = useState(null);
  const [show2FACodeInput, setShow2FACodeInput] = useState(false);
  const [verificationSuccessful, setVerificationSuccessful] = useState(false);
  const [totpSecret, setTotpSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [locationPreference, setLocationPreference] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);


  const updateUser = async (newLocationPreference = null) => {

    let updatedUser = {};
    if (firstname) updatedUser.firstname = firstname;
    if (lastname) updatedUser.lastname = lastname;
    if (email) updatedUser.email = email;
    if (currentPassword) updatedUser.current_password = currentPassword;
    if (password) updatedUser.password = password;
    if (passwordConfirmation) updatedUser.password_confirmation = passwordConfirmation;
    if (newLocationPreference || locationPreference) updatedUser.location_preference = newLocationPreference || locationPreference;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setUpdating(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/users/${user.id}`, { user: updatedUser }, config)
      .then((response) => {
        if (response.data.status === "update_failed") {
          setUpdateError(true);
          setValidationErrors(response.data.error);
        } else if (response.data.status === "success") {
          setSuccessMessage("UPDATE SUCCESS");
          setUpdateError(false);
          setValidationErrors([]);
          window.location = "/update";
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const enable2FA = async () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/${user.id}/enable_totp`, {}, config)
      .then((response) => {
        if (response.data.qrcode) {
          setQrCodeSVG(response.data.qrcode);
          setTotpSecret(response.data.totp_secret); // Store the secret in the state
          setShow2FACodeInput(true);
        } else {
          alert("1 Error enabling 2FA. Please try again.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("2 Error enabling 2FA. Please try again.");
      });
  };

  const handle2FAVerification = async (verified, status) => {
    if (verified) {
      setIs2FAEnabled(true);
      setShow2FACodeInput(false);
      setVerificationSuccessful(true);
      setShowQrCode(false);
      const timer = setTimeout(() => {
        setVerificationSuccessful(false);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (status === "error") {
      alert("3 Error verifying 2FA code. Please try again.");
    }
  };


  const toggle2FA = async () => {
    if (is2FAEnabled) {
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (is2FAEnabled) {
        if (window.confirm("Are you sure you want to disable 2FA?")) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/users/${user.id}/disable_totp`, {}, config)
            .then((response) => {
              if (response.data.message === "2FA disabled") {
                setIs2FAEnabled(false);
                setQrCodeSVG(null);
              } else {
                alert("4 Error updating 2FA settings. Please try again.");
              }
            })
            .catch((err) => {
              console.log(err);
              alert("5 Error updating 2FA settings. Please try again.");
            });
        }
      }
    } else {
      enable2FA();
    }
  };


  const deleteAccount = async () => {
    var question = window.confirm("Are you sure you want to leave us?");
    if (question) {
      axios.delete(`${process.env.REACT_APP_API_URL}/users/${user.id}`,
        {
          headers:
          {
            'Authorization': 'Bearer ' + token,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        })
        .then((response) => {
          if (response.status === 200) {
            // redirect to home page
            localStorage.token = '';
            logOut()
            window.location = "/";
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Error deleting account. Please try again.");
        });
    }
  }

  const fetchUserDetails = async () => {
    if (loggedIn && user.id && token) {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (response.data && response.data.user) {
          setIs2FAEnabled(response.data.user.totp_enabled);
          setLocationPreference(response.data.user.location_preference);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchUserDetails();
    setLoading(false)
  }, [loggedIn, is2FAEnabled]);

  const saveUserPreferences = async (selectedProviders) => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_preferences`,
        {
          user_preference: {
            streaming_providers: selectedProviders.map((provider) => ({
              provider_name: provider.provider_name,
              logo_path: provider.logo_path,
            })),
          },
        },
        config
      );

      if (response.data.status === "success") {
      } else {
        console.error("Failed to save user preferences:", response.data.errors);
      }
    } catch (error) {
      console.error("Error saving user preferences:", error);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const imageUrl = user.profile_image_url ? user.profile_image_url : require('../../assets/images/dummy-pic.jpg');

  return (
    <div className="update-container">
      <div className="update-items">
        <h2>Update {user.firstname}'s Information</h2>
        <ImageUpload user={user} token={token} />
        <p>Current email: {user.email}</p>
        <p>Current firstname: {user.firstname}</p>
        <p>Current lastname: {user.lastname}</p>
      </div>
      <form className="update-form">
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="New Email"
        />
        <input
          type="text"
          value={firstname}
          onChange={(e) => setfirstname(e.target.value)}
          placeholder="New Firstname"
        />
        <input
          type="text"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          placeholder="New Lastname"
        />
        <select
          value={locationPreference}
          onChange={async (e) => {
            setLocationPreference(e.target.value);
            await updateUser(e.target.value);
          }}
        >
          <option value="">Select Country</option>
          <option value="UK">United Kingdom</option>
          <option value="US">United States</option>
        </select>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
        />
        <input
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          placeholder="Confirm Password"
        />
        <PasswordStrengthBar password={password} />
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Enter Current Password to update"
        />
        <UserPreference user={user} token={token} saveUserPreferences={saveUserPreferences}/>
        <button
          onClick={(e) => {
            e.preventDefault();
            updateUser();
          }}
          type="submit"
          disabled={updating}
          className={updating ? 'updating' : ''}
        >
          {updating ? 'Updating...' : 'UPDATE'}
        </button>
        {updateError && (
          <ul className="update-error">
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
        <button
          className="btn-toggle-2fa"
          onClick={toggle2FA}
          type="button"
        >
          {loading ? "Loading..." : is2FAEnabled ? "Disable 2FA" : "Enable 2FA"}
        </button>
      </form>
      {qrCodeSVG && showQrCode && (
        <div
          className="qrcode-container"
          data-testid="qrcode-container"
          dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
        />
      )}
      {show2FACodeInput && (
        <UpdateTwoFactorAuth
          user_id={user.id}
          onVerification={handle2FAVerification}
          totpSecret={totpSecret}
        />
      )}
      {verificationSuccessful && <h4 className="twofactorauth-success">Verification successful!</h4>}
      <StripeSubscription token={token} cancelAtPeriodEnd={cancelAtPeriodEnd} subscriptionStatus={subscriptionStatus} subscriptionEndDate={subscriptionEndDate} />
      <div className='delete-container'>
        <button onClick={toggleDropdown}>Terms &amp; Policies</button>
          {dropdownOpen && (
            <div className="terms-dropdown-content">
              <Link className="update-terms-link" to="/privacy">Privacy Policy</Link>
              &nbsp;
              <Link className="update-terms-link" to="/terms">Terms of Use</Link>
            </div>
          )}
        <button
          className="btn-delete-account"
          onClick={deleteAccount}
          type="submit"
        >
          DELETE ACCOUNT
        </button>
      </div>
      <div className='credits-container'>
        <p>This product uses the TMDb API but is not endorsed or certified by TMDb.</p>
        <img src={tmdb_logo} alt="The Movie Database Logo" />
      </div>
    </div>
  );
}

export default Update;
