import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';

import '../assets/ContentDetail.css';
import imdbLogo from '../assets/images/imdb_logo.png'
import metaCriticLogo from '../assets/images/metacritic-logo-original.png'
import rottenLogo from '../assets/images/Rotten_tomato_logo.png'
import justwatch_logo from '../assets/images/JustWatch-logo.webp'

const ContentDetail = ({ token, contentId, closeDetail, user, friend}) => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const prime = "https://image.tmdb.org/t/p/w342/emthp39XA2YScoYL1p0sdbAH2WA.jpg"
  const apple = "https://image.tmdb.org/t/p/w342/6uhKBfmtzFqOcLousHwZuzcrScK.jpg"
  const disney = "https://image.tmdb.org/t/p/w342/7rwgEs15tFwyR9NPQ5vpzxTj19Q.jpg"
  const iplayer = "https://image.tmdb.org/t/p/w342/zY5SmHyAy1CoA3AfQpf58QnShnw.jpg"
  const netflix = "https://image.tmdb.org/t/p/w342/t2yyOv40HZeVlLjYsCsPHnWLk4W.jpg"
  const now = "https://image.tmdb.org/t/p/w342/y7mZSw1FV99yfawxOISBQTvtJxM.jpg"
  const mubi = "https://image.tmdb.org/t/p/w342/bVR4Z1LCHY7gidXAJF5pMa4QrDS.jpg"
  const hotstar = "https://image.tmdb.org/t/p/w342/7Fl8ylPDclt3ZYgNbW2t7rbZE9I.jpg"
  const all4 = "https://image.tmdb.org/t/p/w342/kJ9GcmYk5zJ9nJtVX8XjDo9geIM.jpg"
  const britbox = "https://image.tmdb.org/t/p/w342/dtU2zKZvtdKgSKjyKekp8t0Ryd1.jpg"
  const sky_go = "https://image.tmdb.org/t/p/w342/fBHHXKC34ffxAsQvDe0ZJbvmTEQ.jpg"
  const hulu = "https://image.tmdb.org/t/p/w342/zxrVdFjIjLqkfnwyghnfywTn3Lh.jpg";
  const itvx = "https://image.tmdb.org/t/p/w342/clC87JljoZKeAHIwdlxd1AUn6cn.jpg";
  const hbo_max = "https://image.tmdb.org/t/p/w342/Ajqyt5aNxNGjmF9uOfxArGrdf3X.jpg";
  const paramount = "https://image.tmdb.org/t/p/w342/xbhHHa1YgtpwhC8lb1NQ3ACVcLd.jpg";
  const peacock = "https://image.tmdb.org/t/p/w342/xTHltMrZPAJFLQ6qyCBjAnXSmZt.jpg";
  const showtime = "https://image.tmdb.org/t/p/w342/4kL33LoKd99YFIaSOoOPMQOSw1A.jpg";
  const starz = "https://image.tmdb.org/t/p/w342/eWp5LdR4p4uKL0wACBBXapDV2lB.jpg";

  const providerLogos = {
    apple,
    disney,
    iplayer,
    netflix,
    now,
    prime,
    mubi,
    hotstar,
    all4,
    britbox,
    paramount_plus: paramount,
    sky_go,
    hulu,
    hbo_max,
    hbo: hbo_max,
    itvx,
    paramount,
    peacock,
    showtime,
    starz
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contents/${contentId}`,
          {
            params: {
              location_preference: user.location_preference,
            },
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }
        );
        setContent(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchContent();
  }, [contentId, token]);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : content ? (
        <div className="contentDetail-container" data-testid='contentDetail-container'>
          <div className="contentDetail-card">
            <div className="close-container">
                <FontAwesomeIcon
                onClick={closeDetail}
                title="Close"
                icon={faSquareXmark}
                size="3x"
              />
            </div>
            <div className="social-media-container">
              {content.facebook_id && content.facebook_id !== 'https://www.facebook.com/' && (
                <FontAwesomeIcon
                  icon={faFacebookF}
                  title="Facebook"
                  onClick={() => window.open(content.facebook_id, '_blank')}
                  className="social-icon"
                />
              )}
              {content.instagram_id && content.instagram_id !== 'https://www.instagram.com/' && (
                <FontAwesomeIcon
                  icon={faInstagram}
                  title="Instagram"
                  onClick={() => window.open(content.instagram_id, '_blank')}
                  className="social-icon"
                />
              )}
              {content.twitter_id && content.twitter_id !== 'https://twitter.com/' && (
                <FontAwesomeIcon
                  icon={faTwitter}
                  title="Twitter"
                  onClick={() => window.open(content.twitter_id, '_blank')}
                  className="social-icon"
                />
              )}
            </div>
            <h2>{content.name || content.title}</h2>
            <div className="media-runtime-container">
              <h5>{content.media_type === 'tv' ? 'TV Show' : 'Movie'}</h5>
              {content.runtime && (
                <p>{content.runtime} mins</p>
              )}
            </div>
            <img className="ContentDetailPoster" src={content.poster_path} alt={content.name || content.title} />
            <p>{content.overview}</p>
            {content.ratings && content.ratings.length > 0 && (
              <div className="providers-section">
                <ul>
                  {content.ratings.map((rating, index) => (
                    <li key={index}>
                      {rating.Source === 'Internet Movie Database' ? (
                        <img src={imdbLogo} alt="IMDb logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Metacritic' ? (
                        <img src={metaCriticLogo} alt="Metacritic logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : rating.Source === 'Rotten Tomatoes' ? (
                        <img src={rottenLogo} alt="Rotten Tomatoes logo" style={{ height: '3em', marginRight: '0.5em' }} />
                      ) : (
                        rating.Source
                      )}{rating.Value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {content.director && content.director !== 'N/A' && (
              <p>
                <strong>Director:</strong> {content.director}
              </p>
            )}
            {content.awards && content.awards !== 'N/A' && content.awards !== 0 && (
              <p>
                <strong>Awards:</strong> {content.awards}
              </p>
            )}
            {typeof content.revenue === 'number' && content.revenue !== 0 && (
              <p>
                <strong>Total Revenue:</strong> ${content.revenue}
              </p>
            )}
            {
              content.watch_links &&
              Object.values(content.watch_links).some((links) => links.length > 0) && (
                <h3>Watch Now Direct Link</h3>
              )
            }
            {content.watch_links && Object.keys(content.watch_links).map((provider, index) => {
              const uniqueLinks = content.watch_links[provider].filter((link, idx, self) =>
                idx === self.findIndex((l) => (
                  l.link === link.link && l.watchLink === link.watchLink && l.type === link.type
                ))
              );
              return (
                uniqueLinks.length > 0 && (
                  <div className="providers-section" key={index}>
                    {uniqueLinks.map((link, idx) => (
                      ((link.watchLink && link.watchLink.trim() !== '') || (link.link && link.link.trim() !== '')) ? (
                        <div key={idx} className="provider-item">
                          <a href={link.watchLink || link.link} target="_blank" title={`${provider} ${idx} logo`} rel="noopener noreferrer">
                            <img
                              src={providerLogos[provider]}
                              className="provider-logo"
                              alt={`${provider} logo`}
                            />
                          </a>
                          <div className="type-info">
                            <span className="type-label">{link.type.charAt(0).toUpperCase() + link.type.slice(1)}</span>
                            {link.price && link.price.formatted && (
                              <span className="type-amount">({link.price.formatted})</span>
                            )}
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div>
                )
              );
            })}

            {(content.buy_providers.length > 0 || content.rent_providers.length > 0 || content.flatrate_providers.length > 0)
              ? (<h3>More Provider Info</h3>)
              : null
            }

            {content.buy_providers && content.buy_providers.length > 0 && (
              <div className="providers-section">
                <h4>Where to Buy</h4>
                <div className="provider-container">
                  {content.buy_providers.map((buy, index) => (
                    <img key={index} alt={`${buy.provider_name} Logo`} src={buy.logo_path} className="provider-logo" />
                  ))}
                </div>
              </div>
            )}
            {content.rent_providers && content.rent_providers.length > 0 && (
              <div className="providers-section">
              <h4>Where to Rent</h4>
                <div className="provider-container">
                  {content.rent_providers.map((rent, index) => (
                    <img key={index} alt={`${rent.provider_name} Logo`} src={rent.logo_path} className="provider-logo" />
                  ))}
                </div>
              </div>
            )}
            {content.flatrate_providers && content.flatrate_providers.length > 0 && (
              <div className="providers-section">
                <h4>Free with Subscription</h4>
                <div className="provider-container">
                  {content.flatrate_providers.map((free, index) => (
                    <img key={index} alt={`${free.provider_name} Logo`} src={free.logo_path} className="provider-logo" />
                  ))}
                </div>
              </div>
            )}
            <div className="contentDetail-credits-container">
              <p>Provider Info sourced from JustWatch</p>
              <img className="justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
            </div>
          </div>
        </div>
      ) : (
        <div>Content not found</div>
      )}
    </>
  );
};

export default ContentDetail;
