import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export function setCurrentUser(currentUser, token, exp, refresh_token, setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd, setToken, setLoggedIn, setUser) {
  setUser(currentUser);
  setSubscriptionStatus(currentUser.subscription_status);
  setSubscriptionEndDate(currentUser.subscription_end_date);
  setCancelAtPeriodEnd(currentUser.cancel_at_period_end);
  setToken(token);
  setLoggedIn(true);
  localStorage.setItem("jwt", token);
  localStorage.setItem("exp", exp);
  sessionStorage.setItem("refresh_token", refresh_token);
  const fingerprint = navigator.userAgent;
  Cookies.set('fingerprint', fingerprint, { expires: 1, secure: isProduction });
}

export async function getJWT(token, setToken, setUser, setLoggedIn, cable) {
  if (!token && sessionStorage.refresh_token) {
    try {
      const refreshToken = sessionStorage.getItem("refresh_token");
      const fingerprint = Cookies.get('fingerprint');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh_token`,
        { refresh_token: refreshToken },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Fingerprint": fingerprint
          },
        }
      );
      setToken(response.data.jwt);
      return response.data.jwt;
    } catch (error) {
      console.error(error);
      logOut(setUser, setToken, setLoggedIn, cable);
    }
  }
  return token;
}

export function logOut(setUser, setToken, setLoggedIn, cable) {
  setUser({});
  setToken(null);
  setLoggedIn(false);
  localStorage.removeItem("jwt");
  localStorage.removeItem("exp");
  localStorage.removeItem("refresh_token");
  sessionStorage.clear();
  Cookies.remove('fingerprint');
  if (cable) {
    cable.disconnect();
  }
}
