import React, { useEffect, useState } from 'react';
import NotificationCard from './NotificationCard';
import InfiniteScroll from 'react-infinite-scroller';
import ClipLoader from "react-spinners/BeatLoader";

import SearchFriends from './SearchFriends';

import '../assets/Friends.css'
import defaultProfileImage from '../assets/images/dummy-pic.jpg';
import axios from 'axios';

const Friends = ({
  loggedIn,
  user,
  token,
  loadMoreFriends = null,
}) => {

  const [friendsList, setFriendsList] = useState([]);
  const [friendsPage, setFriendsPage] = useState(1);
  const [friendsHasMore, setFriendsHasMore] = useState(false)
  const [friendsChanged, setFriendsChanged] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [friendsLoading, setFriendsLoading] = useState(false)

  const searchFriends = async (query) => {
    if (loggedIn && user && token && !friendsLoading && !requestInProgress) {
      setFriendsLoading(true);
      setRequestInProgress(true);

      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        },
      };

      let url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?search=${query}&page=1&per_page=15`;

      try {
        const response = await axios.get(url, config);
        const sortedFriends = response.data.friends.sort((a, b) => b.online - a.online);

        setFriendsList(sortedFriends);
        setFriendsPage(1);
        setFriendsHasMore(response.data.friends.length >= 15);
      } catch (error) {
        console.log(error);
      } finally {
        setFriendsLoading(false);
        setRequestInProgress(false);
      }
    }
  };

  const fetchInitialFriends = async (reset = false) => {
    if (loggedIn && user && token && !friendsLoading && !requestInProgress) {
      setFriendsLoading(true);
      setRequestInProgress(true)

      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        },
      };

      let url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?page=${friendsPage}&per_page=15`;

      try {
        const response = await axios.get(url, config);
        const sortedFriends = response.data.friends.sort((a, b) => b.online - a.online);

        if (reset) {
          setFriendsList(sortedFriends);
          setFriendsPage(1); // Reset the friendsPage state
        } else {
          setFriendsList(prevFriends => [...prevFriends, ...sortedFriends]);
        }

        if (response.data.friends.length < 6) {
          setFriendsHasMore(false);
          setFriendsLoading(false);
        } else {
          setFriendsPage(friendsPage + 1);
          setFriendsHasMore(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setFriendsLoading(false);
        setRequestInProgress(false)
        setFriendsChanged(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchInitialFriends();
    }
  }, [token]);

  const deleteFriendship = async (friend) => {
    const userId = user.id
    const friendId = friend.id
    const url = `${process.env.REACT_APP_API_URL}/destroy_friendship`

    const data = {
      user_id: userId,
      friend_id: friendId
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    var question = window.confirm(`Are you sure you want to delete friend?`);
    if (question) {
      axios.delete(url, { headers, params: data })
      .then((response) => {
        const updatedFriendsList = friendsList.filter(friend => friend.id !== friendId);
        setFriendsList(updatedFriendsList);
        setFriendsChanged(true);
      })
    }
  }

  const defaultLoadMoreFriends = async () => {
    if (loggedIn && user && token && !friendsLoading && !requestInProgress && friendsHasMore) {
      setFriendsLoading(true);
      setRequestInProgress(true)

      let config = {
        headers: {
          Authorization: 'Bearer ' + token,
          responseType: 'json',
        },
      };

      let url = `${process.env.REACT_APP_API_URL}/friends/${user.id}?page=${friendsPage}&per_page=15`;

      try {
        const response = await axios.get(url, config);
        const sortedFriends = response.data.friends.sort((a, b) => b.online - a.online);

        setFriendsList(prevFriends => [...prevFriends, ...sortedFriends]);

        if (response.data.friends.length < 15) {
          setFriendsHasMore(false);
        } else {
          setFriendsPage(friendsPage + 1);
          setFriendsHasMore(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setFriendsLoading(false);
        setRequestInProgress(false)
      }
    }
  };

  // Use the prop if it's provided; otherwise, use the default implementation
  const loadMoreFriendsToUse = loadMoreFriends || defaultLoadMoreFriends;

  return (
    <div className='friends-container'>
      <div className='friends-header-container'>
        <h1 className='friends-header'>Your Friends</h1>
      </div>
      <SearchFriends friendsLoading={friendsLoading} requestInProgress={requestInProgress} token={token} fetchInitialFriends={fetchInitialFriends} loggedIn={loggedIn} user={user} searchFriends={searchFriends} />
      <div style={{ height: "calc(100vh - 120px)", overflow: "auto" }}>
        <InfiniteScroll
          element="div"
          className="notificationList"
          pageStart={1}
          loadMore={loadMoreFriendsToUse}
          hasMore={friendsHasMore}
          useWindow={false}
        >

          {friendsList.map((friend) => (
              <div className="friendsNotificationCardContainer" key={friend.id}>
                <NotificationCard imageUrl={friend.profile_image_url ? friend.profile_image_url : defaultProfileImage} id={friend.id} firstname={friend.firstname} lastname={friend.lastname} email={friend.email} online={friend.online} />
                {loggedIn && user && (
                  <button data-testid={`${friend.id}-deletebtn`} onClick={() => deleteFriendship(friend)}>Delete Friend</button>
                )}
              </div>
            ))
          }
          {friendsLoading && (
            <ClipLoader
              loading={friendsLoading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#ff7c62"
            />
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Friends;
