// TwoFactorToggle.jsx
import React, { useState } from 'react';
import '../../assets/TwoFactorToggle.css';

const TwoFactorToggle = ({ enable2FA, setEnable2FA }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="two-factor-section">
      <div className="two-factor-container">
        <div className="two-factor-content">
          <div className="two-factor-text">
            <h4>Two-Factor Authentication (2FA)</h4>
            <p>Add an extra layer of security to your account</p>
          </div>
          <div className="two-factor-controls">
            <button
              type="button"
              className="info-button"
              onClick={() => setShowInfo(!showInfo)}
              aria-label="More information about 2FA"
            >
              ?
            </button>
            <label className="toggle">
              <input
                type="checkbox"
                checked={enable2FA}
                onChange={(e) => setEnable2FA(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>

      {showInfo && (
        <div className="two-factor-info">
          <h5>Why use Two-Factor Authentication?</h5>
          <ul>
            <li>Adds an extra security layer beyond your password</li>
            <li>Protects against unauthorized access even if your password is compromised</li>
            <li>Uses your phone to verify it's really you logging in</li>
            <li>Recommended for keeping your account secure</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TwoFactorToggle;
