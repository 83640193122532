// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  background-color: var(--background);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0; /* Reduced padding to bring it closer to the last card */
  margin-top: -400px; /* Negative margin to pull it up closer to the cards */
}
`, "",{"version":3,"sources":["webpack://./src/assets/SearchUsers.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAE,wDAAwD;EACzE,kBAAkB,EAAE,sDAAsD;AAC5E","sourcesContent":[".loader-container {\n  background-color: var(--background);\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 10px 0; /* Reduced padding to bring it closer to the last card */\n  margin-top: -400px; /* Negative margin to pull it up closer to the cards */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
