import React, { useState } from 'react';
import HomeDeck from './HomeDeck';
import { Link } from 'react-router-dom';
import logo from '../assets/images/flickswipe_logo.png'
import '../assets/Home.css';

const Home = ({ loggedIn, user }) => {
  const [showModal, setShowModal] = useState(true);

  return (
    <div className="home-container">
      {loggedIn && user ? null : (
      <>
        {showModal && (
          <div className="modal" role="dialog">
            <div className="LoggedOut-homepage-container">
              <div className="LoggedOut-logo-container">
                <img src={logo} alt="Flickswipe logo" className="LoggedOut-flickswipe-logo" />
              </div>
              <div className="LoggedOut-tagline-container">
                <h1 className="LoggedOut-tagline">Swipe, Match, Watch!</h1>
              </div>
              <button onClick={() => setShowModal(false)}>Try swiping!</button>
            </div>
          </div>
        )}
        <HomeDeck />
        <Link className='home-signup-link' to="/signup">Signup now </Link>
      </>
      )}
    </div>
  );
};

export default Home;
