import React from 'react';

import '../../assets/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
      <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>

            <p>Last updated: Friday 21st June 2023</p>

            <p>Welcome to Flickswipe. We respect your privacy and are committed to protecting it. This Privacy Policy explains our practices regarding the collection, use, disclosure, and protection of information that is collected through our app, as well as your choices regarding the collection and use of information.</p>

            <h2>1. How We Collect and Use Information</h2>

            <p>We collect and use your Personal Information for the primary legal basis of facilitating the Service, specifically to enable matching of movies and TV shows with your friends, and to manage your subscription if you choose to subscribe to our premium features. This is achieved when you register with us through the Service, providing personally identifiable information ("Personal Information") such as your name, email address, profile photo, and payment information (for premium subscribers).</p>

            <h2>2. OAuth Facebook and Google Login</h2>

            <p>We offer the ability to register and login to the Service using your third-party social media account details (like your Facebook or Google logins). We receive certain profile information from your social media provider, which is used to streamline the registration process and facilitate matching preferences with your friends. We do not post on your behalf or share your information without your permission.</p>

            <h2>3. Payment Information</h2>

            <p>If you choose to subscribe to Flickswipe’s premium services, you will be required to provide payment information. Your payment information is processed by our third-party payment processor, Stripe. We do not store your payment information on our servers. Stripe may collect certain financial information from you, such as your credit/debit card number or bank account information, in order to process payments. We encourage you to read Stripe’s privacy policy to understand how they handle your financial information.</p>

            <h2>4. Data Retention</h2>

            <p>Your Personal Information is retained for as long as your account is active. Unconfirmed accounts are removed once a week. Your preferences, such as liked and disliked items, are updated every other day with new content, removing old selections from your list.</p>

            <h2>5. Your Rights</h2>

            <p>You have the right to access, correct, delete, or restrict the processing of your Personal Information at any time. You can edit your profile or delete your account, which will remove your Personal Information and any data that Flickswipe has.</p>

            <h2>6. Data Transfers</h2>

            <p>Your data is stored securely on an OVHcloud server in the UK. At present, we do not transfer your data outside the European Economic Area.</p>

            <h2>7. Complaints</h2>

            <p>If you have a concern about our handling of your Personal Information, you can lodge a complaint with the UK Information Commissioner's Office (ICO). More information can be found on the ICO's website at <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a>.</p>

            <h2>8. Automated Decision Making</h2>

            <p>Our Service employs automated decision-making for the purpose of matching your content preferences with those of your friends. This process is automatic and is based upon comparing your liked content with your friends' content. If both match, this information gets served to you.</p>

            <h2>9. Changes to Our Privacy Policy</h2>

            <p>Flickswipe may, in its sole discretion, modify or update this Privacy Policy from time to time. When we change the policy in a material manner, we will update the ‘last modified’ date at the top of this page.</p>

            <h2>10. How to Contact Us</h2>

            <p>If you have any questions about this Privacy Policy or the Service, please contact us at contact@flickswipe.org. </p>
        </div>
    )
  }

export default PrivacyPolicy
