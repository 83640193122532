import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, Await } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, clearUser } from './redux/slices/userSlice';
import { setLoggedIn } from './redux/slices/authSlice';
import { setToken } from './redux/slices/tokenSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from './redux/slices/subscriptionSlice';

import Deck from "./components/Deck/Deck";
import SignUp from './components/registrations/Signup';
import Login from './components/registrations/Login';
import Update from './components/registrations/Update';
import UsersList from './components/UsersList';
import Notifications from './components/Notifications';
import MatchList from './components/MatchList';
import ContentDetail from './components/ContentDetail';
import LoggedInNavbar from './components/LoggedInNavbar';
import LoggedOutNavbar from './components/LoggedOutNavbar';
import useHeartbeat from './useHeartbeat';
import Home from './components/Home';
import ConfirmAccount from './components/ConfirmAccount';
import ResetPassword from './components/registrations/ResetPassword'
import ForgotPassword from './components/registrations/ForgotPassword';
import RevertChanges from './components/registrations/RevertChanges';
import ResetPasswordAndEmail from './components/registrations/ResetPasswordAndEmail';
import PrivacyPolicy from './components/terms&policies/PrivacyPolicy';
import TermsOfUse from './components/terms&policies/TermsOfUse';
import SavedContent from './components/SavedContent';
import LoggedInHomepage from './components/LoggedInHomepage';
import NotificationsHandler from './components/NotificationsHandler';
import Friends from './components/Friends';
import StripeStatusHandler from './components/StripeStatusHandler';
import StripeSubscription from './components/registrations/StripeSubscription';
import CookieNotice from './components/CookieNotice/CookieNotice';
import ProcessPlexLink from './components/registrations/ProcessPlexLink';
import AwaitConfirmEmail from './components/settings/AwaitConfirmEmail';
import RecommendedFriends from './components/RecommendedFriends';

import axios from 'axios';

import { getJWT, logOut } from './utils/appHelpers';
import PlexLoginComponent from './components/registrations/PlexLoginComponent';

function BodyClassName({ children }) {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  useEffect(() => {
    document.body.classList.toggle('login-page', isLoginPage);
    return () => {
      document.body.classList.remove('login-page');
    };
  }, [isLoginPage]);

  return children;
}

function AppContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const handleLogout = () => {
    logOut(dispatch, navigate);
  };

  const [storedTheme, setStoredTheme] = useLocalStorage('theme', null);
  const [theme, setTheme] = useState(storedTheme || 'light');
  const [notifyCount, setNotifyCount] = useState(0);
  const [cable, setCable] = useState(null);
  const [shouldRefreshRequests, setShouldRefreshRequests] = useState(false);

  useEffect(() => {
    // Force a synchronous update when notification count changes
    if (notifyCount >= 0) {
      setNotifyCount(prevCount => {
        if (prevCount !== notifyCount) {
          return notifyCount;
        }
        return prevCount;
      });
    }
  }, [notifyCount]);

  useEffect(() => {
    const toggleTheme = (themePreference) => {
      if (storedTheme === null) {
        setTheme(themePreference.matches ? 'dark' : 'light');
      }
    }

    const darkThemePreference = window.matchMedia('(prefers-color-scheme: dark)');
    if (storedTheme === null) {
      toggleTheme(darkThemePreference);
    }

    darkThemePreference.addListener(toggleTheme);
    return () => {
      darkThemePreference.removeListener(toggleTheme);
    }
  }, [storedTheme]);

  useEffect(() => {
    document.body.dataset.theme = theme;
    setStoredTheme(theme);
  }, [theme]);

  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

  async function handleSilentLogin(dispatch) {
    const token = sessionStorage.getItem("token");
    const refreshToken = sessionStorage.getItem("refresh_token");

    if (token) {
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      dispatch(setUser(userData));
      dispatch(setToken(token));
      dispatch(setLoggedIn(true));
      dispatch(setSubscriptionStatus(userData.subscription_status));
      dispatch(setSubscriptionEndDate(userData.subscription_end_date));
      dispatch(setCancelAtPeriodEnd(userData.cancel_at_period_end));
    } else if (refreshToken) {
      const fingerprint = navigator.userAgent;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/silent_login`,
          { refresh_token: refreshToken },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-Fingerprint": fingerprint,
            },
          }
        );

        if (response.data.jwt && response.data.user) {
          const { jwt, user } = response.data;
          dispatch(setUser(user));
          dispatch(setToken(jwt));
          dispatch(setLoggedIn(true));
          dispatch(setSubscriptionStatus(user.subscription_status));
          dispatch(setSubscriptionEndDate(user.subscription_end_date));
          dispatch(setCancelAtPeriodEnd(user.cancel_at_period_end));
          sessionStorage.setItem("userData", JSON.stringify(user));
          sessionStorage.setItem("token", jwt);
          sessionStorage.setItem("refresh_token", refreshToken);
          Cookies.set("fingerprint", fingerprint, { expires: 1, secure: true });
        } else {
          console.error("Invalid response from server:", response.data);
        }
      } catch (error) {
        console.error("Error during silent login:", error);
      }
    }
  }

  const silentLoginCalled = useRef(false);

  useEffect(() => {
    if (!silentLoginCalled.current && sessionStorage.getItem("refresh_token")) {
      handleSilentLogin(dispatch);
      silentLoginCalled.current = true;
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (user && loggedIn && token) {
  //     const fetchSentInvites = async () => {
  //       let config = {
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //           'Content-Type': 'application/json',
  //           Accept: 'application/json',
  //         },
  //       };
  //       axios
  //         .get(`${process.env.REACT_APP_API_URL}/sentinvites/${user.id}`, config)
  //         .then((response) => {
  //           setNotifyCount(response.data.pending_users.length);
  //         });
  //     };
  //     fetchSentInvites();
  //   }
  // }, [user, loggedIn, token]);

  const handleGetJwt = async () => {
    await getJWT(dispatch, cable);
  };

  useHeartbeat();

  return (
    <>
      <NotificationsHandler setShouldRefreshRequests={setShouldRefreshRequests} setNotifyCount={setNotifyCount} />
      <AwaitConfirmEmail />
      <StripeStatusHandler />
      <CookieNotice />
      {loggedIn && (
        <LoggedInNavbar setTheme={setTheme} storedTheme={storedTheme} cable={cable} setStoredTheme={setStoredTheme} notifyCount={notifyCount} logOut={handleLogout} />
      )}
      {!loggedIn && (
        <LoggedOutNavbar notifyCount={notifyCount} logOut={handleLogout} />
      )}
      <Routes>
        <Route exact path="/" element={loggedIn ? <LoggedInHomepage /> : <Home />} />
        <Route exact path="/premium" element={<StripeSubscription showSalesPitch={true} />} />
        <Route exact path="/confirm/:token" element={<ConfirmAccount />} />
        <Route path="/confirm/"  element={<ConfirmAccount />} />
        <Route path="/login" element={!loggedIn ? <Login /> : <Navigate to="/" />} />
        <Route exact path="/process-plex-login" element={<PlexLoginComponent />} />
        <Route exact path="/process-plex-link" element={<ProcessPlexLink />} />
        {/* <Route exact path="/plex_update_user_details" element={<PlexLoginComponent />} /> */}
        # CHECK SIGNUP FOR CORRECT JWT. REPLACE WITH TOKEN or Method getJWT
        <Route exact path="/signup" element={!loggedIn ? <SignUp getJWT={handleGetJwt} /> : <Navigate to="/home" />} />
        <Route exact path="/password_resets/:token" element={<ResetPassword />} />
        <Route exact path="/password_resets/:token/revert_email" element={<ResetPasswordAndEmail />} />
        <Route exact path="/users/revert_changes" element={<RevertChanges />} />
        <Route exact path="/forgot_password" element={<ForgotPassword />} />
        <Route exact path="/update" element={<Update logOut={handleLogout} />} />
        <Route exact path="/deck" element={<Deck />} />
        <Route exact path="/users" element={<UsersList />} />
        <Route exact path="/recommended-friends" element={<RecommendedFriends />} />
        <Route exact path="/contents/:contentId" element={<ContentDetail />} />
        <Route exact path="/matches" element={<MatchList />} />
        <Route exact path="/saved_content" element={<SavedContent />} />
        <Route exact path="/notifications" element={<Notifications shouldRefreshRequests={shouldRefreshRequests} setShouldRefreshRequests={setShouldRefreshRequests} notifyCount={notifyCount} setNotifyCount={setNotifyCount} />} />
        <Route exact path="/friends" element={<Friends />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/terms" element={<TermsOfUse />} />
      />
      </Routes>
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <BodyClassName>
        <AppContent />
      </BodyClassName>
    </BrowserRouter>
  );
}
