import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import '../assets/Notifications.css';
import '../assets/NotificationCards.css';

import NotificationCard from './NotificationCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClipLoader from "react-spinners/BeatLoader";
import defaultProfileImage from '../assets/images/dummy-pic.jpg';

const Notifications = ({ setNotifyCount, notifyCount, shouldRefreshRequests, setShouldRefreshRequests }) => {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.token.value);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const deduplicateRequests = (requests) => {
    const seen = new Set();
    return requests.filter(request => {
      const duplicate = seen.has(request.invite_id);
      seen.add(request.invite_id);
      return !duplicate;
    });
  };

  const fetchPendingRequests = async (pageNumber = page + 1) => {
    if (user && token && !loading) {
      try {
        setLoading(true);

        const config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/sentinvites/${user.id}?page=${pageNumber}&per_page=10`,
          config
        );

        // Deduplicate before setting state
        const newRequests = deduplicateRequests([
          ...pendingRequests,
          ...response.data.pending_users
        ]);

        setPendingRequests(newRequests);
        setPage((prevPage) => prevPage + 1);

        if (response.data.pending_users.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    }
  };

  const AcceptFriendRequest = async (data) => {
    const id = data.invite_id;
    const url = `${process.env.REACT_APP_API_URL}/invitations/${id}`;

    try {
      await axios.put(url, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      });

      // Update local state immediately
      setPendingRequests(current =>
        current.filter((r) => r.invite_id !== id)
      );

      setNotifyCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const RejectFriendRequest = async (invite) => {
    const id = invite.invite_id;
    const url = `${process.env.REACT_APP_API_URL}/invitations/${id}`;

    try {
      await axios.delete(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        params: { invitation_id: id }
      });

      setPendingRequests(current =>
        current.filter((r) => r.invite_id !== id)
      );

      setNotifyCount(prev => Math.max(0, prev - 1));
    } catch (error) {
      console.error('Error rejecting friend request:', error);
    }
  };

  // Use effect hook to fetch pending friend requests when the component mounts if the user is logged in
  useEffect(() => {
    if (token && user) {
      setShouldRefreshRequests(true);
    }
  }, [token, user]);

  useEffect(() => {
    if (shouldRefreshRequests) {
      setPage(1);
      setPendingRequests([]); // Clear existing requests
      setHasMore(true);

      // Wrap in setTimeout to ensure state is cleared before fetching
      setTimeout(() => {
        fetchPendingRequests(1);
        setShouldRefreshRequests(false);
      }, 0);
    }
  }, [shouldRefreshRequests]);

  const requests = pendingRequests.map((data, i) => (
    <div className="requestsNotificationCardContainer" key={`${data.invite_id}-${i}`}>
      <NotificationCard
        imageUrl={data.user.profile_image_url ? data.user.profile_image_url : defaultProfileImage}
        id={data.user.id}
        firstname={data.user.firstname}
        lastname={data.user.lastname}
        onAccept={() => AcceptFriendRequest(data)}
        onReject={() => RejectFriendRequest(data)}
        testIndex={i}
      />
    </div>
  ));

  return (
    <div className='scrollContainer'>
      <div className='notificationHeader'>
        <h1 className='notificationTitle'>Notifications</h1>
        <div className='searchContainer'>
        {isFetching ? (
          <div className="initial-loader-container">
            <ClipLoader
              loading={true}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#ff7c62"
            />
          </div>
        ) : pendingRequests.length > 0 ? (
            <div id="scrollableDiv" style={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
              <InfiniteScroll
                dataLength={pendingRequests.length}
                next={() => fetchPendingRequests(page + 1)}
                hasMore={hasMore}
                loader={
                  <div className={`request-loader-container${loading ? ' show' : ''}`}>
                    <ClipLoader
                      loading={loading}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      color="#ff7c62"
                    />
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center", marginBottom: "60px" }}>
                    <b>There are no more pending friend requests</b>
                  </p>
                }
                scrollableTarget="scrollableDiv"
              >
                {requests}
              </InfiniteScroll>
            </div>
          ) : (
            <div className='noPending-message'>You have no pending friend requests.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
