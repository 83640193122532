import React from "react";
import '../assets/FriendCard.css';

const FriendCard = ({
  firstname,
  lastname,
  imageUrl,
  online,
  onDelete,
  id
}) => {
  return (
    <div className="friendCard">
      <img className="friendImage" src={imageUrl} alt="profile-pic" />
      <div className="friendContent">
        <div className="friendInfo">
          <h2 className="friendName">
            {firstname} {lastname}
            {online && (
              <div className="onlineStatus" role="status" aria-label="Online" data-testid="online-status" />
            )}
          </h2>
        </div>
        <button
          data-testid={`${id}-deletebtn`}
          className="deleteButton"
          onClick={onDelete}
        >
          Delete Friend
        </button>
      </div>
    </div>
  );
};

export default FriendCard;
