import React, { useEffect, useState } from "react";
import { animated, to } from "react-spring";

import "../assets/Card.css";

import HeartButton from './HeartButton'; // Import HeartButton component
import placeholderImage from '../assets/images/flickswipe_logo.png'

const Card = ({
  i,
  x,
  y,
  bind,
  data,
  token,
  handleDragEnd,
  user,
}) => {
  const { poster_path, id, movie, tv_show } = data[i];

  const shouldRenderCard = poster_path !== 'https://image.tmdb.org/t/p/w342'

  return shouldRenderCard ? (
    <animated.div
      className="animated"
      key={i}
      {...bind(i, id)}
      onDragEnd={() => {
        handleDragEnd();
      }}
      style={{
        transform: to(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
    >
      <div className="card" data-testid={`card-${id}`} >
      {movie?.poster_path && movie.poster_path !== 'https://image.tmdb.org/t/p/w342' ? (
        <img key={movie.poster_path} src={movie.poster_path} alt="poster_picture" />
      ) : tv_show?.poster_path && tv_show.poster_path !== 'https://image.tmdb.org/t/p/w342' ? (
        <img src={tv_show.poster_path} key={id} alt="poster_picture" />
      ) : (
        // Add default image here or any placeholder component
        <img src={placeholderImage} alt="poster_picture" />
      )}
        <HeartButton token={token} contentId={id} user={user} />
      </div>
    </animated.div>
  ) : null;
};

export default Card;
