// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.provider-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 8px;
}

.remove-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 24px;
  font-weight: bold;
  color: red;
  cursor: pointer;
}

.provider-header p {
  font-size: 0.75rem;
}

.provider-header h2 {
  font-size: 1.5rem;
}

.provider-header {
  margin: 1rem;
  color: var(--text-primary);
}

.provider-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: var(--theme-consistent) 0px 0px 5px 0px;
}

.provider-logo img {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 10px;
}

.provider-logo.selected {
  border: 1px solid #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/UserPreferences.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2DAA2D;EAC3D,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,mDAAmD;AACrD;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".provider-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));\n  grid-gap: 8px;\n}\n\n.remove-icon {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  font-size: 24px;\n  font-weight: bold;\n  color: red;\n  cursor: pointer;\n}\n\n.provider-header p {\n  font-size: 0.75rem;\n}\n\n.provider-header h2 {\n  font-size: 1.5rem;\n}\n\n.provider-header {\n  margin: 1rem;\n  color: var(--text-primary);\n}\n\n.provider-logo {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 10px;\n  box-shadow: var(--theme-consistent) 0px 0px 5px 0px;\n}\n\n.provider-logo img {\n  width: 100%;\n  max-height: 100%;\n  max-width: 100%;\n  border-radius: 10px;\n}\n\n.provider-logo.selected {\n  border: 1px solid #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
