// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-dropdown {
  padding: 8px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 100px);
  overflow-y: auto;
  background-color: var(--background);
  transform: translateX(100%);
  transition: transform 0.3s;
  margin-top: 16px;
}

.filter-dropdown-container {
  margin-bottom: 65px;
}

.filter-dropdown-closeBtn {
  right: 16px;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 16px;
  z-index: 100;
  color: var(--theme-consistent);
  font-size: 1.25rem;
}

.filter-dropdown-section-genres {
  margin-top: 8px;
  color: var(--text-primary);
}

.filter-dropdown-section-providers {
  margin-top: 8px;
  color: var(--text-primary);
}

.filter-dropdown-providers{
  margin: 8px;
}

.filter-dropdown.slide-in-right {
  transform: translateX(0);
  z-index: 2
}

.filter-dropdown.slide-out-right {
  transform: translateX(100%);
  z-index: 2
}

.filter-dropdown-header {
  padding-top: 8px;
  color: var(--text-primary);
  width: 100%;
  height: 5%;
}

.credits-container {
  padding-top: 50px;
  text-align: center;
  color: var(--text-primary);
}

.credits-container .justwatch-logo {
  width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/FilterDropdown.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,2BAA2B;EAC3B,gBAAgB;EAChB,mCAAmC;EACnC,2BAA2B;EAC3B,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,SAAS;EACT,YAAY;EACZ,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB;AACF;;AAEA;EACE,2BAA2B;EAC3B;AACF;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".filter-dropdown {\n  padding: 8px;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  width: 100vw;\n  height: calc(100vh - 100px);\n  overflow-y: auto;\n  background-color: var(--background);\n  transform: translateX(100%);\n  transition: transform 0.3s;\n  margin-top: 16px;\n}\n\n.filter-dropdown-container {\n  margin-bottom: 65px;\n}\n\n.filter-dropdown-closeBtn {\n  right: 16px;\n  position: fixed;\n  display: flex;\n  justify-content: flex-end;\n  top: 16px;\n  z-index: 100;\n  color: var(--theme-consistent);\n  font-size: 1.25rem;\n}\n\n.filter-dropdown-section-genres {\n  margin-top: 8px;\n  color: var(--text-primary);\n}\n\n.filter-dropdown-section-providers {\n  margin-top: 8px;\n  color: var(--text-primary);\n}\n\n.filter-dropdown-providers{\n  margin: 8px;\n}\n\n.filter-dropdown.slide-in-right {\n  transform: translateX(0);\n  z-index: 2\n}\n\n.filter-dropdown.slide-out-right {\n  transform: translateX(100%);\n  z-index: 2\n}\n\n.filter-dropdown-header {\n  padding-top: 8px;\n  color: var(--text-primary);\n  width: 100%;\n  height: 5%;\n}\n\n.credits-container {\n  padding-top: 50px;\n  text-align: center;\n  color: var(--text-primary);\n}\n\n.credits-container .justwatch-logo {\n  width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
