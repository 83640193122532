// thumbnailSlice.js

import { createSlice } from '@reduxjs/toolkit';

const thumbnailSlice = createSlice({
  name: 'thumbnails',
  initialState: {
    cache: {},
    loading: {},
  },
  reducers: {
    setThumbnail: (state, action) => {
      const { thumbPath, url } = action.payload;
      if (state.cache[thumbPath]) {
        URL.revokeObjectURL(state.cache[thumbPath]);
      }
      state.cache[thumbPath] = url;
    },
    setThumbnailLoading: (state, action) => {
      const { thumbPath, isLoading } = action.payload;
      if (isLoading) {
        state.loading[thumbPath] = true;
      } else {
        delete state.loading[thumbPath];
      }
    },
    clearThumbnails: (state) => {
      Object.values(state.cache).forEach(url => {
        if (url && url !== 'placeholder') {
          URL.revokeObjectURL(url);
        }
      });
      state.cache = {};
      state.loading = {};
    },
  },
});

export const { setThumbnail, setThumbnailLoading, clearThumbnails } = thumbnailSlice.actions;
export default thumbnailSlice.reducer;
