import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import '../assets/ContentModal.css';
import CheckboxList from "./CheckboxList";
import FilterDropdown from "./FilterDropdown";
import ReactSlider from 'react-slider';

import ContentDetail from "./ContentDetail";

const Modal = ({ user, isOpen, loading, closeModal, likedContent, selectedFriend, token }) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [sortedByPopularity, setSortedByPopularity] = useState(false);
  const [sortedByGenres, setSortedByGenres] = useState(false);
  const [filteredByMovie, setFilteredByMovie] = useState(false);
  const [filteredByTv, setFilteredByTv] = useState(false);
  const [originalLikedContent, setOriginalLikedContent] = useState(likedContent);
  const [sliderValue, setSliderValue] = useState(270);
  const [filterByProvider, setFilterByProvider] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [animation, setAnimation] = useState("slide-in");
  const [disableScroll, setDisableScroll] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState(null);

  const genres = [
    ...new Set(originalLikedContent.flatMap((content) => content.genres)),
  ];

  const flatrate_providers = originalLikedContent.reduce((acc, content) => {
    const providers = content.flatrate_providers || [];
    return providers.reduce((acc, provider) => {
      if (
        provider.logo_path &&
        !acc.some((p) => p.logo_path === provider.logo_path)
      ) {
        acc.push(provider);
      }
      return acc;
    }, acc);
  }, []);

  const handleCheckboxChange = (selectedCheckboxes) => {
    setSelectedCheckboxes(selectedCheckboxes);
    setFilteredByMovie(selectedCheckboxes.includes("Movies"));
    setFilteredByTv(selectedCheckboxes.includes("Shows"));
    setSortedByPopularity(selectedCheckboxes.includes("Popular"));
  };

  let filteredContent = likedContent;

  // Filtering
    filteredContent = filteredContent.filter((content) =>
    ((!filteredByMovie && !filteredByTv) || (filteredByMovie && content.media_type === "movie") || (filteredByTv && content.media_type === "tv")) &&
    (!sortedByGenres || content.genres.some((genre) => selectedCheckboxes.includes(genre))) &&
    (!filterByProvider || content.flatrate_providers.some((provider) => selectedCheckboxes.includes(provider.logo_path))) &&
    (content.runtime <= sliderValue)
  );

  // Sorting
  if (sortedByPopularity) {
    filteredContent = filteredContent.sort((a, b) => b.popularity - a.popularity);
  } else {
    filteredContent = filteredContent.sort((a, b) => a.runtime - b.runtime);
  }

  const formatRuntime = (runtime) => {
    if (runtime < 60) {
      return `${runtime} minutes`;
    } else if (runtime === 60) {
      return `1 hour`;
    } else {
      const hours = Math.floor(runtime / 60);
      const minutes = runtime % 60;
      if (minutes === 0) {
        return `${hours} hours`;
      } else {
        return `${hours} hours ${minutes} minutes`;
      }
    }
  };

  const override: CSSProperties = {
    borderColor: "#ff7c62",
    height: "45px",
    width: "40px"
  };

  const handleChange = (value) => {
    setSliderValue(value);
  };

  const handleClick = (contentId) => {
    setSelectedContentId(contentId);
  };

  const closeDropdown = () => {
    setShowDropdown(false)
    setDisableScroll(false)
  }

  return (
    <>
      {selectedContentId && (
        <ContentDetail
          contentId={selectedContentId}
          closeDetail={() => setSelectedContentId(null)}
          token={token}
          user={user}
        />
      )}
      <div className={`modal-overlay ${selectedContentId ? 'content-detail-visible' : ''}`} />
      <div className={`modal-content ${animation} ${disableScroll ? 'no-scroll' : ''}`}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
        <div className="ContentCloseBtnWrapper">
          <div className="ContentCloseBtn">
            <FontAwesomeIcon
              onClick={() => closeModal(false)}
              icon={faSquareXmark}
              size="3x"
              title="closeBtn"
            />
          </div>
        </div>
        <div className="LikedContentHeader">
          <h2>Flickswipe matches with {selectedFriend.friend.firstname}</h2>
        </div>
        <div className="ContentCheckboxList">
          <CheckboxList
            options={[ "Movies", "Shows", "Popular" ]}
            selectedOptions={selectedCheckboxes}
            onChange={handleCheckboxChange}
            testId="checkboxList"
          />
        </div>
          {showDropdown && (
            <FilterDropdown
              showDropdown={showDropdown}
              closeDropdown={closeDropdown}
              originalLikedContent={originalLikedContent}
              handleCheckboxChange={handleCheckboxChange}
              selectedCheckboxes={selectedCheckboxes}
              onChange={handleCheckboxChange}
              onGenresChange={setSortedByGenres}
              onProvidersChange={setFilterByProvider}
              onClose={() => setShowDropdown(false)}
            />
          )}
          <div className="ContentSliderContainer">
            <ReactSlider
                className="content-react-slider"
                thumbClassName="content-thumb"
                trackClassName="content-track"
                min={0}
                max={200}
                step={10}
                value={sliderValue}
                onChange={handleChange}
            />
            <p>Runtime: {formatRuntime(sliderValue)}</p>
        </div>
        <div className="ContentFilterMoreContainer">
          <button
            className="ContentFilterMoreBtn"
            onClick={() => {
              setShowDropdown(true);
              setDisableScroll(true);
            }}>
            Filter More
          </button>
        </div>
        <div className="content-grid">
            {filteredContent.map(content => (
              <div className="content-card" key={`content-${content.content_id}`} onClick={() => handleClick(content.content_id)} data-testid="content-card">
                {!loaded[content.content_id] && (
                  <div className="loading">
                    <ClipLoader
                      loading={!loaded[content.content_id]}
                      size={35}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      cssOverride={override}
                    />
                  </div>
                )}
                <img
                  className="contentModalImg"
                  src={content.poster_path}
                  alt={content.title || content.name}
                  onLoad={() => setLoaded((prevLoaded) => ({ ...prevLoaded, [content.content_id]: true }))}
                  onError={() => setLoaded((prevLoaded) => ({ ...prevLoaded, [content.content_id]: true }))}
                />
                <div className="content-title">{content.title || content.name}</div>
              </div>
            ))}
          </div>
          </>
        )}
      </div>
    </>
  );
};

export default Modal;
