import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../../assets/ImageUpload.css'

function ImageUpload({ user, token }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file size before uploading (10MB limit as an example)
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      setUploadMessage('Image size exceeds 10MB limit. Please choose a smaller file.');
      setTimeout(() => setUploadMessage(''), 5000);
      return;
    }

    setSelectedFile(file);
    setUploading(true);

    const formData = new FormData();
    formData.append('user[profile_image]', file);

    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${user.id}`,
        formData,
        config
      );

      if (response.status === 200) {
        setUploadMessage('Profile image uploaded successfully');
        setTimeout(() => setUploadMessage(''), 5000);
      }
    } catch (error) {
      let errorMessage = 'Error uploading profile image';

      if (error.response) {
        switch (error.response.status) {
          case 413:
            errorMessage = 'Image file is too large. Maximum size allowed is 10MB';
            break;
          case 415:
            errorMessage = 'Unsupported file type. Please use JPG, PNG, or GIF';
            break;
          case 401:
            errorMessage = 'Authentication error. Please try logging in again';
            break;
          default:
            errorMessage = `Upload failed: ${error.response?.data?.message || error.message}`;
        }
      }

      console.error('Upload error:', error);
      setUploadMessage(errorMessage);
      setTimeout(() => setUploadMessage(''), 5000);
    } finally {
      setUploading(false);
    }
  };

  const imageUrl = user.profile_image_url ? user.profile_image_url : require('../../assets/images/dummy-pic.jpg');

  return (
    <div className="profile-image-container">
      <div
        className="profile-image"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <label htmlFor="file-upload" className="profile-image-label" aria-label="Upload Image">
        <FontAwesomeIcon icon={faCamera} className="camera-icon"/>
        <FontAwesomeIcon icon={faPlus} className="plus-icon"/>
        <input
          id="file-upload"
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          className="file-upload-input"
          style={{ display: "none" }}
          disabled={uploading}
        />
      </label>
      {uploading && (
        <div className="upload-loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      {uploadMessage && <p className='image-upload-message'>{uploadMessage}</p>}
    </div>
  );
}

export default ImageUpload;
