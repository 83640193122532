// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* First, define CSS variables for safe areas */
:root {
  --safe-area-top: env(safe-area-inset-top, 0px);
}

/* Base styles */
html,
body {
  padding: 0;
  margin: 0;
  overscroll-behavior-y: none;
  height: 100%;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  background-color: var(--background);
}

/* Background element that stays at the top */
/* body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 2.2rem;
  background-color: var(--background);
  z-index: 10;
} */

/* Support for iOS Safari */
@supports (-webkit-touch-callout: none) {
  body {
    height: -webkit-fill-available;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C;EACE,8CAA8C;AAChD;;AAEA,gBAAgB;AAChB;;EAEE,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,iCAAiC;EACjC,mCAAmC;AACrC;;AAEA,6CAA6C;AAC7C;;;;;;;;;GASG;;AAEH,2BAA2B;AAC3B;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":["/* First, define CSS variables for safe areas */\n:root {\n  --safe-area-top: env(safe-area-inset-top, 0px);\n}\n\n/* Base styles */\nhtml,\nbody {\n  padding: 0;\n  margin: 0;\n  overscroll-behavior-y: none;\n  height: 100%;\n  overflow: hidden;\n  width: 100vw;\n  height: 100vh;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-overflow-scrolling: touch;\n  background-color: var(--background);\n}\n\n/* Background element that stays at the top */\n/* body::before {\n  content: '';\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 2.2rem;\n  background-color: var(--background);\n  z-index: 10;\n} */\n\n/* Support for iOS Safari */\n@supports (-webkit-touch-callout: none) {\n  body {\n    height: -webkit-fill-available;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
