import React, { useEffect, useState } from "react";
import CheckboxList from "./CheckboxList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";

import "../assets/FilterDropdown.css";
import justwatch_logo from "../assets/images/JustWatch-logo.webp"

const FilterDropdown = ({
  showDropdown,
  closeDropdown,
  originalLikedContent,
  selectedCheckboxes,
  handleCheckboxChange,
  onGenresChange,
  onProvidersChange,
}) => {
  const [genres, setGenres] = useState([]);
  const [flatrate_providers, setFlatrateProviders] = useState([]);
  const [animation, setAnimation] = useState("");

  const handleClose = () => {
    setAnimation("out-right");
    setTimeout(() => {
      closeDropdown();
    }, 300);
  };

  useEffect(() => {
    if (showDropdown) {
      setAnimation("in-right");
    }
  }, [showDropdown]);

  useEffect(() => {
    if (originalLikedContent && originalLikedContent.length > 0) {
      const newGenres = [
        ...new Set(originalLikedContent.flatMap((content) => content.genres)),
      ];
      setGenres(newGenres);

      const newFlatrateProviders = originalLikedContent.reduce(
        (acc, content) => {
          const providers = content.flatrate_providers || [];
          return providers.reduce((acc, provider) => {
            if (
              provider.logo_path &&
              !acc.some((p) => p.logo_path === provider.logo_path)
            ) {
              acc.push(provider);
            }
            return acc;
          }, acc);
        },
        [],
      );
      setFlatrateProviders(newFlatrateProviders);
    }
  }, [originalLikedContent]);

  const onCheckboxChange = (selectedOptions) => {
    handleCheckboxChange(selectedOptions);
    onGenresChange(
      genres.some((genre) => selectedOptions.includes(genre)),
    );
    onProvidersChange(
      flatrate_providers.some((provider) =>
        selectedOptions.includes(provider.logo_path),
      ),
    );
  };

  let providers = (flatrate_providers || []).map(
    (provider) => provider.logo_path,
  );
  return (
    <div className={`filter-dropdown slide-${animation}`}>
      <div className="filter-dropdown-header">
        <h3>Filter Options</h3>
      </div>
      <div className="filter-dropdown-container">
        <div className="filter-dropdown-content">
          <div className="filter-dropdown-section-genres">
            <h4 className="filter-dropdown-genres">Genres</h4>
            <CheckboxList
              options={[...genres]}
              selectedOptions={selectedCheckboxes}
              onChange={onCheckboxChange}
              isProvider={false}
            />
          </div>
          <div className="filter-dropdown-section-providers">
            <h4 className="filter-dropdown-providers">Providers</h4>
            <CheckboxList
              options={[...providers]}
              selectedOptions={selectedCheckboxes}
              onChange={onCheckboxChange}
              isProvider={true}
            />
          </div>
        </div>
        <div className="filter-dropdown-closeBtn">
          <div className="filter-dropdown-closeBtn-inner">
            <FontAwesomeIcon
              onClick={handleClose}
              icon={faSquareXmark}
              size="3x"
              title="filterCloseBtn"
            />
          </div>
        </div>
        <div className="credits-container">
          <p>Provider Info sourced from JustWatch</p>
          <img className="justwatch-logo" src={justwatch_logo} alt="JustWatch Logo" />
        </div>
      </div>
    </div>
  );
}

export default FilterDropdown;
