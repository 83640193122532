// components/common/SearchableCountryDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import '../../assets/SearchableCountryDropdown.css';

const SearchableCountryDropdown = ({
  countries,
  value,
  onChange,
  locationPreference,
  placeholder = "Select Country"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const updateSearch = debounce((term) => {
    const filtered = countries.filter(country =>
      country.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCountries(filtered);
    setHighlightedIndex(-1);
  }, 300);

  useEffect(() => {
    setFilteredCountries(countries);
  }, [countries]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    updateSearch(term);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev < filteredCountries.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredCountries[highlightedIndex]);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const handleSelect = (country) => {
    onChange(country.code);
    setSearchTerm('');
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const selectedCountry = countries.find(country => country.code === value);

  return (
    <div className="country-dropdown-container" ref={dropdownRef}>
      <div
        className="country-dropdown-trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="country-name">
          {selectedCountry ? selectedCountry.name : locationPreference || placeholder}
        </div>
        <div className="country-dropdown-arrow" aria-hidden="true">▼</div>
      </div>

      {isOpen && (
        <div className="country-dropdown-content">
          <input
            type="text"
            className="country-search-input"
            placeholder="Search countries..."
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />

          <div className="country-list">
            {filteredCountries.map((country, index) => (
              <div
                key={country.code}
                className={`country-item ${
                  highlightedIndex === index ? 'highlighted' : ''
                }`}
                onClick={() => handleSelect(country)}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                {country.name}
              </div>
            ))}
            {filteredCountries.length === 0 && (
              <div className="no-results">No countries found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableCountryDropdown;
