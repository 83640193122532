import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/slices/userSlice';
import { setToken } from '../../redux/slices/tokenSlice';
import { setLoggedIn } from '../../redux/slices/authSlice';
import { setSubscriptionStatus, setSubscriptionEndDate, setCancelAtPeriodEnd } from '../../redux/slices/subscriptionSlice';
import SearchableCountryDropdown from '../Common/SearchableCountryDropdown';
import '../../assets/PlexUpdateDetails.css';
import { Link } from 'react-router-dom';

function PlexUpdateDetails({ plexUserEmail, onDetailsUpdated, emailTaken }) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.value);
  const [plexAddress, setPlexAddress] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [plexPort, setPlexPort] = useState('');
  const [locationPreference, setLocationPreference] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch the list of countries from the backend
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    };

    // Only add authorization header if token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    axios.get(`${process.env.REACT_APP_API_URL}/countries`, config)
      .then(response => {
        setCountries(response.data);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    // Add validation
    const errors = [];
    if (!firstname.trim()) errors.push("First name is required");
    if (!lastname.trim()) errors.push("Last name is required");
    if (!locationPreference) errors.push("Please select a country");

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      return;
    }

    setIsSubmitting(true);

    const requestData = {
      email: plexUserEmail,
      firstname,
      lastname,
      plex_address: plexAddress,
      plex_port: plexPort !== '' ? plexPort : null,
      location_preference: locationPreference,
    };

    axios.post(`${process.env.REACT_APP_API_URL}/plex_update_user_details`, requestData)
      .then((response) => {
        if (response.data.status === 'success') {
          dispatch(setUser(response.data.user));
          dispatch(setToken(response.data.jwt));
          dispatch(setLoggedIn(true));
          dispatch(setSubscriptionStatus(response.data.user.subscription_status));
          dispatch(setSubscriptionEndDate(response.data.user.subscription_end_date));
          dispatch(setCancelAtPeriodEnd(response.data.user.cancel_at_period_end));
          sessionStorage.setItem('userData', JSON.stringify(response.data.user));
          sessionStorage.setItem('token', response.data.jwt);
          sessionStorage.setItem('refresh_token', response.data.refresh_token);
          onDetailsUpdated();
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage('Error updating details. Please try again.');
        console.error("Error updating user details:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="plex-update-details-container">
      <div className="plex-update-details-form">
        {emailTaken ? (
          <>
            <h2>Link Your Plex Account</h2>
            <p>Your email is already registered. Please link your Plex account:</p>
            <form onSubmit={handleSubmit}>
              <div className="plex-address-info">
                <h4>Please only update your Plex address if you are behind a proxy and know your external access URL.</h4>
                <p>THIS CANNOT BE CHANGED LATER.</p>
              </div>
              <input
                type="text"
                name="plexAddress"
                placeholder="Plex Server URL"
                value={plexAddress}
                onChange={(e) => setPlexAddress(e.target.value)}
              />
              <input
                type="text"
                name="plexPort"
                placeholder="Plex Port (optional)"
                value={plexPort}
                onChange={(e) => setPlexPort(e.target.value)}
              />
              <SearchableCountryDropdown
                countries={countries}
                value={locationPreference}
                onChange={setLocationPreference}
                placeholder="Select Country"
              />
              <button className="plex-update-details-submit-btn" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : emailTaken ? 'Link Plex Account' : 'Update Details'}
              </button>
            </form>
          </>
        ) : (
          <>
            <h2>Complete Your Registration</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="firstname"
                placeholder="Firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <input
                type="text"
                name="lastname"
                placeholder="Lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
              <div className="plex-address-info">
                <h4>Only update your Plex address if you are behind a proxy and know your external access URL otherwise this is set automatically</h4>
                <p>Plex address and port can be updated in My Account</p>
              </div>
              <input
                type="text"
                name="plexAddress"
                placeholder="Plex Server URL"
                value={plexAddress}
                onChange={(e) => setPlexAddress(e.target.value)}
              />
              <input
                type="text"
                name="plexPort"
                placeholder="Plex Port (optional)"
                value={plexPort}
                onChange={(e) => setPlexPort(e.target.value)}
              />
              <SearchableCountryDropdown
                countries={countries}
                value={locationPreference}
                onChange={setLocationPreference}
                placeholder="Select Country"
              />
              <button className="plex-update-details-submit-btn" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : emailTaken ? 'Link Plex Account' : 'Update Details'}
              </button>
            </form>
          </>
        )}
        {errorMessage && <p className="plex-update-details-error">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default PlexUpdateDetails;
